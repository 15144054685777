import React from 'react';
import {Col, Row} from "antd";
import LoginLinks from "./LoginLinks";

const fullWidthStyle = {width: '100%', paddingTop: '60px'}
function AuthLayoutWrapper(props) {
  return <Row style={fullWidthStyle} align="top">
    <Col xs={1} sm={4} md={6} lg={6} xl={9} />
    <Col xs={22} sm={16} md={12} lg={12} xl={6}>
      <section style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', paddingTop: '10%', boxSizing: 'border-box', height: '90vh'}}>
        {props.children}
      </section>
      {props.hideLinks ? null : <LoginLinks/>}
    </Col>
    <Col xs={1} sm={4} md={6} lg={6} xl={9}/>
  </Row>
}

export default AuthLayoutWrapper;
