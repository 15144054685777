import {all, takeEvery, call, put, delay} from "redux-saga/effects";
import actions from "./actions";

function* endContentVideoSequence() {
  yield put({
    type: actions.RESET_VIDEO_STATUS,
  });
  yield delay(750);
  yield put({
    type: actions.END_CONTENT_VIDEO,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.END_CONTENT_VIDEO_SEQUENCE, endContentVideoSequence),
  ]);
}
