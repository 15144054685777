import React from 'react';
import PropTypes from 'prop-types';
import backgroundActions from "@web/redux/background/actions";
import {useDispatch} from "react-redux";
import {Tooltip} from "antd";
import appActions from "@web/redux/app/actions";

VideoLink.propTypes = {
  url: PropTypes.string.isRequired,
};

function VideoLink(props) {
    const dispatch = useDispatch();
  const startContentVideo = (url) => dispatch(backgroundActions.startContentVideo({url}));
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // startContentVideo(props.url);
    // startContentVideo("/video/about-thescribe-ai.mp4")
    dispatch(appActions.openVideoModal({url: props.url}));
    return false;
  }
  return <Tooltip title="Video">
    <a className="ai-text" href={props.method} onClick={onClick}>{props.children}</a>
  </Tooltip>
}

export default VideoLink;