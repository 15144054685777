import {all, takeEvery, call, put, fork} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import AlgoliaIndex from "@web/lib/algolia.db";
import map from "lodash/map.js";
import {api} from "@web/lib/api";
import reduce from "lodash/reduce.js";

/**
 * @function parseFilters
 * @param {object} filters
 * @returns {string}
 */
export const parseFilters = (filters = null) => {
  return map(filters, (value, key) => {
    return(` ${key}:${value}`);
  }).join(" AND");
}

/**
 * @summary Get search app params
 * @function getSearchAppParams
 * @param {string} userId
 * @param {object} params
 * @param {object} params.filters
 * @param {string[]} params.tagFilters
 * @returns {[string, object, string][]} - [group, params, indexName]
 */
const getSearchAppParams = (userId, {filters = {}, tagFilters = []}) => {
  const parsedFilters = parseFilters(filters);
  return [
    // [AlgoliaIndex.NAMES.MESSAGES, {hitsPerPage: 20, filters: parsedFilters, tagFilters: uniq([userId, ...tagFilters])}, AlgoliaIndex.NAMES.MESSAGES],
    // [AlgoliaIndex.NAMES.CONTACTS, {hitsPerPage: 10, tagFilters: [userId]}, AlgoliaIndex.NAMES.CONTACTS],
    // [AlgoliaIndex.NAMES.FILES, {hitsPerPage: 20, filters: parsedFilters, tagFilters: uniq([userId, ...tagFilters])}, AlgoliaIndex.NAMES.FILES],
    // [AlgoliaIndex.NAMES.TRELLO, {hitsPerPage: 10}, AlgoliaIndex.NAMES.TRELLO],
    [AlgoliaIndex.NAMES.TIMELINE, {hitsPerPage: 20, filters: parsedFilters, tagFilters: [userId, ...tagFilters]}, AlgoliaIndex.NAMES.TIMELINE],
    // ["todos", {hitsPerPage: 10, filters: 'status:new', tagFilters: ["group:todos", userId, ...tagFilters]}, AlgoliaIndex.NAMES.SUMMARY],
    // ["urgent", {hitsPerPage: 5, filters: `event.isUrgent:true${parsedFilters ? ` AND ${parsedFilters}` : ""}`, tagFilters: [userId, ...tagFilters]}, AlgoliaIndex.NAMES.SUMMARY],
  ];
}

/**
 * @summary Run a search
 * @function runSearchApp
 * @param {string} query
 * @param {[string, object, string][]} searchRequests
 * @returns {Generator<*, void, *>}
 */
function* runSearchApp(query, searchRequests) {
  const payload = yield call(AlgoliaIndex.searchApp, query, searchRequests);
  yield put({
    type: actions.SEARCH_APP_SUCCESS,
    payload,
  });
}

/**
 * @summary Run a search
 * @function runSearchSimilarity
 * @param {string} query
 * @param {object} params
 * @param {object} params.filters
 * @param {string[]} params.tagFilters
 * @returns {Generator<*, void, *>}
 */
function* runSearchSimilarity(query, {filters, tagFilters}) {
  if (!query) return;
  const response = yield call(api.call, "search-similarity", {text: query, filters, tagFilters});
  yield put({
    type: actions.SEARCH_SIMILARITY_SUCCESS,
    payload: reduce(response?.data?.results, ($acc, {refPath, text, distance_score: distance}) => {
      return $acc.push([refPath, {text: query, distance}]) && $acc;
    }, []),
  });
}

/**
 * @summary Search multi
 * @generator
 * @return {Generator<*, void, *>}
 */
function* searchApp({query, filters, tagFilters}) {
  console.log('searchApp saga query', {query, filters, tagFilters});
  console.log('searchApp saga params', getSearchAppParams(auth.currentUser.uid, {filters, tagFilters}));
  yield fork(runSearchApp, query, getSearchAppParams(auth.currentUser.uid, {filters, tagFilters}));
}

function* searchSimilarity({query, filters, tagFilters}) {
  yield fork(runSearchSimilarity, query, {filters, tagFilters});
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEARCH_APP, searchApp),
    takeEvery(actions.SEARCH_SIMILARITY, searchSimilarity),
  ]);
}
