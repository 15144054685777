import * as React from "react";
import ReactTimeAgo from "react-timeago";

const formatter = (value, unit, defaultSuffix) => {
  // console.log("formatter 1", {value, unit, defaultSuffix})
  const [prefix, suffix] = (defaultSuffix === "from now") ? ["In ", ""] : ["", defaultSuffix];
  if (unit === "second") {
    return "just now";
  }
  if (value === 1) {
    return `${prefix}${value} ${unit} ${suffix}`
  }
  if (unit === "minute") {
    return `${prefix}${value}m ${suffix}`;
  }
  if (unit === "hour") {
    return `${prefix}${value} hrs ${suffix}`;
  }
  if (unit === "day") {
    return `${prefix}${value} days ${suffix}`;
  }
  if (unit === "week") {
    return `${prefix}${value} wks ${suffix}`;
  }
  if (unit === "month") {
    return `${prefix}${value}mo ${suffix}`;
  }
  // if (unit === "months") {
  //     return `${value}mo ago`;
  // }
  if (unit === "year") {
    return `${prefix}${value}yr ${suffix}`;
  }
  return value + unit;
};
// function usMicro(number, index) {
//   return [
//     ["now", "now"],
//     ["%ss", "in %ss"],
//     ["1m", "in 1m"],
//     ["%sm", "in %sm"],
//     ["1h", "in 1h"],
//     ["%sh", "in %sh"],
//     ["1d", "in 1d"],
//     ["%sd", "in %sd"],
//     ["1w", "in 1w"],
//     ["%sw", "in %sw"],
//     ["1Mo", "in 1mo"],
//     ["%sMo", "in %smo"],
//     ["1y", "in 1yr"],
//     ["%sy", "in %syr"],
//   ][index];
// }
// timeAgo.register("usMicro", usMicro);
const TimeAgo = ({value, date, live = false, className}) =>
  <ReactTimeAgo
    minPeriod={60}
    live={live}
    date={value || date}
    formatter={formatter}
    className={className}
  />;
export default TimeAgo;
