import actions from "./actions";
import uniqBy from "lodash/uniqBy.js";

/**
 * @summary Reducer for router
 * @param {object} state
 * @param {object} state.data
 * @param {SearchQueryResults} state.timeline
 * @param {SearchQueryResults} state.urgent
 */
const initState = {
  data: {},
  activity: {
    all: [],
    items: [],
  },
  timeline: {
    list: [],
    nextPage: 0,
    facets: {},
  },
  urgent: {},
};
export default function reducer(state = initState, {type, payload, eventId}) {
  switch (type) {
    // case actions.LOAD_ACTIVITY_SUCCESS: {
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data.activity,
    //       ...(payload.activity?.data || null),
    //     },
    //     activity: {
    //       all: uniq([...state.activity.all, ...payload?.activity?.list]),
    //       items: uniqBy([...state.activity.items, ...payload?.activity?.items], 'key'),
    //     },
    //   };
    // }
    case actions.LOAD_FEED_SUCCESS: {
      // const timeline = {
      //   ...payload,
      //   data: {...state.timeline.data, ...payload.data},
      // };

      // if (fieldValue) {
      //   timeline[fieldValue] = timeline[fieldValue] || [];
      //   timeline[fieldValue] = payload.list;
      // }
      // if (payload instanceof Error) {
      //   return {
      //     ...state,
      //     timeline: {
      //       errorMessage: payload.message,
      //     },
      //   };
      // }
      const {data = null, ...rest} = payload;
      return {
        ...state,
        data: {...state.data, ...data},
        timeline: rest,
      };
    }
    case actions.PAGE_FEED_SUCCESS: {
      // const timeline = {
      //   ...state.timeline,
      //   data: {...state.timeline.data, ...payload.data},
      //   list: uniqBy([...state.timeline.list, ...payload.list], 'key'),
      //   nextPage: payload.nextPage,
      // };

      // if (fieldValue) {
      //   timeline[fieldValue] = timeline[fieldValue] || [];
      //   timeline[fieldValue] = uniqBy([...timeline[fieldValue], ...payload.list], 'key');
      // }
      const {data = null, list = [], ...rest} = payload;
      return {
        ...state,
        data: {...state.data, ...data},
        timeline: {
          ...state.timeline,
          ...rest,
          list: uniqBy([...state.timeline.list, ...list], 'key'),
        },
        // pagers: {...state.pagers, [fieldValue]: nextPage},
      };
    }
    // case actions.LOAD_EVENT_DETAIL_SUCCESS:
    //   const threadId = payload?.data?.[payload.list[0]]?.threadId;
    //   return {
    //     ...state,
    //     timeline: {
    //       ...state.timeline,
    //       data: {...(state.timeline?.data || null), ...payload.data},
    //       list: uniqBy([...(state.timeline?.list || []), ...payload.list], 'key'),
    //       [threadId]: payload.list,
    //     },
    //   }
    // case actions.LOAD_EVENTS_FOR_CONTACT_SUCCESS:
    //   return {
    //     ...state,
    //     timeline: {
    //       ...state.timeline,
    //       data: {...(state.timeline?.data || null), ...payload.data},
    //       list: uniqBy([...(state.timeline?.list || []), ...payload.list], 'key'),
    //       [payload.contactId]: payload.list,
    //     }
    //   }
    case actions.LOAD_EVENT_SUCCESS: {
      // console.log("LOAD_EVENT_SUCCESS", {payload});
      const {data = null, ...rest} = payload;
      return {
        ...state,
        data: {...state.data, ...data},
        // [eventId]: rest,
      }
    }
    case actions.LOAD_URGENT_SUCCESS: {
      console.log("LOAD_URGENT_SUCCESS", {payload});
      const {data = null, ...rest} = payload;
      return {
        ...state,
        data: {...state.data, ...data},
        urgent: {
          ...state.urgent,
          ...rest,
        },
      };
    }
    case actions.LOAD_ITEM_EVENTS_SUCCESS: {
      console.log("LOAD_ITEM_EVENTS_SUCCESS", {payload});
      const {data = null, path, ...rest} = payload;
      return {
        ...state,
        data: {...state.data, ...data},
        [path]: rest,
      };
    }
    default:
      return state;
  }
}
