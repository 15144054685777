import React from 'react';
import {Divider} from 'antd'
import PropTypes from 'prop-types';

LineConnector.propTypes = {
  style: PropTypes.object,
};
function LineConnector({style = null}) {
  return <Divider style={{maxWidth: '40px', minWidth: '20px', ...style}} className="no-top no-bottom" />
}

export default LineConnector;
