import React from 'react';
import PropTypes from 'prop-types';
import {Card, Typography} from "antd";
import {CalendarIcon, TruckIcon} from "@web/assets/icons";
import MarkdownTimeline from "./MarkdownTimeline";
import SlideCardWrapper from "./SlideCardWrapper";
import dayjs from "dayjs";
import {TimelineContext} from "@web/hooks/context";
import {useSelector} from "react-redux";

// const getDisplayFields = ({activity, data}) => {
//   if (activity) {
//     const eventIds = activity?.eventIds || [];
//     const event = activity.events[eventIds[eventIds.length - 1]];
//     return {title: event?.activitySummary};
//   }
//   return {title: data.summary};
// }

TimelineDeliveriesCard.propTypes = {
  path: PropTypes.string.isRequired,
  date: PropTypes.number,
  // content: PropTypes.object,
};

function TimelineDeliveriesCard(props) {
  // const {itemPath, date} = props.deliveries[0];
  // const context = React.useContext(TimelineContext);
  const delivery =
    useSelector((state) => {
      return state.Content.data?.[props.path];
    });

  if (!delivery) {
    return null;
  }
    // const {title} = getDisplayFields(context.content[itemPath]);
    return <Card
      bordered={false}
      title={<div className="space space-center-y">
        <TruckIcon />&nbsp;
        <label className="font-xl card-header-title-text">
          {dayjs(delivery.date || props.date)
          .format("dddd MMMM D[,] h:mm a")}
        </label>
      </div>}
      className="card-rounded card-simple extra-round extra-padding card-body-scroll"
    >
      <section>
      <div
        className="space space-col space-between"
        style={{height: '80%'}}
      >
        <MarkdownTimeline
          markdown={delivery.summary}
          components={{
            p: (props) => {
              return <Typography.Text
                className="ai-text"
                style={{fontSize: '30px', lineHeight: '1.4em', marginBottom: '15px'}}
              >{props.children}
              </Typography.Text>
            }}}
        />
      </div>
      </section>
  </Card>
  //});
}

export default TimelineDeliveriesCard;
