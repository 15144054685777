import {collection, getDoc, doc, getDocs, limit, orderBy, query, where, updateDoc, addDoc, setDoc, deleteDoc, increment as incr, deleteField, arrayUnion, arrayRemove, documentId, serverTimestamp, getCountFromServer, startAfter, DocumentReference, FirestoreError, Query, DocumentData, DocumentSnapshot, QuerySnapshot, CollectionReference, UpdateData, PartialWithFieldValue, WithFieldValue, FieldValue, QueryCompositeFilterConstraint } from 'firebase/firestore'
import {firestore} from './firebase'
import now from 'lodash/now.js'
import reduce from 'lodash/reduce.js'
import flatten from 'lodash/flatten.js'
import isArray from 'lodash/isArray.js'

export const colls = {
  'app-about': 'app-about',
  'ai-scribe': 'ai-scribe',
  'agent-config': 'agent-config',
  'ai-agents': 'ai-agents',
  'ai-agents-list': 'ai-agents-list',
  'ai-article': 'ai-article',
  'ai-augment': 'ai-augment',
  'ai-chat': 'ai-chat',
  'ai-chat-log': 'ai-chat-log',
  'ai-page-builder': 'ai-page-builder',
  'ai-workspace': 'ai-workspace',
  'amazon-lists': 'amazon-lists',
  'app-faq': 'app-faq',
  'art-image': 'art-image',
  'business-profile': 'business-profile',
  'openai-brainstorm': 'openai-brainstorm',
  'openai-content': 'openai-content',
  'openai-models': 'openai-models',
  'oven-apps': 'oven-apps',
  'oven-stripe-log': 'oven-stripe-log',
  'post': 'post',
  "stripe-customers": "stripe-customers",
  'stripe-products': 'stripe-products',
  'link-listings': 'link-listings',
  'looks': 'looks',
  'looks-swipe': 'looks-swipe',
  'stage': 'stage',
  'user': 'user',
  'user-audience': 'user-audience',
  'user-feedback': 'user-feedback',
  'user-interests': 'user-interests',
  'user-tips': 'user-tips',
  'wait-list': 'wait-list',
  'inbox': 'inbox',
  'outbox': 'outbox',
  'messages': 'messages',
  'prompts': 'prompts',
  'user-message': 'user-message',
  'ai-queue': 'ai-queue',
}
export const increment = incr
/**
 * @summary Get a timestamp from the server
 * @type {function(): FieldValue}
 */
export const ServerTimestamp = serverTimestamp
/**
 * @typedef {Array} DataItem
 * @property {string} 0 - document id
 * @property {object} 1 - document data
 * @property {string} 2 - document path
 */

/**
 * @summary Convert a firestore query snapshot to an array of data items
 * @function toDataPairs
 * @param {{docs: object[], size: number}} querySnapshot
 * @returns {DataItem[]}
 */
export const toDataPairs = (querySnapshot) => {
  if (!querySnapshot?.size) return []
  const pairs = []
  querySnapshot.size && querySnapshot.docs.forEach(snapshot => pairs.push([snapshot.id, snapshot.data(), snapshot.ref.path]))
  return pairs
}

/**
 * @summary Generate a document id
 * @function generateID
 * @returns {string}
 */
export const generateID = () => doc(collection(firestore, 'dummy')).id

/**
 * @function safeValueForDB
 * @param rawJson {object} - raw json object
 * @returns {{length}|*|null|{}|null} - json object with all null values removed
 */
export const safeValueForDB = (rawJson) => {
  switch (true) {
    case (rawJson == null): {
      return {};
    }
    case isArray(rawJson): {
      const array = rawJson.reduce((accumulator, item) => {
        if (item && item !== undefined) accumulator.push(safeValueForDB(item));
        return accumulator;
      }, []);
      return array.length ? array : null;
    }
    case (typeof rawJson === "object"): {
      if (rawJson.constructor.name === "Timestamp") {
        return rawJson;
      }
      const map = {};
      Object.keys(rawJson).forEach((key) => {
        if (key && rawJson[key] !== undefined && rawJson[key] != null) {
          map[key] = safeValueForDB(rawJson[key]);
        }
      });
      return map;
    }
    default: {
      if (rawJson === undefined) rawJson = null;
      return rawJson;
    }
  }
}

/**
 * @summary Sanitize string for use as db key
 * @static
 * @param {string} key
 * @param {Number} [maxLength]
 * @return {string}
 */
export const safeKeyForDB = (key, maxLength = 250) => {
  // Remove any special characters and limit the string length
  const sanitizedUrl = key.replace(/[^\w\d]/g, "_").substring(0, maxLength);
  /**
   * TODO - Implement browser safe crypto module and uncomment below
   *    until fixed, urls greater maxLength param will not be retrieved.
   */
  // If the sanitized URL is too long, create a hash to ensure uniqueness
  // if (sanitizedUrl.length === maxLength) {
  //   const hash = crypto.createHash("md5").update(key).digest("hex");
  //   return sanitizedUrl.substring(0, maxLength - hash.length) + hash;
  // }
  return sanitizedUrl;
};

/**
 * @function buildQuery
 * @param collectionPath {string | string[]} - name of the collection
 * @param fields {object} - fields to query by (e.g. {name: 'John'})
 * @param queryLimit {number} - limit the number of results
 * @param order {[]} - order by (e.g. [['name', 'asc']])
 * @param {{startAfter:number|string}} [pagingOptions] - paging options (e.g. {startAfter: 'abc123'})
 * @returns {Query<DocumentData>} - firestore query object
 */
export const buildQuery = (collectionPath, fields, {limit: queryLimit = 10, order = []} = {}, pagingOptions) => {
  try {
    const collectionName = typeof collectionPath === 'string' ?
      collectionPath :
      collectionPath.join('/')

    /** @type {QueryCompositeFilterConstraint[]} */
    const queryFields = [
      ...flatten(Object
      .keys(fields)
      .map(indicator => {
      return reduce(fields[indicator], (predicates, value, fieldName) => {
        predicates.push(where(fieldName, indicator, value))
        return predicates
      }, []);
    })),
      ...order.map(([field, direction = 'asc']) => orderBy(field, direction)),
      limit(queryLimit),
    ];
    pagingOptions?.startAfter &&
    queryFields.push(startAfter(pagingOptions.startAfter))
    return query(collection(firestore, collectionName), ...queryFields)
  } catch (error) {
    console.error('buildQuery error', error)
    return null;
  }
}

/**
 * @summary Get a count of query results from the server
 * @param {Query} query
 * @return {Promise<number>}
 */
export const getCollectionCount = async (query) => {
  const snap = await getCountFromServer(query);
  return snap.data().count;
}
/**
 * @param {string} collectionPath - name of the collection
 * @param documentId {string} - document id to get from collection
 * @returns {DocumentReference<DocumentData>} - firestore document reference
 */
export const getDocumentRef = (collectionPath, documentId) => {
  return doc(firestore, `${collectionPath}/${documentId}`);
}

/**
 * @summary Get a collection of files
 * @param {Query} query
 * @returns {Promise<QuerySnapshot<unknown, DocumentData> | Error>}
 */
export const getDocuments = (query) =>
  getDocs(query)
  .catch((error) => {
    console.log('getDocuments error', error.code, error.message, query);
    return error;
  });

/**
 * @summary get a collection reference
 * @param {string | string[]} collectionNameOrPath
 * @return {CollectionReference<DocumentData>}
 */
export const getCollection = (collectionNameOrPath) => {
  if (!collectionNameOrPath) throw new Error(`CollectionName is required`)
  const collectionPath = (isArray(collectionNameOrPath)) ? collectionNameOrPath.join('/') : collectionNameOrPath
  return collection(firestore, collectionPath);
}

// export const listCollections = async (documentPath) => {
//   const collections = null;
//   return null;
// }

/**
 * @summary get a document reference
 * @param {string | string[]} collectionPath
 * @param {string} documentId
 * @return {Promise<DocumentSnapshot<DocumentData>>}
 */
export const getDocument = async (collectionPath, documentId) => {
  if (!documentId) throw new Error(`documentID is required`);
  const collectionName = (isArray(collectionPath)) ? collectionPath.join('/') : collectionPath;
  return await getDoc(getDocumentRef(collectionName, documentId));
}

/**
 * @summary Get a document from a collection
 * @param {string|string[]} path
 * @returns {Promise<DocumentSnapshot<DocumentData, DocumentData> | Error>}
 */
export const getPath = async (path) => {
  try {
    const ref = doc(firestore, typeof path === 'string' ? path : path.join('/'));
    return await getDoc(ref);
  } catch (error) {
    console.log('getPath error', error.code, error.message, path);
    return error;
  }
}
/**
 * @summary Update a document in a collection
 * @param {string} collectionName
 * @param {string} documentId
 * @param {UpdateData} updateData
 * @returns {Promise<false|void>}
 */
export const updateDocument = async (collectionName, documentId, updateData) => {
  const ref = getDocumentRef(collectionName, documentId)
  return !!ref && await updateDoc(ref, updateData)
}

/**
 * @summary Update a document in a collection
 * @param {string|string[]} path - path to document
 * @param {UpdateData|null} updateData
 * @returns {Promise<false|void>}
 */
export const updatePath = async (path, updateData) => {
  const ref = doc(firestore, flatten([path]).join('/'));
  return !!ref && await updateDoc(ref, updateData)
    .catch((error) => {
      console.log('updatePath error', error.code, error.message, path);
    });
}

/**
 * @summary Set document in a collection by path
 * @param {string|string[]} path - path to document
 * @param {PartialWithFieldValue<DocumentData>} documentData
 * @param {boolean} merge
 * @returns {Promise<false|void>}
 */
export const setPath = async (path, documentData, merge = false) => {
  const ref = doc(firestore, flatten([path]).join('/'));
  return !!ref && await setDoc(ref, documentData, {merge})
}
/**
 * @summary Delete document in a collection by path
 * @param {string|string[]} path - path to document
 * @returns {Promise<void>}
 */
export const deletePath = async (path) => {
  const ref = doc(firestore, flatten([path]).join('/'));
  return deleteDoc(ref)
    .catch((err) => console.log(err.message, path));
}

/**
 * @summary Add a document to a collection
 * @param {string|string[]} collectionPath - path to document
 * @param {WithFieldValue<DocumentData>} documentData
 * @returns {Promise<false|DocumentReference<DocumentData>>}
 */
export const addPath = async (collectionPath, documentData) => {
  const ref =
    collection(firestore, flatten([collectionPath]).join('/'));
  return !!ref &&
    await addDoc(ref, documentData)
}
/**
 * @summary Add a document to a collection
 * @param {string} collectionName
 * @param {WithFieldValue<DocumentData>} documentData
 * @returns {Promise<false|DocumentReference<DocumentData, DocumentData>>}
 */
export const addDocument = async (collectionName, documentData) => {
  const collectionReference = getCollection(collectionName);
  /** @type {WithFieldValue<DocumentData>} */
  const payload = {
    ...documentData, createdTs: now(), updatedTs: now(),
  };
  return !!documentData && !!collection && await addDoc(collectionReference, payload)
}

/**
 * @summary Add a document to a sub-collection
 * @function addDocumentSub
 * @param {string[] | string} pathArray
 * @param {WithFieldValue<DocumentData>} documentData
 * @returns {Promise<false|DocumentReference<DocumentData, DocumentData>>}
 */
export const addDocumentSub = async (pathArray, documentData) => {
  const collection = getCollection(pathArray);
  if (!collection) return null;
  if (!documentData) return null;
  return await addDoc(collection, {
    ...safeValueForDB(documentData), created: ServerTimestamp(), updated: ServerTimestamp(),
  })
}

/**
 * @summary Add a document to a collection or sub-collection
 * @param {string[]} collectionNameOrPath
 * @param {WithFieldValue<{}>} data
 * @returns {Promise<null | DocumentReference<DocumentData, DocumentData>>}
 */
export const addDocument_NoTimestamp = async (collectionNameOrPath, data) => {
  const collection = getCollection(collectionNameOrPath);
  if (!collection) return null;
  if (!data) return null;
  return await addDoc(collection, data);
}

/**
 * @summary Set document in a collection
 * @param {string} collectionName
 * @param {string} documentId
 * @param {PartialWithFieldValue<DocumentData> | WithFieldValue<DocumentData>} data
 * @param {boolean} merge
 * @returns {Promise<void | null | DocumentReference<DocumentData, DocumentData>>}
 */
export const setDocument = async (collectionName, documentId, data, merge = false) => {
  const ref = getDocumentRef(collectionName, documentId)
  if (!ref) return null;
  return merge ?
    await setDoc(ref, data, {merge: true}) :
    await setDoc(ref, data);
}

/**
 * @summary Set document in a sub-collection
 * @function setDocumentSub
 * @param {string | string[]} collectionPath
 * @param {string} documentId
 * @param {PartialWithFieldValue<DocumentData> | WithFieldValue<DocumentData>} data
 * @param {boolean} merge
 * @returns {Promise<void | null | DocumentReference<DocumentData, DocumentData>>}
 */
export const setDocumentSub = async (collectionPath, documentId, data, merge = false) => {
  const ref = doc(firestore, collectionPath.join('/'), documentId)
  if (!ref) return null
  return merge ?
    await setDoc(ref, data, {merge: true}) :
    await setDoc(ref, data)
}
/**
 * @summary Delete a document from a collection
 * @function deleteDocument
 * @param {string | string[]} collectionName
 * @param {string} documentId
 * @returns {Promise<null | void>}
 */
export const deleteDocument = async (collectionName, documentId) => {
  const ref = getDocumentRef(collectionName, documentId);
  if (!ref) return null;
  return await deleteDoc(ref);
}
/**
 * @summary Delete a document from a sub-collection
 * @type {{deleteField: () => FieldValue, arrayRemove: (...elements: unknown[]) => FieldValue, documentId: () => FieldPath, arrayUnion: (...elements: unknown[]) => FieldValue}}
 */
export const Fields = {
  deleteField,
  arrayUnion,
  arrayRemove,
  documentId,
}
