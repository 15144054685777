import {all, takeEvery, call, put, delay} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {colls, buildQuery, getDocuments} from "@web/lib/firestore.db";
import {toFirestoreQueryItems, firestore as channel} from "../channels";

const pageLimit = 10;
let eventsIndexPage = 0;

/**
 * @summary Build and return a query
 * @param {Number} [limit]
 * @param {Number|String|FirebaseFirestore.DocumentSnapshot} [startAfter]
 * @param {string} [memberUserId]
 * @return {Query<DocumentData>}
 */
const getQuery = ({limit = pageLimit, startAfter}, memberUserId) => {
  return buildQuery(
    [colls.user, auth.currentUser.uid, "calendars"],
    memberUserId ?
      {"array-contains": {memberUserIds: memberUserId}} :
      {},
    {order: [['startTs', 'desc']], limit},
    {startAfter},
  );
};

/**
 * @summary sync email
 * @generator
 * @return {Generator<*, void, *>}
 */
function* syncCalendar(params) {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getQuery({...params, limit: 1}),
    success: actions.SYNC_CALENDAR_SUCCESS,
  }];
  yield call(channel, listenTo);
}

/**
 * Load and sync email
 * @param {object} action
 * @param {object} action.params
 * @param {string} action.memberUserId
 * @return {Generator<*, void, *>}
 */
function* loadCalendar({params = {}, memberUserId}) {
  if (!auth.currentUser?.uid) return;
  if (params.startAfter === null) {
    console.log("loadCalendar:no more events");
    return;
  }
  const query = getQuery(params, memberUserId);
  const querySnapshot = yield call(getDocuments, query);
  yield put({
    type: actions.LOAD_CALENDAR_SUCCESS,
    events: toFirestoreQueryItems(querySnapshot),
    pageParams: {
      ...params,
      startAfter: querySnapshot.docs?.[pageLimit - 1] || null,
    },
  });
  if (!memberUserId && !params.startAfter) {
    delay(5000);
    yield call(syncCalendar, params);
  }
}

function* pageNext({params, memberUserId}) {
  eventsIndexPage += 1;
  yield call(loadCalendar, {params, memberUserId});
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CALENDAR, loadCalendar),
    takeEvery(actions.PAGE_CALENDAR, pageNext),
  ]);
}
