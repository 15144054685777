import React from 'react'
import {Divider} from 'antd'
import {Link} from 'wouter'
import HotClick from '@web/ui/containers/HotClick'

export default function LoginLinks() {
  return <div className="space space-center-x space-center-y">
    <HotClick><Link href="/">Home</Link></HotClick>
    <Divider type={"vertical"} />
    <HotClick><Link href="/about">About</Link></HotClick>
    <Divider type={"vertical"} />
    <HotClick><a href="/legal/terms.html">Terms</a></HotClick>
    <Divider type="vertical" />
    <HotClick><Link href="/about/faq">FAQ</Link></HotClick>
  </div>
}
