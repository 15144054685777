import React from "react";
import PropTypes from "prop-types";
// import UserAvatar from "./UserAvatar";
import {List} from "antd";
import MemberAvatar from "./MemberAvatar";
import {useSelector} from "react-redux";
import {AppContext} from "@web/hooks/context";

MembersList.propTypes = {
  size: PropTypes.any,
  avatarOnly: PropTypes.bool,
  itemPath: PropTypes.string,
  minCount: PropTypes.number,
};

function MembersList(props) {
  const [, user] =
    React.useContext(AppContext);

  const membersUserIdList =
    useSelector((state) => {
      return state.Content.activity?.[props.itemPath]?.membersList;
    }) || [];

  if (!membersUserIdList.length) {
    return null;
  }

  if (membersUserIdList.length < props.minCount) {
    return null;
  }

  return <List
    className={"team-list list-item-no-divider"}
    itemLayout="vertical"
    dataSource={membersUserIdList}
    renderItem={(memberUserId) => {
      return <List.Item
        key={memberUserId}
      >
        <MemberAvatar
          userId={user.uid}
          memberUserId={memberUserId}
          size={props.size}
          avatarOnly={props.avatarOnly}
        />
        {/*</TopContactData>*/}
      </List.Item>
        // <List.Item className="team-list-item-inactive" key={index}>
        //   <UserAvatar
        //     key={index} displayName="" size={props.size} icon={index === membersUserIdList.length ? <PlusIcon/> : null}/>
        // </List.Item>;
    }
  }
    locale={{
      emptyText: <List.Item className="team-list-item-inactive" key={0}>
      </List.Item>,
    }}
  />;
}

export default MembersList;
