import {useSelector, useDispatch} from "react-redux";
import activityActions from "@web/redux/activity/actions";
import {getSelectedMember, getSelectedUserId} from "@web/redux/activity/selectors";

/**
 * @function useSelectedContact
 * @return {[string, function]}
 */
const useSelectedContact = () => {
  const dispatch = useDispatch();
  const selectedUserId = useSelector(getSelectedUserId);
  const selectedMember = useSelector(getSelectedMember);
  const unselect = () => {
    if (!selectedMember) return;
    dispatch(activityActions.selectContact(selectedUserId));
  }
  return [selectedMember, unselect]
}

export default useSelectedContact
