/** @namespace chatStream */
import {useSelector} from "react-redux";

/**
 * @function useChatStreamListener
 * @memberof chatStream
 * @param {string} agentId
 * @returns {[chat:models.Chat.Data, collectionPath:string]}
 */
function useChatStreamListener(agentId) {
  /** @type {[models.Chat.Data, string]} */
  const stream =
    useSelector((state) =>
      state.AI[agentId]);

  return [stream?.[0], stream?.[1], stream?.[2]];
}

export default useChatStreamListener;
