const actions = {
  LOAD_FEED: "LOAD_FEED",
  LOAD_FEED_SUCCESS: "LOAD_FEED_SUCCESS",
  SYNC_TIMELINE: "SYNC_TIMELINE",
  SYNC_TIMELINE_SUCCESS: "SYNC_TIMELINE_SUCCESS",
  PAGE_FEED: "PAGE_FEED",
  PAGE_FEED_SUCCESS: "PAGE_FEED_SUCCESS",
  LOAD_EVENT_DETAIL: "LOAD_EVENT_DETAIL",
  LOAD_EVENT_DETAIL_SUCCESS: "LOAD_EVENT_DETAIL_SUCCESS",
  LOAD_EVENTS_FOR_CONTACT: "LOAD_EVENTS_FOR_CONTACT",
  LOAD_EVENTS_FOR_CONTACT_SUCCESS: "LOAD_EVENTS_FOR_CONTACT_SUCCESS",
  LOAD_EVENT: "LOAD_EVENT",
  LOAD_EVENT_SUCCESS: "LOAD_EVENT_SUCCESS",
  LOAD_URGENT: "LOAD_URGENT",
  LOAD_URGENT_SUCCESS: "LOAD_URGENT_SUCCESS",
  LOAD_ITEM_EVENTS: "LOAD_ITEM_EVENTS",
  LOAD_ITEM_EVENTS_SUCCESS: "LOAD_ITEM_EVENTS_SUCCESS",

  // loadActivity: () => {
  //   return (dispatch) => {
  //     console.log('loadActivity');
  //     dispatch({
  //       type: actions.LOAD_ACTIVITY,
  //       // field,
  //       // fieldValue,
  //       // params,
  //     });
  //   };
  // },
  loadFeed: () => {
    return (dispatch) => {
      // console.log('loadFeed');
      dispatch({
        type: actions.LOAD_FEED,
        // field,
        // fieldValue,
        // params,
      });
    };
  },
  pageFeed: () => {
    return (dispatch, getState) => {
        // console.log('pageTimeline')
      // console.log("pageTimeline action", {field, fieldValue});
      const nextPage = getState().Events.timeline?.nextPage;
      // const searchAppParams = getState().Search.app;
      if (!nextPage) {
        console.log('Next page value not found', {nextPage});
        return;
      }
      dispatch({
        type: actions.PAGE_FEED,
        page: nextPage,
      });
    };
  },
  loadItemEvents: ({path}) => {
    return (dispatch) => {
      dispatch({
        type: actions.LOAD_ITEM_EVENTS,
        path,
      });
    };
  },
  // loadEventDetail: ({eventId}) => {
  //   return (dispatch, getState) => {
  //     const threadId = getState().Events?.timeline?.data?.[eventId]?.threadId;
  //     console.log("loadEventDetail", eventId, threadId);
  //     dispatch({
  //       type: actions.LOAD_EVENT_DETAIL, eventId, threadId,
  //     });
  //     dispatch({
  //       type: feedActions.LOAD_THREAD_ATTACHMENTS, threadId,
  //     });
  //   };
  // },
  // loadEventsForContact: ({contactId, email}) => {
  //   return (dispatch) => {
  //     dispatch({
  //       type: actions.LOAD_EVENTS_FOR_CONTACT, contactId, email,
  //     });
  //   };
  // },
  loadEvent: (eventId) => {
    return (dispatch) => {
      console.log("loadEvent action");
      dispatch({
        type: actions.LOAD_EVENT,
        eventId,
      });
    };
  },
  loadUrgent: () => {
    return (dispatch) => {
      console.log("loadUrgent action");
      dispatch({
        type: actions.LOAD_URGENT,
      });
    };
  },
};

export default actions;
