import React, {useContext} from "react";
import {Alert, Card, Col, Empty, List, Row, Space, Image, Typography, Button} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {buildQuery} from "@web/lib/firestore.db";
import Loading from "./Loading";
import AppIntegrationsData from "@web/ui/containers/AppIntegrationsData";
import AppIntegrationsList from "./AppIntegrationsList";
import ExternalLink from "./ExternalLink";
import {RightArrowOutlined} from "@web/assets/icons";
import GroupTitle from "./GroupTitle";
// import WithDrawer from "@web/ui/containers/WithDrawer";

ProductsList.propTypes = {};

function ProductsList() {
  const [, user] = useContext(AppContext);
  const [isMobile, w, height] = useContext(WindowContext);
  const [pairs = [], dataLoading, error] = useQueryFirestore(user.uid && buildQuery(
    ["user", user.uid, "products"],
    {}, {order: [["updatedTs", "desc"]], limit: 50}
  ));
  if (!user?.uid) {
    return null;
  }
  if (error) {
    return <Alert message={error.message} type="error" showIcon closable/>;
  }
  if (dataLoading) {
    return <Loading/>;
  }

  return <Row>
    <Col xs={24} sm={24} md={24} lg={24} xl={23} xxl={23}>
      <Card className="card-rounded card-simple card-transparent" bordered={false} style={{margin: '0 20px 20px 0', minHeight: `${height * 0.55}px`}}>
      <List
        className="list-empty-no-padding list-item-no-divider"
        header={<GroupTitle title="Store" style={{paddingLeft: '15px'}} />}
        // action={[<Button ghost><RightArrowOutlined /></Button>]}
        dataSource={pairs}
        pagination={{pageSize: 10}}
        renderItem={([id, product, path]) =>
          <List.Item
            key={path}
            actions={[
              <Button size="small" ghost><RightArrowOutlined className="muted-icon" /></Button>,
            ]}
          >
            <div className="space space-col" style={{width: '100%', marginBottom: '20px'}}>
              <h6>
                <ExternalLink href={product.permalink} className="ai-text external-link underline">{product.name}
                </ExternalLink>
              </h6>
              <span className="muted">{product.shortDescription.substring(0, 100)}</span>
              <Space>
                {product.images.map((image) =>
                  <Image
                    key={image.name}
                    src={image.thumbnail}
                    width={60}
                    preview={{src: image.src}}
                    style={{borderRadius: '8px'}}
                  />)
                }
              </Space>
            </div>
        </List.Item>
      }
        loading={dataLoading}
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
            <Card className="card-simple card-rounded card-no-header" bordered={false}>
              <AppIntegrationsData filter="all" tags={["products"]}>
                <AppIntegrationsList/>
              </AppIntegrationsData>
            </Card>
          </Empty>
        }}
      />
      </Card>
    </Col>
    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}/>
  </Row>
    {/*<Drawer serviceId="products" hideCloseIcon />*/}
  {/*</React.Fragment>*/}
}

export default ProductsList;
