import React from "react";
import PropTypes from "prop-types";
import {TrelloIcon} from "@web/assets/icons";
import {Button, Typography} from "antd";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {AppContext} from "@web/hooks/context";
import RefreshSourceButton from "./RefreshSourceButton";
import now from "lodash/now";
import {notifyAndTrack} from "./notification";

const trelloApiKey = process.env.REACT_APP_TRELLO_API_KEY;

TrelloAuthButton.propTypes = {
  integrationId: PropTypes.string.isRequired,
  integration: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

function TrelloAuthButton({integration, integrationId, label = "Connect", disabled}) {
  const [,user] = React.useContext(AppContext)
  const [userAuth, loadingUserAuth = false, errorLoadingUserAuth = false] = useDocumentFirestore(["user", user?.uid, 'providers', integrationId])
  const [authorized, setAuthorized] = React.useState(false);
  const [loaderErrorCode, setLoaderErrorCode] = React.useState(0);

  React.useEffect(() => {
    if (loadingUserAuth) return;
    if (!user) return;
    if (user.isAnonymous) return;
    if (!integration?.loader) return;
    if (!userAuth?.expireTs) return;
    if (userAuth.expireTs <= now()) return;
    setAuthorized(true);
  }, [user, userAuth, loadingUserAuth, integration?.loader])

  const onLoaderError = React.useCallback((errorCode) => {
    if (errorCode === 401) {
      setAuthorized(false);
      setLoaderErrorCode(401);
    }
  }, [authorized, loaderErrorCode])

  if (authorized) {
    return <RefreshSourceButton
      loader={integration?.loader}
      onLoadError={onLoaderError}
    />
  }

  if (loadingUserAuth) return null;
  if (errorLoadingUserAuth) return notifyAndTrack.warn('Connect failed', 'Unable to complete your request at this time', {errorLoadingUserAuth});

  const authorizeUrl = new URL(integration.authorizeUrl);
  authorizeUrl.searchParams.append('key', trelloApiKey);
  authorizeUrl.searchParams.append('return_url', `${location.origin}/oauth/${integrationId}/callback`);
  authorizeUrl.searchParams.append('callback_method', 'fragment');

  return <Button disabled={disabled}>
    <TrelloIcon size={16} style={{marginRight: '10px', verticalAlign: 'middle'}} />
    <Typography.Link href={authorizeUrl.href}>
      {label}
    </Typography.Link>
  </Button>
}

export default TrelloAuthButton;
