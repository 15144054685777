import {firebaseApp} from "@web/lib/firebase";
import {connectFunctionsEmulator, getFunctions, HttpsCallableResult} from "firebase/functions";
import {useHttpsCallable} from "react-firebase-hooks/functions";

if (process.env.NODE_ENV !== "production") {
  connectFunctionsEmulator(getFunctions(firebaseApp, "us-west1"), "localhost", 5001);
}

/**
 * @summary useFunctionsCallable
 * @param {string} functionName
 * @param {string} [region]
 * @return {readonly [((data?: (unknown | undefined)) => Promise<HttpsCallableResult<object> | undefined>), boolean, (Error | undefined)]}
 */
export default function useFunctionsCallable(functionName, region = "us-west1") {
  return useHttpsCallable(getFunctions(firebaseApp, region), functionName);
}
