import React, {useEffect} from "react";
import {Alert, Button} from "antd";
import {GoogleIcon} from "@web/assets/icons";
import {getAdditionalUserInfo, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "@web/lib/firebase";
import {AppContext, WindowContext} from "@web/hooks/context";
import useLocalStorage from "@web/hooks/useLocalStorage";
import {safeValueForDB, setDocument, setDocumentSub} from "@web/lib/firestore.db";
import {string} from "@web/lib/regex";
import now from "lodash/now";
import {useLocation} from "wouter";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import RefreshSourceButton from "./RefreshSourceButton";
import {api} from "@web/lib/api";
import {notifyAndTrack} from "./notification";
import {ProgressBar} from "./ProgressBar";
import isNumber from "lodash/isNumber";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const provider = new GoogleAuthProvider();

export const SCOPES = {
  readonly: {
    gmail: "https://www.googleapis.com/auth/gmail.readonly",
    drive: "https://www.googleapis.com/auth/drive.readonly",
    docs: "https://www.googleapis.com/auth/documents.readonly",
    calendar: "https://www.googleapis.com/auth/calendar.readonly",
    contacts: "https://www.googleapis.com/auth/contacts.readonly",
    sheets: "https://www.googleapis.com/auth/spreadsheets.readonly",
    tasks: "https://www.googleapis.com/auth/tasks.readonly",
    analytics: "https://www.googleapis.com/auth/analytics.readonly",
    slides: "https://www.googleapis.com/auth/presentations.readonly",
    youtube: "https://www.googleapis.com/auth/youtube.readonly",
    photos: "https://www.googleapis.com/auth/photoslibrary.readonly",
    cloud: "https://www.googleapis.com/auth/cloud-platform.readonly",
  },
  write: {
    gmailSend: "https://www.googleapis.com/auth/gmail.send",
  }
};
/**
 * @summary Update user auth data in firestore
 * @param user
 * @param profile
 * @param credential
 */
const updateUser = ({user, profile, credential}) => {
  const providerId = credential.providerId;
  const providerKey = string.replacePunctuationAndSpaces(providerId, "-");
  const createdTs = +user.metadata.createdAt;
  const {granted_scopes: grantedScopes, ...rest} = profile.profile;
  const userPayload = {};
  userPayload.updatedTs = now();
  userPayload.lastLoginTs = +user.metadata.lastLoginAt;
  userPayload.lastLoginProvider = providerId || null;
  if (profile.isNewUser) {
    userPayload.createdTs = createdTs;
  }

  const client = {
    ...rest,
    oauthCredentials: {
      ...safeValueForDB(credential.toJSON()),
      expireTs: dayjs().add(1, 'hour').valueOf(),
    },
    grantedScopes,
    email: user.email,
    updatedTs: now(),
  }
  return setDocument("user", user.uid, userPayload)
    .then(() => setDocumentSub(["user", user.uid, "providers"], providerKey, {client}, {merge: true}))
    .catch(err => console.error(err));
};

GoogleAuthButton.propTypes = {
  integration: PropTypes.object,
  scopes: PropTypes.array,
  onStart: PropTypes.func,
  block: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.node,
}

function GoogleAuthButton(props) {
  const [, user] = React.useContext(AppContext);
  const [, width, height] = React.useContext(WindowContext);
  const [userAuth, loadingUserAuth = false, errorLoadingUserAuth = false] =
    useDocumentFirestore(["user", user?.uid, "providers", "google-com"]);

  const [, setAnonUID] = useLocalStorage("anon-user-id");
  const [, setLocation] = useLocation();
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [loaderErrorCode, setLoaderErrorCode] = React.useState(0);
  const {scopes: requestedScopes = [], integrationId} = props;
  const scopesToRequest = loaderErrorCode === 401 ?
    requestedScopes :
    requestedScopes.filter((scope) => !userAuth?.grantedScopes?.includes(scope));

  const onLoaderError = React.useCallback((data) => {
    const errorCode = isNumber(data) ? data : data?.code || 0;
    if (errorCode === 401) {
      setAuthorized(false);
      setLoaderErrorCode(401);
    }
  }, [authorized, loaderErrorCode]);

  useEffect(() => {
    if (loadingUserAuth) return;
    if (!user) return;
    if (user.isAnonymous) return;
    if (!props.integration?.loader) return;
    if (isLoggingIn) return;
    if (loaderErrorCode === 401) return;
    if (scopesToRequest.length) return;
    if ((userAuth?.oauthCredentials?.expireTs || 0) <= now() && !userAuth?.oauthCredentials.refreshToken) return;
    setAuthorized(true);
  }, [user, userAuth, scopesToRequest, isLoggingIn, loadingUserAuth, loaderErrorCode, props.integration?.loader]);

  if (authorized && integrationId) {
    return <RefreshSourceButton loader={props.integration?.loader} onLoadError={onLoaderError} onStart={props.onStart}/>;
  }

  // const scopes = [...scopesToRequest];
  scopesToRequest.forEach((scope) => provider.addScope(scope));

  if (loadingUserAuth) {
    return null;
  }
  if (errorLoadingUserAuth) {
    return <Alert type="info" message="An error occurred"/>;
  }

  const onClickSignIn = () => {
    console.log("GoogleAuthButton:onClickSignIn", {user, integrationId, scopesToRequest});
    if (user?.uid && (integrationId || scopesToRequest?.length)) {
      setIsLoggingIn(true);
      return api.call("google-authLinks", {
        integrationId,
        scopes: scopesToRequest,
      })
      .then(({data, error}) => {
        console.log("googleAuthButton:authLink:data", {data, error});
        setIsLoggingIn(false);
        if (error) {
          return notifyAndTrack.error(error, "Unable to complete your request");
        }
        window.open(data.authUrl, "_blank", `popup=true,width=800,height=800,noreferrer,left=${(width / 2) - 400},top=${(height / 2) - 400}`);
      });
    }
    // setIsLoggingIn(true)
    user?.isAnonymous && setAnonUID(user.uid);
    signInWithPopup(auth, provider)
      .then((result) => {
        // console.log('result', result)
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // console.log('credential', credential)
        //const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // console.log('user', user)
        // IdP data available using getAdditionalUserInfo(result)
        const profile = getAdditionalUserInfo(result);
        // console.log('profile', profile)
        setIsLoggingIn(false);
        setLoaderErrorCode(0);
        return {user, profile, credential};
      })
      .then(updateUser)
      .then(() => setLocation("/"))
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        setIsLoggingIn(false);
        setLoaderErrorCode(0);
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // console.log('error', errorCode, errorMessage)
        // console.log('credential from error', credential)
      });
  };
  return <Button disabled={isLoggingIn} block={!!props.block} size={props.size || "large"} onClick={onClickSignIn} className="icon-btn">
    <GoogleIcon size={props.size === "default" ? 14 : 16} style={{marginRight: "10px"}}/> {isLoggingIn ?
      <ProgressBar visible/> :
      (props.children || "Login with Google")}
  </Button>;
}

export default GoogleAuthButton;
