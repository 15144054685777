import actions from "./actions";

const initState = {
  isLoading: false,
  data: {},
  list: [],
  query: "",
  error: null,
  params: {
    page: 0,
    typoTolerance: false,
    hitsPerPage: 20,
    filters: {},
    facetFilters: [],
    numericFilters: [],
    tagFilters: [],
    relevancyStrictness: 90,
    attributesToRetrieve: ["_tags", "date", "path", "events", "itemPath"],
    responseFields: [
      'hits',
      'hitsPerPage',
      'nbPages',
      'page',
      'query',
      'processingTimeMS',
      'serverTimeMS',
      'facets',
      'index',
      'nbHits',
    ],
  },
};

export default function reducer(state = initState, {type, payload}) {
  switch (type) {
    case actions.LOAD_TIMELINE_SUCCESS: {
      console.log('LOAD_TIMELINE_SUCCESS', payload);
      return {
        ...state,
        ...payload,
        // members: sortBy(Object.entries(payload.members), 1),
      };
    }
    default: {
      return state;
    }
  }
}
