import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {AppContext} from "@web/hooks/context";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import UserContentPreview from "./UserContentPreview";

ShortCodeMedia.propTypes = {
  code: PropTypes.string.isRequired,
  children: PropTypes.node,
};

function ShortCodeMedia(props) {
  const [_, user] = useContext(AppContext);
  const [doc] = useDocumentFirestore(["user", user.uid, "short-codes", props.code].join("/"));
  console.log("ShortCodeMedia", props, doc);
  return <React.Fragment>
    <div className="space space-center-y">
      {doc?.payload?.paths?.map((path) =>
        <UserContentPreview path={path} href={props.href}>
          {props.children}
        </UserContentPreview>)}
    </div>
  </React.Fragment>
}

export default ShortCodeMedia;
