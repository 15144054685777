const actions = {
  LOAD_DOCUMENTS: "LOAD_DOCUMENTS",
  LOAD_DOCUMENTS_SUCCESS: "LOAD_DOCUMENTS_SUCCESS",
  PAGE_DOCUMENTS: "PAGE_DOCUMENTS",
  PAGE_DOCUMENTS_SUCCESS: "PAGE_DOCUMENTS_SUCCESS",
  SYNC_DOCUMENTS: "SYNC_DOCUMENTS",
  SYNC_DOCUMENTS_SUCCESS: "SYNC_DOCUMENTS_SUCCESS",
  load: (memberUserId) => {
    return (dispatch) => {
      // console.log("loadDocuments");
      dispatch({
        type: actions.LOAD_DOCUMENTS,
        params: {},
        memberUserId,
      });
    };
  },
  page: () => {
    return (dispatch, getState) => {
      console.log("pageDocuments");
      const params = getState().Documents?.pageParams;
      dispatch({
        type: actions.PAGE_DOCUMENTS,
        params,
      });
    };
  },
};

export default actions;
