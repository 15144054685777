import React from "react";
import PropTypes from "prop-types";
import LineConnector from "./LineConnector";
import {DatePicker, Space} from "antd";
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import {AppContext} from "@web/hooks/context";
import dayjs from "dayjs";
import {CheckIcon, RightArrowOutlined} from "@web/assets/icons";
import {notifyAndTrack} from "./notification";

MemoryOriginDate.propTypes = {

};

export default function MemoryOriginDate(props) {
  const MAX = 12;
  const [,user, claims] = React.useContext(AppContext);
  const [setOriginTs] = useFunctionsCallable("user-setOriginTs");
  const [displayDiff, setDisplayDiff] = React.useState("");
  const customFormat = (value) => {
    const diff = Math.abs(value.diff(dayjs(), "months"));
    return `${diff} Months`;
  }

  const onDateChange = (value, dateString) => {
    if (!value) return;
    setDisplayDiff(value.format("MMM DD YYYY"));
    setOriginTs({originTs: value.valueOf()})
      .then(() => notifyAndTrack.success("success", "Memory Origin Date Updated", value.valueOf()))
      .then(() => claims.refresh())
  }

  if (!user) return null;
  return <div className="space space-center-y">
    <DatePicker
      size="large"
      allowClear={false}
      disabledDate={(value) => value.diff(dayjs(), "months") < -MAX || value.diff(dayjs(), "months") > -3}
      onChange={onDateChange}
      format={customFormat}
      picker="month"
      defaultValue={claims.data.originTs ? dayjs(claims.data.originTs) : dayjs().subtract(6, "months").startOf("month")}
    />
    &nbsp;
    {!!displayDiff && <div className="space space-center-y"><CheckIcon className="gold-icon"/> <span
      className="ai-text">Starting {displayDiff}</span></div>}
    {/*<LineConnector style={{maxWidth: 240}} /><h4 className="muted"></h4>*/}
  </div>
}