import React from "react";
import PropTypes from "prop-types";
import TodoCard from "./TodoCard";
import GetDocumentFirestore from "@web/ui/containers/GetDocumentFirestore";
import MessageCard from "./MessageCard";
import FileCard from "./FileCard";
import ContactCard from "./ContactCard";
import MarkdownTimeline from "./MarkdownTimeline";
import Day from "./Day";
import DatasetData from "@web/ui/containers/DatasetData";
import DatasetCard from "./DatasetCard";

UserContentPreview.propTypes = {
  path: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  data: PropTypes.object,
  index: PropTypes.number,
  length: PropTypes.number,
  children: PropTypes.node.isRequired,
};

function UserContentPreview({path, href, children, ...props}) {
  const [userLiteral, contentOwnerUserId, serviceId, itemId, ...restOfPath] = path.split("/");

  switch(serviceId) {
    case "todos":
      return <div style={{display: "flex", width: "300px", maxWidth: "300px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="todo" path={path}>
          <TodoCard path={path} />
        </GetDocumentFirestore>
      </div>
    // case "threads": {
    //   return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
    //     <EmailDetailData
    //       path={path} component={EmailsCard}
    //       childProps={{pageSize: 1, card: true}}
    //     />
    //   </div>
    // }
    // case "threads":
    case "messages":
    case "emails":
      const messagePath = path.replace("/emails/", "/messages/").replace("/threads/", "/messages/");
      return <div style={{display: "flex", width: '100%', maxWidth: "600px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="message" path={path}>
          <MessageCard path={messagePath} />
        </GetDocumentFirestore>
      </div>
    case "files":
      return <div style={{display: "flex", width: "100%", maxWidth: "600px"}} className="card-content-wrapper">
        <GetDocumentFirestore dataField="file" path={path}>
          <FileCard size="xl" bordered={false} />
        </GetDocumentFirestore>
      </div>
    case "contacts":
      return <div style={{display: "flex", width: "300px", maxWidth: "300px"}}>
        <GetDocumentFirestore dataField="contact" path={path}>
          <ContactCard />
        </GetDocumentFirestore>
      </div>
    case "activity": {
      return <div className="fat-chip" style={{display: "flex", width: '100%', maxWidth: "600px"}}>
        <MarkdownTimeline
          markdown={props.data.activitySummary}
          action={[
            <span className="muted uppercase" style={{fontSize: '12px', paddingTop: '3px'}}>
              <Day ts={props.data.date} format={"MMM DD YYYY"}/>
            </span>
          ]}
        />
      </div>
    }
    case "datasets":
      return <div style={{display: "flex", width: "100%", maxWidth: "1200px"}} className="card-content-wrapper">
        <DatasetData path={path} component={DatasetCard} />
      </div>
    default:
      return <a href={href}>{children || href}</a>
  }
}

export default UserContentPreview;
