import {all, takeEvery, call, put} from "redux-saga/effects";
import {DocumentSnapshot, Query} from "firebase/firestore";
import taskActions from "./actions";
// import {getIdTokenResult} from 'firebase/auth'
import {buildQuery, getDocument, getDocuments, getPath} from "@web/lib/firestore.db";
import {toFirestoreQueryItems, firestore as channel} from "../channels";
import {auth} from "@web/lib/firebase";

// function* getTrelloOrgIds() {
//   if (!auth.currentUser) return [];
//   const {claims} = yield call(getIdTokenResult, auth.currentUser);
//   let {trelloOrgIds} = claims;
//   if (!trelloOrgIds) {
//     // console.log('refreshing claims to get trelloOrgIds');
//     // const refreshTokenResult = yield call(getIdTokenResult, auth.currentUser, true);
//     // trelloOrgIds = refreshTokenResult.claims.trelloOrgIds;
//   }
//   if (!trelloOrgIds?.length) return [];
//   return [...trelloOrgIds] || []; //, ["600624ff9d4d360a0c8d36ec"]
// }
// const defaultTodosFields = {'==': {status: 'new'}}

/**
 * Get todos query
 * @param {string} status
 * @param {{limit: number}} options
 * @returns {Query}
 */
function getTodosQuery(status, options = {}) {
  return buildQuery(["user", auth.currentUser.uid, "todos"], {'==': {status}}, {limit: options.limit || 24, order: [['createdTs', 'desc']]});
}

/**
 * Get todos sync query
 * @param {{limit: number}} params
 * @returns {Query}
 */
function getTodosSyncQuery({limit = 1} = {}) {
  return buildQuery(["user", auth.currentUser.uid, "todos"], {'==': {status: 'new'}}, {limit, order: [['createdTs', 'desc']]});
}
function* syncTasks(actions = []) {
  if (!auth.currentUser) return;
  const listenTo = [...actions];
  yield call(channel, listenTo);
}

function* loadTodos({status}) {
  const todos = yield call(getDocuments, getTodosQuery(status));
  yield put({
    type: taskActions.LOAD_TODOS_SUCCESS,
    payload: {
      status,
      items: toFirestoreQueryItems(todos),
    },
  });
}

/**
 * Load organization tasks
 * @function loadOrganizationTasks
 * @param {string} organizationId
 * @param {object} [fields]
 * @returns {Generator<*, void, *>}
 */
function* loadOrganizationTasks(organizationId, fields) {
  const [actions, cards, lists, boards] = yield all([
    call(getDocuments, buildQuery(["trello", organizationId, "actions"], fields || {}, {
      limit: 20,
      order: [['dateTs', 'desc']],
    })),
    call(getDocuments, buildQuery(["trello", organizationId, "cards"], fields || {}, {
      limit: 20,
      order: [['lastActivityTs', 'desc']],
    })),
    call(getDocuments, buildQuery(["trello", organizationId, "lists"], fields || {}, {
      limit: 20,
    })),
    call(getDocuments, buildQuery(["trello", organizationId, "boards"], fields || {}, {
      limit: 20,
      order: [['dateLastActivity', 'desc']],
    })),
  ]);

  yield put({
    type: taskActions.LOAD_TASKS_SUCCESS,
    payload: {
      cards: toFirestoreQueryItems(cards),
      actions: toFirestoreQueryItems(actions),
      lists: toFirestoreQueryItems(lists),
      boards: toFirestoreQueryItems(boards),
    },
  });
}

/**
 * Load tasks
 * @function loadTasks
 * @param {object} params
 * @param {object} [fields=null]
 * @return {Generator<*, void, *>}
 */
function* loadTasks({fields = {}}) {
  /** @type {DocumentSnapshot} */
  const trelloProviderSnapshot = yield call(getPath, ["user", auth.currentUser.uid, "providers", "trello-com"]);
  if (trelloProviderSnapshot instanceof Error) {
    return yield put({
      type: taskActions.LOAD_TASKS_ERROR,
      error: trelloProviderSnapshot,
    });
  }

  if (!trelloProviderSnapshot.exists()) return;

  const idOrganizations = trelloProviderSnapshot.data().idOrganizations || [];
  if (!idOrganizations.length) return;
  console.log('loadTasks saga', {idOrganizations});

  yield all(idOrganizations.map(idOrganization =>
    call(loadOrganizationTasks, idOrganization, fields)));

  // const syncActions = [];
  // syncActions.push({ref: getTodosSyncQuery({limit: 1}), success: taskActions.SYNC_TASKS_SUCCESS, key: "task-todos", collection: "todos"});
  // if (organizationId) {
  //   syncActions.push({ref: buildQuery(["trello", organizationId, "actions"], {}, {limit: 1, order: [['dateTs', 'desc']]}), success: taskActions.SYNC_TASKS_SUCCESS, key: "task-actions", collection: "actions"});
  //   syncActions.push({ref: buildQuery(["trello", organizationId, "cards"], {}, {limit: 1, order: [['lastActivityTs', 'desc']]}), success: taskActions.SYNC_TASKS_SUCCESS, key: "task-cards", collection: "cards"});
  //   syncActions.push({ref: buildQuery(["trello", organizationId, "lists"], {}, {limit: 1}), success: taskActions.SYNC_TASKS_SUCCESS, key: "task-lists", collection: "lists"});
  //   syncActions.push({ref: buildQuery(["trello", organizationId, "boards"], {}, {limit: 1, order: [['dateLastActivity', 'desc']]}), success: taskActions.SYNC_TASKS_SUCCESS, key: "task-boards", collection: "boards"});
  // }
  // yield call(syncTasks, syncActions);
}
export default function* rootSaga() {
  yield all([
    takeEvery(taskActions.LOAD_TASKS, loadTasks),
    takeEvery(taskActions.LOAD_TODOS, loadTodos),
  ]);
}
