import actions from "./actions";
import uniq from "lodash/uniq.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  list: [],
  contacts: [],
};

/**
 * @summary Reducer for activity
 * @param {object} state
 * @param {string} type
 * @param {object} payload
 * @return {*}
 */
export default function reducer(state = initState, {type, payload}) {
  switch (type) {
    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case actions.LOAD_CONTACTS_SUCCESS:
        return {
          ...state,
          list: uniq([...(state.list || []), ...(payload.list || [])]),
          data: {...state.data, ...payload.data},
          contacts: uniq([...(state.contacts || []), ...(payload.list || [])]),
        }
    default:
      return state;
  }
}
