import React from "react";
import PropTypes from "prop-types";
import {Alert, Typography} from "antd";
import AuthLayoutWrapper from "@ai-antd/components/AuthLayoutWrapper";
import useQuerystring from "@web/hooks/useQuerystring";
import {AppContext} from "@web/hooks/context";
import {setDocumentSub} from "@web/lib/firestore.db";
import OauthCompletionStatus from "@ai-antd/components/OauthCompletionStatus";
import {notifyAndTrack} from "@ai-antd/components/notification";
// import TrelloAuthCallback from "@ai-antd/components/TrelloAuthCallback";

const fullWidthStyle = {width: '100%'}
OauthCallback.propTypes = {
  integrationId: PropTypes.string.isRequired,
};

function OauthCallback(props) {
  const [, user] = React.useContext(AppContext);// [app, user, claims
  const params = useQuerystring();
  const [loading, setLoading] = React.useState(false);

  // if (props.integrationId === 'trello-com') return <TrelloAuthCallback integrationId={props.integrationId} />

  const {code, state} = params;
  if (!user) return null;
  if (!code) return notifyAndTrack.warn('Connect failed', 'Unable to complete your request at this time', {params, code});
  if (!state || state !== user.uid) return <Alert type="warning">An error occurred</Alert>
  const [integrationId, ...requesterUserIdArray] = state.split(':');
  const requesterUserId = requesterUserIdArray.join(':');
  if (requesterUserId !== user.uid) return <Alert type="warning">An error occurred</Alert>

  React.useEffect(() => {
    console.log('OauthCallback', params);
    setDocumentSub(["user", user.uid, 'providers'], integrationId, {code})
      .then(() => setLoading(true));
  }, [code, state]);

  return <AuthLayoutWrapper>
    <Typography.Title level={5} style={{margin: '20px 40px 0'}}>Connecting...</Typography.Title>
    <div className="space-col center middle" style={fullWidthStyle}>
      {loading && <OauthCompletionStatus integrationId={integrationId} userId={user.uid} />}
    </div>
  </AuthLayoutWrapper>
}

export default OauthCallback;