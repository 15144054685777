import {all, takeEvery, call, put, delay} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {colls, buildQuery, getDocuments} from "@web/lib/firestore.db";
import {toFirestoreQueryItems, firestore as channel} from "../channels";

const pageLimit = 102;
let attachmentsIndexPage = 0;
const source = "gmail";

/**
 * @summary Build and return a query
 * @param {Number} [limit]
 * @param {Number|String|FirebaseFirestore.DocumentSnapshot} [startAfter]
 * @param {string} [memberUserId]
 * @return {Query<DocumentData>}
 */
const getThreadsQuery = ({limit = pageLimit, startAfter}, memberUserId) => {
  return buildQuery(
    ["user", auth.currentUser.uid, "threads"],
    memberUserId ?
      {"array-contains": {memberUserIds: memberUserId}} :
      {},
    {order: [['lastTs', 'desc']], limit},
    {startAfter},
  );
};

/**
 * @summary sync email
 * @generator
 * @return {Generator<*, void, *>}
 */
function* syncMail(params) {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getThreadsQuery({...params, limit: 1}),
    success: actions.SYNC_MAIL_SUCCESS,
  }];
  yield call(channel, listenTo);
}

/**
 * Load and sync email
 * @param {object} action
 * @param {object} action.params
 * @param {string} action.memberUserId
 * @return {Generator<*, void, *>}
 */
function* loadMail({params = {}, memberUserId}) {
  if (!auth.currentUser?.uid) return;
  if (params.startAfter === null) {
    console.log("loadMail", "no more threads");
    return;
  }
  const query = getThreadsQuery(params, memberUserId);
  const querySnapshot = yield call(getDocuments, query);
  const threads = toFirestoreQueryItems(querySnapshot);
  yield put({
    type: actions.LOAD_MAIL_SUCCESS,
    payload: {threads},
    pageParams: {
      ...params,
      startAfter: querySnapshot.docs?.[pageLimit - 1]?.get("lastTs") || null,
    },
  });
  if (!memberUserId && !params.startAfter) {
    delay(5000);
    yield call(syncMail, params);
  }
}

function* pageNext({params, type}) {
  attachmentsIndexPage += 1;
  yield call(loadMail, {params, type});
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_MAIL, loadMail),
    takeEvery(actions.PAGE_MAIL, pageNext),
  ]);
}
