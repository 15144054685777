import React from "react";
import PropTypes from "prop-types";
import ContactDetails from "./ContactDetails";
import ContactDetailData from "@web/ui/containers/ContactDetailData";

ContactDetailsWrapper.propTypes = {
 contactId: PropTypes.string,
};

function ContactDetailsWrapper(props) {
  return <ContactDetailData {...props}>
    <ContactDetails />
  </ContactDetailData>
}

export default ContactDetailsWrapper;
