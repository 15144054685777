import React from "react";
import {Button} from "antd";
import {CheckIcon} from "@web/assets/icons";

function AuthSuccess() {
  const onClose = () => {
    window.close();
  };
  return <section className="space space-center-x space-col">
    <Button ghost onClick={onClose}>
      <div className="space space-center-y">
        <CheckIcon className="green-icon"/> <span className="green-icon">Click here to continue</span>
      </div>
    </Button>
  </section>;
}

export default AuthSuccess;