import React, {useContext} from "react";
import {Card, Empty, List} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import FileListItem from "./FileListItem";
import GroupTitle from "./GroupTitle";
import CleanModeButton from "./CleanModeButton";
import {DownArrowOutlined} from "@web/assets/icons";

FileList.propTypes = {
  list: PropTypes.array,
  grid: PropTypes.object,
  pageSize: PropTypes.number,
  pageNext: PropTypes.func,
};

function FileList(props) {
  const [, user] = useContext(AppContext);
  const [,, height] = useContext(WindowContext);

  if (!user?.uid) {
    return null;
  }

  return <div style={{position: 'relative'}}>
    <Card
      className="card-rounded card-simple card-transparent"
      bordered={false}
      style={{margin: '0 10px 40px 10px', minHeight: `${height * 0.65}px`}}
    >
      <List
        header={<div className="space space-center-y">
          <h6>Files</h6>
          <DownArrowOutlined/>
        </div>}
        className="list-empty-no-padding pagination-left list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <FileListItem
            key={path}
            path={path}
            date={date}
          />
        }
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
          </Empty>
        }}
        grid
        pagination={
        props.list?.length &&
          {pageSize: 30, showSizeChanger: false, hideOnSinglePage: true}}
      />
        {/*</Col>*/}
        {/*<Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={6} push={1}>*/}
        {/*  <Card*/}
        {/*    className="card-rounded card-simple card-transparent"*/}
        {/*    bordered={false}*/}
        {/*    title={<Typography.Title level={4}>Filters</Typography.Title>}*/}
        {/*  >*/}
        {/*    <List*/}
        {/*      size="small"*/}
        {/*      className="list-empty-no-padding list-item-no-divider"*/}
        {/*      dataSource={['PDF', 'Images', 'Invoices', 'Receipts', 'Contracts', 'Agreements', 'Forms', 'Spreadsheets', 'Presentations', 'Documents']}*/}
        {/*      renderItem={(facet) => <List.Item>*/}
        {/*        <span className="font-lg muted">{facet}</span>*/}
        {/*      </List.Item>}*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
      {/*</Row>*/}
    </Card>
  </div>;
}

export default FileList;
