import uniq from "lodash/uniq.js";

/**
 * @summary Determine if app search is active
 * @function isAppSearch
 * @param {object} [searchAppParams]
 * @param {string} [searchAppParams.query]
 * @param {object} [searchAppParams.filters]
 * @param {string[]} [searchAppParams.tagFilters]
 * @returns {boolean}
 */
export const isAppSearch = (searchAppParams) => {
  if (!searchAppParams) return false;
  return !!(searchAppParams.query || searchAppParams.filters?.length || searchAppParams.tagFilters?.length);
}

const actions = {
  QUICK_SEARCH: "QUICK_SEARCH",
  QUICK_SEARCH_SUCCESS: "QUICK_SEARCH_SUCCESS",
  SEARCH_APP: "SEARCH_APP",
  SEARCH_APP_SUCCESS: "SEARCH_APP_SUCCESS",
  SEARCH_APP_NEXT_PAGE: "SEARCH_APP_NEXT_PAGE",
  SEARCH_APP_NEXT_PAGE_SUCCESS: "SEARCH_APP_NEXT_PAGE_SUCCESS",
  SEARCH_SIMILARITY: "SEARCH_SIMILARITY",
  SEARCH_SIMILARITY_SUCCESS: "SEARCH_SIMILARITY_SUCCESS",
  SEARCH_UPDATE_APP_FILTERS: "SEARCH_UPDATE_APP_FILTERS",
  SEARCH_CLEAR_APP_FILTERS: "SEARCH_CLEAR_APP_FILTERS",

  /**
   * @summary Search app
   * @param {string} [newQuery] - undefined will use appFilters query if present
   * @returns {(function(*, *): void)|*}
   */
  searchApp: (newQuery) => {
    return (dispatch, getState) => {
      console.log('searchApp', newQuery)
      if (newQuery !== undefined) {
        dispatch({
          type: actions.SEARCH_UPDATE_APP_FILTERS,
          payload: {query: newQuery},
        });
      }

      const appSearchParams = getState().Search.app || {};
      const hasParameters = isAppSearch(appSearchParams);
      !!hasParameters && dispatch({
        type: actions.SEARCH_APP,
        ...appSearchParams,
      });
      !hasParameters && dispatch({
        type: actions.SEARCH_CLEAR_APP_FILTERS,
      });
    };
  },
  toggleMemberFilter: (contactId) => {
    return (dispatch, getState) => {
      const {tagFilters = []} = getState().Search.app || {};
      const memberTag = `member:${contactId}`;
      const addMember = !tagFilters.includes(memberTag);
      const tagFiltersWithoutMembers = tagFilters.filter((tag) => !tag.startsWith('member:'));

      dispatch({
        type: actions.SEARCH_UPDATE_APP_FILTERS,
        payload: {
          tagFilters: addMember ?
            uniq([...tagFiltersWithoutMembers, memberTag]) :
            tagFiltersWithoutMembers,
        },
      });
      dispatch(actions.searchApp());
      // addMember && dispatch(contactActions.checkStatus, {contactId});
    };
  },
  appSearchNextPage: () => {
    console.log('appSearchNextPage')
    return (dispatch, getState) => {
      dispatch({
        type: actions.SEARCH_APP_NEXT_PAGE,
      });
    };
  },
};

export default actions;
