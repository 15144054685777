import React from 'react';
import PropTypes from 'prop-types';
import {Card, List, Typography} from "antd";
import {LayersIcon, PlusIcon} from "@web/assets/icons";
// import MarkdownTimeline from "./MarkdownTimeline";
// import SlideCardWrapper from "./SlideCardWrapper";
// import dayjs from "dayjs";
import FileCard from "./FileCard";
import {useSelector} from "react-redux";
// import {TimelineContext} from "@web/hooks/context";

TimelineFilesCard.propTypes = {
  files: PropTypes.array,
  // content: PropTypes.object,
};

function TimelineFilesCard(props) {
  // const {itemPath, date} = props.files.sort((a, b) => a.date - b.date)[0];
  // const itemPath = props.files[0].itemPath;
  // const context = React.useContext(TimelineContext);
  // console.log('TimelineFilesCard', props.files);
  /** @type {[string, number, models.File.Data]} */
  const files =
    useSelector((state) => {
      return !!props.files?.length &&
        props.files
        .map(({date, path}) =>
          [path, date, state.Content.data?.[path]]);
    });
    console.log('TimelineFilesCard', {props, files});
    return <List
      bordered={false}
      title="Files"
      dataSource={files || []}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      renderItem={
        ([path, date, file]) =>
          <List.Item
            key={path}
          >
            <FileCard
              file={file}
              path={path}
              grid
              // size="xl"
              // className="no-padding"
              bordered={false}
            />
          </List.Item>}
      locale={{
        emptyText: <div className="space space-center-y">
          <PlusIcon />
          &nbsp;
          <Typography.Text>
            No files
          </Typography.Text>
        </div>
      }}
    />
  //});
}

export default TimelineFilesCard;
