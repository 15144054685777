import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card, Typography} from "antd";
import {AttachmentIcon, EmailIconOutlined, LikeIcon} from "@web/assets/icons";
import appActions from "@web/redux/app/actions";
import Markdown from "./Markdown";
import {WindowContext} from "@web/hooks/context";
// import StatusActions from "./StatusActions";
// import TimeAgo from "@web/ui/components/TimeAgo";
// import ReactionButton from "./ReactionButton";
import ListItemAvatar from "./ListItemAvatar";
// import ContactData from "@web/ui/containers/ContactData";
import ContactDataByEmail from "@web/ui/containers/ContactDataByEmail";

MessageCard.propTypes = {
  message: PropTypes.object,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function MessageCard(props) {
  const {path} = props;
  if (!path) return null;
  const messageId = path?.split("/")?.pop();
  const dispatch = useDispatch();
  const reduxMessage = useSelector(state => state.Email.data?.[messageId]);
  const [isMobile] = React.useContext(WindowContext);
  const setActiveDetail = useCallback((serviceId, props, show) => {
    dispatch(appActions.setActiveDetail(serviceId, props, show));
  }, [dispatch]);

  const showDetail = (e) => {
    if (props.onClick) {
      props.onClick(e, {path, message});
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setActiveDetail("threads", {path, message}, true);
    return false;
  }
  const message = props.message || reduxMessage;
  if (!message) return null; // <Alert message="Details not found" onClick={() => notification.info({description: path})} type="warning" icon={<EmailIconOutlined />} showIcon />;
  return <Card
    title={<div className="space space-center-y" style={{maxWidth: '85%'}}><Button ghost><EmailIconOutlined /></Button><span className="line-clamp-1 muted">{message.subject}</span></div>}
    className={`card-rounded card-simple card-slim ant-card-body card-slim-title extra-round card-no-titles `}
    bordered={false}
    hoverable
    onClick={showDetail}
  >
    <div style={{position: 'relative'}} className="space space-col space-between">
      <div style={{
        maxHeight: '400px',
        overflow: isMobile ? "hidden" : "auto", paddingBottom: '10px'}}>
        <div style={{width: "100%"}} className="space space-col">
          <ContactDataByEmail email={message.from?.email}>
            <ListItemAvatar />
          </ContactDataByEmail>
          {/*{message.memberUserIds?.map((memberUserId) => {*/}
          {/*  return <ContactData userId={memberUserId}>*/}
          {/*    <ListItemAvatar />*/}
          {/*  </ContactData>*/}
          {/*})}*/}
          {/*<label className="font-xl">From {message.from.email}</label>*/}
          {/*<div className="space space-center-y">*/}
          {/*  <h6 className="line-clamp-1">{from.email}</h6>*/}
          {/*  <span style={{display: "inline-block", marginLeft: "0"}}>*/}
          {/*    <TimeAgo date={message.date}/>*/}
          {/*  </span>*/}
          {/*</div>*/}
          {/*<span className="muted line-clamp-1">{subject?.replace("Re:", "")?.replace("Fwd:", "")?.trim()}</span>*/}
          <Typography.Text className="font-xl"><span dangerouslySetInnerHTML={{__html: message.snippet || message.text || ""}}></span></Typography.Text>
          {message.attachments && <div className="space space-center-y">
            <AttachmentIcon className="muted-icon" />
            <Typography.Text className="font-xl muted">{Object.keys(message.attachments).length}</Typography.Text>
          </div>}
        </div>
      </div>
      {/*<div style={{position: "absolute", bottom: "-20px", left: "0", right: "0", paddingTop: "6px"}}>*/}
      {/*  <div className="space space-center-y space-between">*/}
      {/*    <div/>*/}
      {/*    <StatusActions*/}
      {/*      // actions={[*/}
      {/*      //   // 'remind',*/}
      {/*      //   'reply',*/}
      {/*      // ]}*/}
      {/*      initialStatus={message?.status}*/}
      {/*      path={path}*/}
      {/*      iconClassName="ai-text"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  </Card>
}

export default MessageCard;
