import React from 'react';
import PropTypes from 'prop-types';
import {Button, List, Typography, Divider} from "antd";
import TimelineEmail from "./TimelineEmail";
import {
  DownArrowOutlined,
  ForwardIcon,
  PlusIcon,
  ReplyIcon, UpArrowIcon,
} from "@web/assets/icons";
import {useSelector} from "react-redux";
import TimelineItemEventsList from "./TimelineItemEventsList";
import IconTip from "./IconTip";
import EmailAction from "./EmailAction";

TimelineEmailWrapper.propTypes = {
  items: PropTypes.array,
  cardTitle: PropTypes.string,
  contextPath: PropTypes.string,
  path: PropTypes.string,
  date: PropTypes.number,
  dateKey: PropTypes.string,
  enableActions: PropTypes.bool,
};

function TimelineEmailWrapper(props) {
  // const contextItem =
  //   useSelector((state) => {
  //     return state.Content.data?.[props.contextPath];
  //   });

  // console.log('TimelineEmailWrapper:contextPath:', props.contextPath);
  // console.log('TimelineEmailWrapper:items:', props.items);
  const emailPath = props.items[props.items.length - 1]?.path;
  // const email =
  //   useSelector((state) =>
  //     state.Content.data?.[emailPath]);

  const [showDetails, setShowDetails] =
    React.useState(false);

  const onClickDetails = () => {
    setShowDetails(!showDetails);
  }

  // const messagesPathList = [];
  // const messagesPathList =
  //   useSelector((state) => {
  //     return state.Content?.activity?.[props.contextPath]?.timeline?.[props.dateKey]?.messages ||
  //       null;
  //   });
  // const [, user] =
  //   React.useContext(AppContext);
  // const [
  //   showEditor,
  //   setShowEditor,
  // ] = React.useState(false);
  // const [
  //   editorData,
  //   setEditorData,
  // ] = React.useState({});
  // const getReplyToEmails =
  //   (email) => {
  //     const toEmails =
  //       email.from.email === user.email ?
  //         email.to :
  //         [email.from];
  //
  //     const ccEmails = []; // email.to.map(({name, email}) => ({name, email}));
  //     return {toEmails, ccEmails};
  //   }
  // const onForward = () => {
  //   // const [forwardEmailPath, forwardEmail] =
  //   //   props.contextPath
  //   //   .split("/")[2] === "messages" ?
  //   //     [props.contextPath, contextItem] :
  //   //     [emailPath, email];
  //   dispatch(
  //     actions
  //     .setActiveEmailDraft(
  //       "forward",
  //       emailPath,
  //     ));
  //   // setEditorData({
  //   //   action: "forward",
  //   //   actionLabel: "Forward",
  //   //   threadPath: forwardEmail.threadPath,
  //   //   messagePath: forwardEmailPath,
  //   //   toEmails: [],
  //   //   ccEmails: [],
  //   //   message: "",
  //   //   attachments: [],
  //   //   important: false,
  //   //   subject: forwardEmail.subject,
  //   //   contextPaths: [forwardEmailPath],
  //   // });
  //   // setShowEditor(true);
  // }
  // const getReplyToEmails = (email) => {
  //   const toEmails = email.from.email === user.email ? email.to : [email.from];
  //   const ccEmails = []; // email.to.map(({name, email}) => ({name, email}));
  //   return {toEmails, ccEmails};
  // }
  // const onReply = () => {
  //   setEditorData({
  //     action: "reply",
  //     actionLabel: "Reply",
  //     threadPath: email.threadPath,
  //     messagePath: itemPath,
  //     ...getReplyToEmails(email),
  //     replyingToEmailMessage: email,
  //     contextPaths: [itemPath],
  //     subject: email?.subject,
  //   });
  //   setShowEditor(true);
  // }
  //
  // const onForward = () => {
  //   setEditorData({
  //     action: "forward",
  //     actionLabel: "Forward",
  //     threadId: itemPath,
  //     messageId: itemPath,
  //     toEmails: [],
  //     ccEmails: [],
  //     message: "",
  //     attachments: [],
  //     important: false,
  //     subject: email?.subject,
  //     contextPaths: [itemPath],
  //   });
  //   setShowEditor(true);
  // }
  // if (!email) return null;
  // console.log('TimelineEmailWrapper', {itemPath, date, email})
    // const maxLength = props.messages.map(({itemPath}) => context.content[itemPath]?.data?.text?.length).reduce((a, b) => Math.max(a, b), 0);
    // const size = maxLength > 500 ? 'xl' : 'xl';
  // console.log('TimelineEmailWrapper', props);
  // if (!contextEmail) {
  //   return null;
  // }

  return <List
    className={"timeline-messages-list"}
    bordered={false}
    dataSource={props.items || []}
    renderItem={
    ({path, date}) =>
      <List.Item key={path}>
        <div className="space space-col">
          <TimelineItemEventsList
            key="messages-timeline"
            contextPath={props.contextPath}
            path={path}
            date={date}
            serviceId="messages"
            cardTitle="Activity"
            showDetails={showDetails}
          >
          <TimelineEmail
            path={path}
            date={date}
            hideSubject
            showAttachmentCount={false}
          />
          </TimelineItemEventsList>
        </div>
      </List.Item>}
    footer={
      // <Comment
      //   author={null}
      //   avatar={
      //   <Avatar
      //     size={"large"}
      //     style={{visibility: 'hidden'}}
      //   />
      //   }
      //   actions={[
      //     !showEditor &&
      //     <Button
      //       shape="round"
      //       className="icon-btn"
      //       onClick={onReply}
      //     >
      //       <ReplyIcon
      //         size={24}
      //         className="green-icon"
      //       />
      //       <h6
      //         className="green-color"
      //       >
      //         Reply
      //       </h6>
      //     </Button>,
      //     !showEditor &&
      //     <Button
      //       shape="round"
      //       className="icon-btn"
      //       onClick={onForward}
      //     >
      //       <ForwardIcon
      //         size={24}
      //         className="green-icon"
      //       /> <h6 className="green-color">Forward</h6>
      //     </Button>
      //   ]}
      //   content={
      //   showEditor ?
      //     <DraftEditor
      //       {...editorData || null}
      //       onCancel={() =>
      //         setShowEditor(false)}
      //     /> :
      //     null
      // }>
      // </Comment>,
      props.enableActions &&
      <div
        className="space space-center-y"
        style={{marginLeft: '60px'}}
      >
        <IconTip
          key="details"
          title="Details"
          placement="topCenter"
        >
          <Button
            shape="round"
            className="icon-btn"
            onClick={onClickDetails}
          >
            {!showDetails ?
              <DownArrowOutlined
                key="details"
                className="green-icon"
              /> :
              <UpArrowIcon
                key="details"
                className="green-icon"
              />
            }
            <h6
              className="green-color"
            >
              Details
            </h6>
          </Button>
        </IconTip>
        <Divider
          type="vertical"
          className="hidden"
          style={{height: 30, position: 'relative', top: '-8px'}}
        />
        <EmailAction
          action="reply"
          emailPath={emailPath}
        >
          <Button
            shape="round"
            className="icon-btn"
          >
            <ReplyIcon size={24} className="green-icon"/>&nbsp;
            <h6 className="green-color">Reply</h6>
          </Button>
        </EmailAction>
        <EmailAction
          action="forward"
          emailPath={emailPath}
        >
          <Button
            shape="round"
            className="icon-btn"
          >
            <ForwardIcon size={24} className="green-icon"/>&nbsp;
            <h6 className="green-color">Forward</h6>
          </Button>
        </EmailAction>
      </div>
      // <Comment
      //   // className={showEditor ? "" : "comment-no-padding"}
      //   author={null}
      //   avatar={
      //     <Avatar
      //       size={"large"}
      //       style={{visibility: 'hidden'}}
      //     />
      //   }
      //   // actions={}
      //   // content={
      //   //   showEditor ?
      //   //     <DraftEditor
      //   //       {...editorData || null}
      //   //       onCancel={() =>
      //   //         setShowEditor(false)}
      //   //     /> :
      //   //     null
      //   // }
      // >
      // </Comment>
    }
      locale={{
        emptyText: <div className="space space-center-y">
          <PlusIcon />
          &nbsp;
          <Typography.Text>
            No more messages
          </Typography.Text>
        </div>
      }}
    />
      // {/*<div className="space space-col" style={{height: '80%'}}>*/}
      // {/*  <label className="font-xl muted">{email.subject}</label>*/}
      // {/*  <div className="space space-center-y">*/}
      // {/*    <label className="font-xl muted underline">{email.from.name?.split(" ")?.[0]}</label>*/}
      // {/*    <ArrowRightOutlined className="green-icon"/>*/}
      // {/*    <label className="font-xl muted underline">{email.to.map(({name, email}) => (email === user.email) ? `${user.displayName} (You)` : name?.split(" ")?.[0]).join(", ")}</label>*/}
      // {/*  </div>*/}
      // {/*  /!*<EmailsCard email={email} messages={props.messages.map(({itemPath}) => ["", props.content[itemPath].data, itemPath])} card />*!/*/}
      // {/*  <Typography.Paragraph*/}
      // {/*    ellipsis={{rows: 14, expandable: true}}*/}
      // {/*    className="font-xl render-line-breaks">*/}
      // {/*    {email.text || email.text}*/}
      // {/*  </Typography.Paragraph>*/}
      // {/*  {!showEditor && <Space>*/}
      // {/*    <Button shape="round" className="icon-btn green-color" onClick={onReply}>*/}
      // {/*      <ReplyIcon size={24} className="green-icon"/>*/}
      // {/*      <span>Reply</span>*/}
      // {/*    </Button>*/}
      // {/*    <Button shape="round" className="icon-btn green-color" onClick={onForward}>*/}
      // {/*      <ForwardIcon className="green-icon"/> <span>Forward</span></Button>*/}
      // {/*    {!!Object.keys(email.attachments || {})?.length &&*/}
      // {/*      <Button shape="round" className="icon-btn green-color">*/}
      // {/*        <AttachmentIcon className="muted-icon"/>*/}
      // {/*        <label className="font-xl muted">{Object.keys(email.attachments).length}</label>*/}
      // {/*      </Button>*/}
      // {/*    }*/}
      // {/*  </Space>}*/}
      // {/*  {showEditor && <DraftEditor {...editorData || null} onCancel={() => setShowEditor(false)} />}*/}
      // {/*</div>*/}
}

export default TimelineEmailWrapper;
