import React from "react";
import {WindowContext} from "@web/hooks/context";
import {Divider, Statistic, Typography} from "antd";
import LogoutButton from "@ai-antd/components/LogoutButton";

const launchDate = new Date('2023-09-12T18:00:00.000Z').getTime();

BetaCountdown.propTypes = {
  
};

function BetaCountdown() {
  const [isMobile, width, height] = React.useContext(WindowContext)
  return <section className="bg-baby-gray" style={{width, height: (height + 100), padding: isMobile ? '50px 0' : '50px', position: 'relative', boxSizing: 'border-box'}}>
    <div className="flex-col center middle" style={{width: '100%', position: 'absolute', top: '40px', bottom: '80px', margin: '0 auto', justifyContent: 'space-between', alignItems: 'center'}}>
      <h1 style={{fontSize: '30px'}}>TheScribe AI</h1>
      <div className="space space-col">
        <Statistic.Countdown
          style={{textAlign: 'center'}}
          title={<Typography.Title level={4} type="secondary">Beta begins 9/12</Typography.Title>}
          value={launchDate}
          format="DD:HH:mm:ss"
        />
        <Divider className="small-bottom" />
        <div className="space space-between">
          <div />
          <LogoutButton />
        </div>
      </div>
    </div>
    {/*<Background />*/}
  </section>
}

export default BetaCountdown;