import actions from "./actions";
import uniq from "lodash/uniq.js";
import uniqBy from "lodash/uniqBy.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  threads: {
    all: [],
    items: [],
  },
};
// /**
//  * @summary Merge state
//  * @param {object} groupState
//  * @param {object[]} list
//  * @return {*&{data: (*)}}
//  */
// const mergeGroup = (groupState = {}, list = []) => {
//   return {
//     all: uniq([...(groupState.all || []), ...list]),
//   };
// };
// const groupDocuments = (state, {data, list}) => {
//   return {
//     all: [...(state.all || []), ...list],
//     ...(reduce(data, ($acc, {messageId, threadId}, $documentId) => {
//       $acc[messageId] = $acc[messageId] || [];
//       $acc[messageId].push($documentId);
//       $acc[threadId] = $acc[threadId] || [];
//       $acc[threadId].push($documentId);
//       return $acc;
//     }, {}))
//   }
// }
// const mergeNetwork = (contactsState = {}, contactsData = {}, contactsList = [], threadsData = {}, summaryData = {}) => {
//   const idLookup = reduce(contactsData, ($acc, contact, contactId) => {
//     $acc[contact.email] = contactId;
//     return $acc;
//   }, {});
//   return {
//     idLookup,
//     all: uniq([...(contactsState.all || []), ...contactsList]),
//     ...(reduce(threadsData, ($acc, thread, threadId) => {
//       const {email: fromEmail} = threadsData[threadId]?.latest?.message?.from || {};
//       const contactId = idLookup[fromEmail];
//       const messageId = threadsData[threadId]?.newMessageIds?.[0];
//       if (summaryData[messageId]) {
//         $acc.summary[contactId] = $acc.summary[contactId] || [];
//         $acc.summary[contactId].push(messageId);
//       }
//       return $acc;
//     }, {summary: {}})),
//   };
// };

/**
 * @summary Reducer for feeds
 * @param {object} state
 * @param {string} type
 * @param {{threads: FirestoreQueryItems}} payload
 * @param {object} [pageParams]
 * @param {string} [source=gmail]
 * @return {*}
 */
export default function reducer(state = initState, {type, payload, pageParams, attachmentsParams, ...rest}) {
  switch (type) {
    case actions.SYNC_MAIL_SUCCESS:
      console.log("SYNC_MAIL_SUCCESS", {payload, pageParams});
      if ((state.threads?.list || []).includes(payload.list?.[0])) {
        return state;
      }
      const syncList = uniq([...state.threads.sync?.list || [], ...payload.list]);
      return {
        ...state,
        data: {
          ...state.data,
          threads: {
            ...state.data?.threads || null,
            ...(payload.data || null),
          },
        },
        threads: {
          ...state.threads,
          sync: {
            list: syncList,
            items: uniqBy([...state.threads.sync?.items || [], ...payload.items], 'key'),
            count: syncList.length,
          },
        },
        // pageParams,
      };
    case actions.LOAD_MAIL_SUCCESS:
      console.log("LOAD_MAIL_SUCCESS:pageParams:", pageParams);
      return {
        ...state,
        data: {
          ...state.data,
          threads: {
            // ...state.data.threads,
            ...(payload.threads?.data || null),
          },
        },
        threads: {
          all: uniq([...payload?.threads?.list]),
          items: uniqBy([...payload?.threads?.items], 'key'),
        },
        pageParams,
      };
    // case actions.LOAD_FEED_METADATA_SUCCESS:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       threads: {...state.data.threads},
    //       summary: {...state.data.summary, ...payload.summary.data},
    //       activity: {...state.data.activity, ...payload.activity.data},
    //       files: {...state.data.files, ...payload.files.data},
    //       domains: {...state.data.domains, ...payload.domains.data},
    //     },
    //     summary: mergeGroup(state.summary, payload.summary.list),
    //     domains: mergeGroup(state.domains, payload.domains.list),
    //     activity: mergeNetwork(state.activity, payload.activity.data, payload.activity.list, state.data.threads, payload.summary.data),
    //     files: groupDocuments(state.files, payload.files),
    //     facets: {
    //       ...(state.facets || {}),
    //       summary: payload.summary.facets,
    //       activity: payload.activity.facets,
    //       files: payload.files.facets,
    //       domains: payload.domains.facets,
    //     },
    //   };
    // case actions.LOAD_THREAD_ATTACHMENTS_SUCCESS:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       files: {
    //         ...state.data.files,
    //         ...payload.files.data,
    //       },
    //     },
    //     files: groupDocuments(state.files, payload.files),
    //     attachmentsParams,
    //   }
    default:
      return state;
  }
}
