import { all } from "redux-saga/effects";
import emailSagas from "./email/sagas";
import eventsSagas from "./events/sagas";
import searchSagas from "./search/sagas";
import tasksSagas from "./tasks/sagas";
import documentsSagas from "./documents/sagas";
import background from "./background/sagas";
import usersSagas from "./users/sagas";
import voiceSagas from "./voice/sagas";
import timelineSaga from "./timeline/sagas";
import calendarSagas from "./calendar/sagas";
import contentSaga from "./content/sagas";

export default function* rootSaga() {
  yield all([
    emailSagas(),
    calendarSagas(),
    eventsSagas(),
    searchSagas(),
    tasksSagas(),
    documentsSagas(),
    background(),
    usersSagas(),
    voiceSagas(),
    timelineSaga(),
    contentSaga(),
  ]);
}
