import React, {useContext, useEffect} from 'react';
import {AppContext} from '@web/hooks/context'
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import appActions from "@web/redux/app/actions";

DetailData.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  memberUserId: PropTypes.string,
  preview: PropTypes.object,
};

function DetailData({component, ...props}) {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);

  const daysList =
    useSelector((state) => {
    return state.Content?.activity?.[props.path]?.timeline ?
      Object.keys(state.Content?.activity?.[props.path]?.timeline || {}) :
      null;
  });

  const item =
    useSelector((state) => {
      return state.Content?.data?.[props.path];
    });

  const clearActiveDetail =
    React.useCallback(() => {
      dispatch(appActions
      .clearActiveDetail());
      }, []);

  useEffect(() => {
    const threadPath = props.message?.threadPath;
    dispatch(contentActions
    .loadContext(props.path, {threadPath}));
  }, [props.path]);

  if (!user?.uid) {
    return null;
  }

  if (!item && !daysList?.length) {
    return null;
  }

  return React
  .createElement(component, {
    path: props.path,
    item,
    daysList,
    // timeline: Object.keys(context.timeline),
    onClose: clearActiveDetail,
    // memberUserId: props.memberUserId,
    // content,
    // timeline: context.timeline,
    // contentKeys: context.contentKeys,
    // members: context.members,
    // itemPath: context.itemPath,
  });
}

export default DetailData;
