import React from 'react';
import PropTypes from 'prop-types';
import {List, Tag, Typography} from "antd";
import UserAvatar from "./UserAvatar";
import {FlagIcon} from "@web/assets/icons";
import now from "lodash/now";
import Day from "./Day";
import MarkdownTimeline from "./MarkdownTimeline";
import Toggle from "./Toggle";
import StatusActions from "./StatusActions";
import {useSelector} from "react-redux";

ContactDetailsListItemMeta.propTypes = {
  path: PropTypes.string.isRequired,
  activityPath: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactIcon: PropTypes.node,
};

function ContactDetailsListItemMeta(props) {
  const event =
    useSelector((state) =>
      state.Content.events[props.eventId]);

  return <List.Item.Meta
    avatar={
    <UserAvatar
      userId={props.path.split("/").pop()}
      path={props.path}
      displayName={props.displayName}
      email={props.email}
      icon={event.isUrgent ?
        <FlagIcon
          key="urgent"
          style={(event?.activityTs > (now() - (1000 * 60 * 60 * 48))) ? {color: "orange"} : null}
          size={18}
        /> :
        props.contactIcon
      }
      extra={event?.isUrgent &&
        <Tag color="orange">Urgent</Tag>}
    />
    }
    title={
      <div className="space space-center-y">
        <Typography.Title level={5}>
          {Object
          .values(event?.members || {})
          .filter(({displayName}) =>
            displayName !== props.name)
          .map(({displayName}, index) =>
            <span>{displayName} </span>)
          }
        </Typography.Title>
        <span className="font-sm uppercase muted">
          <Day ts={event?.date} format={"MMM DD"} />
        </span>
      </div>}
    description={
      <MarkdownTimeline
        markdown={event?.summary}
        action={[
          // <Button onClick={onClickItem} ghost className="icon-btn icon-left"><DownArrowOutlined /> Details</Button>,
          !!event?.recommendedTodo &&
          event?.recommendedTodo.todoStatus !== "completed" &&
          <Toggle
            key="recommended-todo"
            trigger={<span className="fat-chip font-sm uppercase">Todo</span>}
          >
            <div
              className="space space-col gap-small"
              style={{paddingLeft: '22px', borderLeft: 'solid 4px rgba(255,255,255,.1)'}}
            >
              {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
              <label className="muted font-lg">
                {event.recommendedTodo.goal}
              </label>
              <Typography.Text
                className="ai-text">
                {event.recommendedTodo.summary}
              </Typography.Text>
              <h6>
                <div className="space space-center-y space-between">
                  <StatusActions
                    path={props.activityPath}
                    initialStatus={event.recommendedTodo.status}
                    iconClassName="green-icon"
                    actions={['done', 'dismiss']}
                  />
                </div>
              </h6>
            </div>
          </Toggle> || null]}
      />
    }>
  </List.Item.Meta>
}

export default ContactDetailsListItemMeta;
