import React from "react";
import useSelectedContact from "@web/hooks/useSelectedContact";
// import {Tag} from "antd";

function GroupTitle({title, style}) {
  const [selectedContact] = useSelectedContact();
  return selectedContact ?
    <div className="space space-center-y" style={style}><h6>{title}</h6> <span className="muted">with {selectedContact.displayName || selectedContact.name || ""}</span><span className="muted">{selectedContact.domain || selectedContact?.email?.split("@")?.[1]}</span></div> :
    <h6 style={style}>{title}</h6>;
}

export default GroupTitle;
