import {notification} from 'antd'
import {ga} from '@web/lib/firebase'

const placement = 'top'
export const notifyAndTrack = {
  /**
   * @summary Show an error notification
   * @param {string} message
   * @param {string} description
   * @param {object} [options]
   */
  error: (message, description = "", options) => {
    const {log = null, ...componentProps} = options || {};
    notification.error({
      placement, message, description, ...componentProps,
    });
    ga.notification('error', {message, description, ...log})
  },
  // /**
  //  * @summary Show a warning notification
  //  * @param {object} params
  //  * @param {string} params.message
  //  * @param {string} [params.description]
  //  * @param {object} [params.log]
  //  * @param {object} [options = null]
  //  */
  // warning: (params, options) => {
  //   const {message, description = "", log = null} = params
  //   notification.warn({
  //     placement,
  //     message,
  //     description,
  //     ...options,
  //   })
  //   ga.notification('warning', params)
  // },
  /**
   * @summary Show an error notification
   * @param {string} message
   * @param {string} [description]
   * @param {object} [options]
   */
  warn: (message, description, options) => {
    const {log = null, ...componentProps} = options || {};
    notification.warn({message, description, placement, ...componentProps});
    ga.notification('warning', {...log})
  },
  /**
   * @summary Show an info notification
   * @param {string} message
   * @param {string} [description]
   * @param {object} [options]
   */
  info: (message, description, options) => {
    const {log = null, ...componentProps} = options || {};
    notification.info({
      placement, message, description, ...componentProps,
    })
    ga.notification('info', {message, description, ...log})
  },
  /**
   * @summary Show a success notification
   * @param {string} message
   * @param {string} [description = ""]
   * @param {object} [options]
   */
  success: (message, description = "", options) => {
    const {log = null, ...componentProps} = options || {};
    notification.success({placement, message, description, ...componentProps})
    ga.notification('success', {message, description, ...log})
  }
}
