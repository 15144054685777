const actions = {
  LOAD_MAIL: "LOAD_MAIL",
  LOAD_MAIL_SUCCESS: "LOAD_MAIL_SUCCESS",
  SYNC_MAIL_SUCCESS: "SYNC_MAIL_SUCCESS",
  PAGE_MAIL: "PAGE_MAIL",
  PAGE_MAIL_SUCCESS: "PAGE_MAIL_SUCCESS",
  SEARCH_MAIL: "SEARCH_MAIL",
  SEARCH_MAIL_SUCCESS: "SEARCH_MAIL_SUCCESS",
  loadMail: (memberUserId) => {
    return (dispatch) => {
      console.log("loadMail", {memberUserId});
      dispatch({
        type: actions.LOAD_MAIL,
        memberUserId,
        params: {},
      });
    };
  },
  pageNext: (memberUserId) => {
    return (dispatch, getState) => {
      const params = getState().Email?.pageParams;
      console.log("pageNext", {memberUserId, params});
      dispatch({
        type: actions.PAGE_MAIL,
        memberUserId,
        params,
      });
    };
  },
  searchMail: () => {
    return (dispatch, getState) => {
      console.log("searchMail");
      const params = getState().Search?.pageParams;
      dispatch({
        type: actions.SEARCH_MAIL,
        params: {},
      });
    };
  },
};

export default actions;
