import PropTypes from 'prop-types';
import dayjs from "dayjs";

Day.propTypes = {
  ts: PropTypes.number,
  format: PropTypes.string,
};

function Day(props) {
  const activityDay = dayjs(props.ts);
  return activityDay.year() < new Date().getFullYear() && props.format !== "YYYY" ?
    activityDay.format(props.format.replace("YYYY", "").trim()) :
    activityDay.format(props.format);
}

export default Day;
