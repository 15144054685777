const getKeyForFilter = (activityFilter) => {
  switch (activityFilter) {
    case "urgent":
      return "isUrgent";
    case "payments":
      return "isPaymentRelated";
    case "meetings":
      return "isMeetingRelated";
    case "deliveries":
      return "isDeliveryRelated";
    case "todos":
      return "requiresAction";
    case "complaints":
      return "isComplaintRelated";
    case "sales":
      return "isSaleRelated";
    case "support":
      return "isSupportRelated";
    case "products":
      return "isProductRelated";
    case "notifications":
      return "isNotification";
    // case "all":
    //   return "all";
  }
}

/**
 * @function getSelectedUserId
 * @param {app.State} state
 * @return {string|undefined}
 */
export const getSelectedUserId = (state) => state.Activity.selectedUserId;
/**
 * @function getSelectedMember
 * @param {app.State} state
 * @returns {{displayName: string, name: string, photoURL: string, date: number}|undefined}
 */
export const getSelectedMember = (state) => state.Activity.activity?.members?.[state.Activity.selectedUserId];
/**
 * @function getActivityMembersList
 * @param {app.State} state
 * @return {string[]|undefined}
 */
export const getActivityMembersList = (state) => state.Activity.activity?.membersList;

/**
 * @function getSelectedMemberItemPaths
 * @param {app.State} state
 * @return {string[]|undefined}
 */
export const getSelectedMemberItemPaths = (state) => {
  return getMemberItemPaths(state, state.Activity.selectedUserId);
}

/**
 * @function getSelectedItemPaths
 * @param {app.State} state
 * @return {string[]|undefined}
 */
export const getSelectedItemPaths = (state) => {
  console.log("state.UserTimeline", state.Activity);
  switch (true) {
    case !!state.Activity.selectedUserId:
      return getSelectedMemberItemPaths(state);
    case !!state.Activity.activityFilter: {
      if (state.Activity.activityFilter === "all") return getAllItemPaths(state);
      return getAllItemPaths(state)?.filter((itemPath) => {
        return getItemEventsList(state, itemPath)?.[0]?.[getKeyForFilter(state.Activity.activityFilter)] === true;
      });
    }
  }
}

/**
 * @function filterHasEvents
 * @param {app.State} state
 * @param {string} activityFilter
 * @returns {boolean}
 */
export const filterHasEvents = (state, activityFilter) => {
  if (activityFilter === "all") return getAllItemPaths(state)?.length > 0;
  return getAllItemPaths(state)?.some((itemPath) => {
    return getItemEventsList(state, itemPath)?.[0]?.[getKeyForFilter(activityFilter)] === true;
  });
}

/**
 * @function getMemberItemPaths
 * @param {app.State} state
 * @param {string} memberUserId
 * @return {string[]|undefined}
 */
export const getMemberItemPaths = (state, memberUserId) => {
  return state.Activity.activity?.eventsByMember?.[memberUserId] &&
    Object.keys(state.Activity.activity?.eventsByMember?.[memberUserId]);
}

/**
 * @function getAllItemPaths
 * @param {app.State} state
 * @return {string[]}
 */
export const getAllItemPaths = (state) => {
  return state.Activity.activity?.itemsList;
}

/**
 * @function getSelectedMemberItemEventsList
 * @param {app.State} state
 * @param {string} itemPath
 * @return {web.client.ActivityEvent[]|undefined}
 */
export const getSelectedMemberItemEventsList = (state, itemPath) => {
  const selectedUserId = state.Activity.selectedUserId;
  return getMemberItemEventsList(state, selectedUserId, itemPath);
}
/**
 * @function getItemEventsList
 * @param {app.State} state
 * @param {string} itemPath
 * @return {web.client.ActivityEvent[]|undefined}
 */
export const getItemEventsList = (state, itemPath) => {
  return state.Activity.activity.eventsByItem[itemPath]?.map((eventId) => getEventById(state, eventId));
}

/**
 * @function getMemberItemEventsList
 * @param {app.State} state
 * @param {string} memberUserId
 * @param {string} itemPath
 * @return {web.client.ActivityEvent[]|undefined}
 */
export const getMemberItemEventsList = (state, memberUserId, itemPath) => {
  return state.Activity.activity?.eventsByMember?.[memberUserId]?.[itemPath]?.map((eventId) => getEventById(state, eventId));
}

/**
 * @function getSelectedMemberLatestEvent
 * @param {app.State} state
 * @returns {web.client.ActivityEvent|undefined}
 */
export const getSelectedMemberLatestEvent = (state) => {
  const itemPath = getSelectedMemberItemPaths(state)?.[0];
  return getSelectedMemberItemEventsList(state, itemPath)?.[0];
}

/**
 * @function getMemberItemEvents
 * @param {app.State} state
 * @param {string} itemPath
 * @return {Object<string,web.client.ActivityEvent>|undefined}
 */
export const getSelectedMemberItemEvents = (state, itemPath) => {
  const selectedUserId = state.Activity.selectedUserId;
  return !!selectedUserId &&
    state.Activity.activity?.eventsByMember?.[selectedUserId]?.[itemPath]?.reduce(($acc, eventId) => {
      $acc[eventId] = getEventById(state, eventId);
      return $acc;
    }, {});
}
/**
 *
 * @function getMemberLatestEvent
 * @param {app.State} state
 * @param {string} memberUserId
 * @returns {web.client.ActivityEvent|undefined}
 */
export const getMemberLatestEvent = (state, memberUserId) => {
  const itemPath = getMemberItemPaths(state, memberUserId)?.[0];
  return getMemberItemEventsList(state, memberUserId, itemPath)?.[0];
}

/**
 * @function selectEventById
 * @param {app.State} state
 * @param {string} eventId
 * @return {web.client.ActivityEvent|undefined}
 */
export const getEventById = (state, eventId) =>
  state.Activity.activity.events[eventId];

/**
 * @function getMemberByUserId
 * @param {app.State} state
 * @param {string} userId
 * @returns {{displayName: string, photoURL: string, date: number}}
 */
export const getMemberByUserId = (state, userId) =>
  state.Activity.activity?.members?.[userId];

/**
 * @function getMemberByEmail
 * @param {app.State} state
 * @param {string} email
 * @returns {*|{displayName: string, photoURL: string, date: number}}
 */
export const getMemberByEmail = (state, email) => {
  const userId = state.Activity.activity?.membersByEmail?.[email.toLowerCase()];
  return userId && getMemberByUserId(state, userId);
}
