import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Divider} from "antd";
import {DownIcon} from "@web/assets/icons";
import {useSelector} from "react-redux";
import Markdown from "./Markdown";
import dayjs from "dayjs";
import {getMemberLatestEvent} from "@web/redux/activity/selectors";

UserAvatarCard.propTypes = {
  email: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.string,
  onClick: PropTypes.func,
  extra: PropTypes.node,
};

/**
 * @function getSummaryPreview
 * @param {web.client.ActivityEvent} event
 * @return {string}
 */
const getSummaryPreview = (event) => {
  if (!event) return "";
  const day = !!event?.date && dayjs(event.date);
  const time = day ?
    dayjs().startOf('day').valueOf() < event.date ? // is today
    day.format('hh:mm A') :
    day.format("MMM DD") :
    "";

  const text = `**${time}** ${event.update?.summary || event.summary || ""}`;
  return text.length > 200 ?
    text.substring(0, 200) +
    "..." : text;
}

function UserAvatarCard({email, title, userId, onClick, extra = null}) {
  const event = useSelector((state) => getMemberLatestEvent(state, userId));
  const summaryPreviewText = getSummaryPreview(event);
  const todoStatus = event?.update?.recommendedTodo?.todoStatus ||
    event?.recommendedTodo?.todoStatus;
  // console.log("UserAvatarCard", {event, todoStatus, summaryPreviewText});
  return <Card className="card-rounded card-simple card-transparent" bordered={false}>
    <div className="space space-center-y space-between">
      <h6 className="capitalize">{title}</h6>
      {/*{user.uid === userId &&*/}
      {/*  <RefreshSourceButton loader={"user-loadAll"} />}*/}
      {/*{eventPathList?.length > 1 &&*/}
      {/*  <div className="space space-center-y space-between">*/}
      {/*    /!*<Button size="small" ghost className="icon-btn"><LeftArrowOutlined/></Button>*!/*/}
      {/*    <Button size="small" ghost className="icon-btn">*/}
      {/*      <RightArrowOutlined/>*/}
      {/*    </Button>*/}
      {/*  </div>}*/}
    </div>
    <span className="muted line-clamp-1">{email}</span>
    <div style={{paddingTop: "10px"}}>
      {!!summaryPreviewText && <Markdown markdown={summaryPreviewText} className="ai-text font-lg" />}
    </div>
    <br/>
    {!!event && <div className="space space-center-y space-between">
      <Button className="icon-btn" onClick={() => onClick && onClick(userId, "chat")} size="large">
        <DownIcon className="green-icon" size={20}/> <span className="green-color">Show</span>
      </Button>
      {(todoStatus && todoStatus !== "void") ?
        <span className="ai-text font-lg">{todoStatus || "Has tasks"}</span> :
        <Button className="icon-btn" size="large" onClick={() => null}>Dismiss</Button>}
    </div>}
    {!!extra &&
      <>
        <Divider className="no-top small-bottom" />
        {extra}
      </>}
  </Card>
}

export default UserAvatarCard;
