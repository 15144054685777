import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Image, Typography} from "antd";
import StorageFile from "@web/ui/containers/StorageFile";
import DatasetCard from "./DatasetCard";
import DatasetData from "@web/ui/containers/DatasetData";
import {WindowContext} from "@web/hooks/context";
import {useDispatch} from "react-redux";
// import appActions from "@web/redux/app/actions";
import DocumentCard from "./DocumentCard";
import AudioCard from "./AudioCard";

const onThumbnailClick = (e) => {
  e.stopPropagation();
  e.preventDefault();
  return false;
};

FileCard.propTypes = {
  media: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium", "large", "xl", "xxl", "block"]),
  path: PropTypes.string,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  preview: PropTypes.bool,
  onClick: PropTypes.func,
  lazy: PropTypes.bool,
  asDetail: PropTypes.bool,
};

function FileCard({media, file = media, path, size = "large", direction = "vertical", className, bordered, preview = true, onClick, lazy}) {
  // const dispatch = useDispatch();
  const [isMobile] =
    useContext(WindowContext);

  /**
   * @function getComponent
   * @summary Get a component based on the type of document
   * @param {models.File.Data} file
   * @param {string} path
   * @param {("small" | "medium" | "large" | "xl" | "xxl")} size
   * @param {boolean} isMobile
   * @param {function} onClick
   * @param {boolean} lazy
   * @returns {Element}
   */
  const getComponent = (file, path, size, isMobile, onClick, lazy) => {
    if (!file) {
      return null;
    }
    const {mimeType, documentType} = file;
    const contentType = mimeType?.split("/")?.[0];
    switch (true) {
      case mimeType === "application/pdf":
        return <DocumentCard
          file={file}
          path={path}
          size={size}
          onClick={onClick}
          preview={preview}
          lazy={lazy}
        />
      case contentType === "image": {
        return <StorageFile
          storageURI={file.storageURI}
          key={file.storageURI}
        >
          <Image
            preview={preview}
            alt={file.storageURI}
            onClick={onClick}
          />
        </StorageFile>
      }
      // csv
      case mimeType === "text/csv":
      case mimeType === "text/plain":
      case mimeType === "application/json":
      case mimeType === "application/xml":
        return <DatasetData
          preview={preview}
          path={file.datasetPath}
          component={DatasetCard}
        />;
      case mimeType === "application/vnd.google-apps.spreadsheet":
      case mimeType === "application/vnd.google-apps.document":
      case mimeType === "application/vnd.google-apps.presentation":
      case mimeType === "application/vnd.google-apps.drawing":
      case mimeType === "application/vnd.google-apps.form": {
        return <div style={{width: "100%", paddingBottom: "20px"}}>
          <Image
            wrapperStyle={{border: "solid 3px transparent"}}
            preview={preview}
            key={file?.originId}
            alt={file?.thumbnailLink}
            src={file?.thumbnailLink}
            height={"auto"}
          />
        </div>

      }
      case mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case mimeType === "application/vnd.ms-excel": {
        return <Typography.Title
          level={4}
          type="secondary"
        >
          Spreadsheet
        </Typography.Title>;
      }
      case mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case mimeType === "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case mimeType === "application/vnd.ms-powerpoint":
      case mimeType === "application/msword":
      case mimeType === "application/vnd.oasis.opendocument.text":
        return <Typography.Title
          level={4}
          type="secondary"
        >
          Doc
        </Typography.Title>;
      case mimeType === "text/html":
        return <a href="#" target="_blank" className="space space-center-y space-end">
          <Typography.Title level={4} type="secondary">html</Typography.Title>
        </a>;
      case mimeType?.split("/")?.[0] === "audio":
        return <AudioCard
          file={file}
          path={path}
          preview={preview}
        />
      default:
        return <Typography.Title
          level={4}
          type="secondary"
        >{documentType || mimeType?.split("/")?.pop()}
        </Typography.Title>;
    }
  };

  return <div className={`${direction === "horizontal" ? "" : "space-col"}`}>
    <div
      className={`attachment-thumbnail ${size} ${className??""}${bordered ? '' : ' no-border'}`}
      onClick={onThumbnailClick}
    >
      <div>{getComponent(file, path, size, isMobile, onClick, lazy)}</div>
    </div>
  </div>
}

export default FileCard;
