import React from "react";
import PropTypes from "prop-types";
import {Typography, Tooltip} from "antd";
import {safeKeyForDB, increment, setPath, getPath, updatePath} from "@web/lib/firestore.db";
import {AppContext} from "@web/hooks/context";
import {track} from '@web/lib/analytics';
import now from "lodash/now";
import {UpRightArrowOutlined} from "@web/assets/icons";

ExternalLink.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
};

function ExternalLink(props) {
  const protocol = props.href?.startsWith('http') ? "" : "https://";
  const href = protocol + props.href;
  const [_, user] = React.useContext(AppContext);
  const [extHref, setExtHref] = React.useState(protocol + props.href);

  const onClick = (e) => {
    if (!user?.uid) return;
    e.preventDefault();
    e.stopPropagation();
    e.stopPropagation();
    getPath(["user", user.uid, "links", safeKeyForDB(href)])
    .then((doc) => {
      const extUrl = doc.get("url") || href;
      track(e, {url: extUrl});
      return setPath(["user", user.uid, "link-logs", safeKeyForDB(href)], {clicks: increment(1), date: now()}, true)
      .then(() => window.open(extUrl, "_blank"));
    });
  }

  const onHover = (e) => {
    getPath(["user", user.uid, "links", safeKeyForDB(href)])
    .then((doc) => {
      setExtHref(doc.get("url") || href);
    })
  }

  return <Tooltip
    trigger={['hover']}
    destroyTooltipOnHide={true}
    title={<div className="space space-center-y"><UpRightArrowOutlined style={{minWidth: 22}} />{extHref}</div>}
    mouseEnterDelay={0.5}
  >
    <Typography.Link
      {...props}
      href={extHref || href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      onMouseOver={onHover}
    >
      {props.children}
    </Typography.Link>
  </Tooltip>
}

export default ExternalLink;
