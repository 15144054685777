import React from "react";
import {UploadIcon} from "@web/assets/icons";
import {Button, Modal} from "antd";
import UploadsManager from "./UploadsManager";

UploadModal.propTypes = {};

function UploadModal() {
  const [open, setOpen] = React.useState(false);
  return <>
    <Button className="icon-btn" onClick={() => setOpen(true)}><UploadIcon size={18}/> Uploads</Button>
    <Modal
      open={open}
      closable={false}
      onCancel={() => setOpen(false)}
      afterClose={() => setOpen(false)}
      keyboard
      okText="Finish"
    >
      <UploadsManager/>
    </Modal>

  </>;
}

export default UploadModal;