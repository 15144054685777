import React from 'react';
import PropTypes from 'prop-types';
// import {ArrowRightOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {AppContext} from "@web/hooks/context";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import difference from "lodash/difference";
import AddScopeModal from "./AddScopeModal";

ButtonWithScope.propTypes = {
  requiredScopes: PropTypes.array,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  size: PropTypes.string,
  ghost: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  providerId: PropTypes.string.isRequired,
  providerIcon: PropTypes.node.isRequired,
};

function ButtonWithScope({requiredScopes = [], children, onClick, providerIcon, providerId, ...buttonProps}) {
  const [_, user] = React
  .useContext(AppContext);

  const [provider] =
    useDocumentFirestore([
      "user",
      user.uid,
      "providers",
      providerId,
    ].join("/"));

  const scopesToRequest =
    difference(requiredScopes, provider?.oauthCredentials?.scopes || []);

  const [showModal, setShowModal] = React
  .useState(false);

  const [requestingScopes, setRequestingScopes] = React
  .useState(false);

  const onUserClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    scopesToRequest.length ?
      onClickShowModal() :
      onClick();
  }

  const onClickShowModal = () => {
    setShowModal(true);
    setRequestingScopes(true);
  }

  const onCancel = () => {
    console.log("ButtonWithScope:onCancel");
    setShowModal(false);
    setRequestingScopes(false);
  }

  return <Button
    {...buttonProps}
    onClick={onUserClick}
  >
    {children}
    {!!scopesToRequest?.length &&
      <AddScopeModal
        providerId={providerId}
        open={showModal}
        scopes={scopesToRequest}
        onCancel={onCancel}
        onContinue={() => setRequestingScopes(true)}
        closable={false}
        title="Grant Access"
        icon={providerIcon}
      />}
  </Button>
}

export default ButtonWithScope;
