import React, { useState } from 'react'
import { createUserWithEmailAndPassword, sendSignInLinkToEmail, updateProfile, setPersistence, browserLocalPersistence } from 'firebase/auth'
import {Button, Input, Space, Divider, Card} from 'antd'
import {auth, ga} from '@web/lib/firebase'
import {LockOutlined, MailOutlined} from '@ant-design/icons'
import {notifyAndTrack} from '@ai-antd/components/notification'
import HotClick from '@web/ui/containers/HotClick'
import {AppContext} from '@web/hooks/context'
import {colls, setDocument} from '@web/lib/firestore.db'
import useLocalStorage from '@web/hooks/useLocalStorage'
import {Redirect} from 'wouter'

const domain = location.hostname === 'localhost' ?
  `http://localhost:${location.port}` :
  process.env.REACT_APP_OVEN_APP_DOMAIN;

console.log('domain', domain);
const saveNotes = (uid, notes) => {
  return setDocument(colls["user-profile"], uid, {notes}, true)
}
const fullWidthStyle = {width: '100%'}
const moreOptionsStyle = {marginTop: '10px'}
const signUpEmailPassword = async (app, e, p, d, notes) => {
  if (!e || !p || !d) return notifyAndTrack.warn('Missing Info', 'All fields are required.')
  await setPersistence(auth, browserLocalPersistence)
  createUserWithEmailAndPassword(auth, e, p)
    .then(() => auth.currentUser && updateProfile(auth.currentUser, {displayName: d}))
    .then(() => auth.currentUser && notes && saveNotes(auth.currentUser.uid, notes))
    .then(() => auth.currentUser && auth.currentUser.sendEmailVerification())
    .then(() => ga.setUserId(auth.currentUser.uid))
    .catch(err => {
      switch (err.code) {
        case 'auth/email-already-in-use':
          notifyAndTrack.error('Email is Already In Use')
          break
        case 'auth/weak-password':
          notifyAndTrack.error('Weak Password', 'Password must be at least 6 characters.')
          break
        default:
          notifyAndTrack.error('Sign Up Failed', 'Try again later')
      }
    })
}
const defaultTitle = "Create Your Free Account"
const containerStyle = {width: '100%', marginBottom: '10px'}
export default function AuthEmail({title = defaultTitle, withPassword = false, cta = !withPassword ? "Send Link" : "Sign Up", extra = [], redirectTo = "/"}) {
  const [app, user] = React.useContext(AppContext)
  const [,setEmailLS] = useLocalStorage('signup-email')
  const [,setAnonUID] = useLocalStorage('anon-user-id')
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [notes, setNotes] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  if (user && !user.isAnonymous) return <Redirect to={redirectTo} replace />

  const signUpEmailLink = async (e) => {
    if (!e) return notifyAndTrack.warn('Missing Info', 'All fields are required.')
    await setPersistence(auth, browserLocalPersistence)
    const actionCodeSettings = {
      url: domain + '/email-link' + (redirectTo ? `?redirectTo=${encodeURI(redirectTo)}` : ''),
      handleCodeInApp: true,
    }
    sendSignInLinkToEmail(auth, e, actionCodeSettings)
      .then(() => setEmailLS(email))
      .then(() => user?.isAnonymous && setAnonUID(user.uid))
      .then(() => setEmail(""))
      .then(() => setEmailSent(true))
      .catch(err => {
        console.log('Sign up failed', err, err.code)
        console.log('app.root.domain', app.root.domain)
        switch (err.code) {
          case 'auth/email-already-in-use':
            notifyAndTrack.error('This email is already in use')
            break
          default:
            notifyAndTrack.error('Sign Up Failed', 'Try again later')
        }
      })
  }

  return <section className="flex-col middle center" style={fullWidthStyle}>
    {emailSent ?
      <Card title="We sent you a link" style={{...fullWidthStyle, marginTop: '20px', marginBottom: '10px'}}>
        <Card.Meta description="Check your email and click on the link to continue." />
      </Card> :
      <Space direction="vertical" style={containerStyle}>
        <Divider>{title}</Divider>
        {withPassword && <Input size="large" prefix={null} onChange={e => setDisplayName(e.target.value)} placeholder="Your Name"/>}
        <Input size="large" prefix={<MailOutlined />} onChange={e => setEmail(e.target.value)} placeholder="Your Email" />
        {withPassword && <Input
          size="large"
          prefix={<LockOutlined/>}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
        />}
        {!!extra.length && <Input.TextArea
          size="large"
          value={notes}
          rows={4}
          onChange={e => setNotes(e.target.value)}
          placeholder={extra[0].placeholder || "Type here"}
        />}
        <Button
          size="large"
          block
          onClick={() =>
            !withPassword ?
              signUpEmailLink(email, notes) :
              signUpEmailPassword(app, email, password, displayName, notes)}
          type="primary"
        >
          <HotClick>{cta}</HotClick>
        </Button>
      </Space>
    }
  </section>
}
