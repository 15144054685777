import React from "react";
import {Alert, Card, Empty, List} from "antd";
import CleanModeButton from "./CleanModeButton";
import PropTypes from "prop-types";
import {ThreadsListItemMobile} from "./ThreadsListItemMobile";
import GroupTitle from "./GroupTitle";

ThreadsList.propTypes = {
  list: PropTypes.array,
  hasMore: PropTypes.bool,
  pageNext: PropTypes.func,
  error: PropTypes.object,
}

function ThreadsList({list, hasMore, pageNext, error}) {
  if (error) {
    return <Alert
      message={error.message}
      type="error"
      showIcon
      closable
    />
  }
  return <div
    id="threads-list-scroll-wrapper"
    className="paging-list-wrapper"
    >
      {/*<Pager*/}
      {/*  loader={<Loading/>}*/}
      {/*  pageSize={60}*/}
      {/*  length={list?.length ?? 0}*/}
      {/*  hasMore={hasMore}*/}
      {/*  endMessage={!!list?.length &&*/}
      {/*    <div className="space space-center-y" style={{position: "relative", top: "6px"}}><h6*/}
      {/*      className="muted">End</h6>&nbsp;<LineConnector/>*/}
      {/*    </div>}*/}
      {/*  pageNext={pageNext}*/}
      {/*  scrollContainerId="root-wrapper-scroll"*/}
      {/*>*/}
    <List
      style={{marginLeft: '10px'}}
      header={
        <div className="space space-between space-center-y">
          <h6>Email</h6>
          <CleanModeButton/>
        </div>
      }
      className="list-item-no-divider list-empty-no-padding"
      dataSource={list}
      renderItem={([path, date]) =>
        <ThreadsListItemMobile
          key={path}
          path={path}
          date={date}
        />
      }
      // pagination={{
      //   pageSize: 60,
      //   showSizeChanger: false,
      //   hideOnSinglePage: true,
      // }}
      // loading={!list}
      locale={{
        emptyText: <Empty
          className="add-integrations-empty-wrapper"
          description={null}
          image={null}
          imageStyle={{display: "none"}}
          style={{maxWidth: "600px"}}
        >
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
          </Card>
        </Empty>
      }}
    />
  </div>
}

export default ThreadsList;
