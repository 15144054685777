import {all, takeEvery, takeLatest, call, put, take, cancelled, fork} from "redux-saga/effects";
import actions from "./actions";
import {Call} from "@twilio/voice-sdk";
import {auth} from "@web/lib/firebase";
import TwilioVoice from "@web/lib/twilio-voice";
import {eventChannel} from "redux-saga";
import {setPath} from "@web/lib/firestore.db";
import now from "lodash/now.js";

let activeEvents = [];
const listenTo = (events = [], target) => {
  // const registeredDevice = TwilioVoice().getDevice().device;
  console.log("listenTo", {events, target});
    // const newEvents = router.filter(({ success, key }) => activeEvents.indexOf(key || success) < 0);
    // activeEvents = activeEvents.concat(newEvents.map(({ success, key }) => key || success));
    return eventChannel((emit) => {
        const unsubscribes = events.map(({ event, success, error, ...rest }) => {
          const listener = (...args) => {
              console.log('********** Events Received', event, success);
              emit({
                  payload: {args},
                  type: success,
                  ...rest,
              });
          };
          console.log("subscribing to Twilio", event)
          target.on(event, listener);
          return {event, listener};
        });
        return () => {
            unsubscribes.forEach(({event, listener}) => {
              console.log("unsubscribing from Twilio", event);
              target.off(event, listener);
            });
        };
    });
};

function* channel(events = [], target) {
  const listeners = yield call(listenTo, events, target);
  try {
    while (!!target || (target && (target instanceof Call && (target.status() !== 'disconnected' && target.status() !== 'closed')))) {
      console.log("channel while", "Will take listeners for ", target);
      const result = yield take(listeners);
      yield put(result);
    }
  } finally {
    console.log("channel finally", "Will close listeners for ", target);
    if (yield cancelled() || !target) listeners.close();
  }
}

function registerDevice() {
    return TwilioVoice().register();
}
// function connectCall(params) {
//   console.log("connectCall", params);
//     return TwilioVoice().connect(params);
// }
function* addEventListeners(device) {
    const events = [
        {event: 'error', success: actions.VOICE_DEVICE_ERROR, key: "error"},
        {event: 'incoming', success: actions.VOICE_DEVICE_INCOMING, key: "incoming"},
        {event: 'registered', success: actions.VOICE_DEVICE_REGISTERED, key: "registered"},
        {event: 'registering', success: actions.VOICE_DEVICE_REGISTERING, key: "registering"},
        {event: 'unregistered', success: actions.VOICE_DEVICE_UNREGISTERED, key: "offline"},
    ];
    yield call(channel, events, device);
}
function* addCallEventListeners(voiceCall) {
  console.log("addCallEventListeners", {voiceCall});
  const events = [
    {event: 'error', success: actions.VOICE_CALL_ERROR, key: "error"},
    {event: 'accept', success: actions.VOICE_CALL_ACCEPTED, key: "accept"},
    {event: 'cancel', success: actions.VOICE_CALL_CANCELLED, key: "cancel"},
    {event: 'ignore', success: actions.VOICE_CALL_IGNORED, key: "ignore"},
    {event: 'disconnect', success: actions.VOICE_CALL_DISCONNECTED, key: "disconnect"},
    {event: 'reconnecting', success: actions.VOICE_CALL_RECONNECTING, key: "reconnecting"},
    {event: 'mute', success: actions.VOICE_CALL_MUTED, key: "mute"},
    {event: 'reject', success: actions.VOICE_CALL_REJECTED, key: "reject"},
    {event: 'ringing', success: actions.VOICE_CALL_RINGING, key: "ringing"},
    {event: 'warning', success: actions.VOICE_CALL_WARNING, key: "warning"},
  ];
  yield call(channel, events, voiceCall);
}

function logNewCall(voiceCall) {
  const {customParameters = null, parameters = null} = voiceCall;
  const direction = voiceCall?.direction;
  console.log("logNewCall", parameters.CallSid, voiceCall, voiceCall.direction);
  console.log("logNewCall", customParameters);
  if (!parameters?.CallSid) return;
  return setPath(["user", auth.currentUser?.uid, "voice", parameters.CallSid], {direction, parameters, createdTs: now()}, true);
}

/**
 * Initialize voice
 * @return {Generator<*, void, *>}
 */
function* register() {
  if (!auth.currentUser?.uid) return;
  const twilioVoice = TwilioVoice();

  const voice = yield call(twilioVoice.getDevice);
  if (!voice?.device) return;
  if (voice.device.state === 'registered' || voice.device.state === 'registering') return;

  yield fork(addEventListeners, voice.device);
  yield call(registerDevice);
}

/**
 * Connect a call
 * @param {object} action
 * @param {object} action.params
 * @return {Generator<*, void, *>}
 */
function* connect({params = null}) {
  console.log("connect", params);
  if (!auth.currentUser?.uid) return;
  if (!params?.To) return;
  const twilioVoice = TwilioVoice();
  const outgoingCall = yield call(twilioVoice.connect, params);

  yield put({
    type: actions.VOICE_CALL_CONNECTED,
    payload: {args: [outgoingCall]},
  });

  // console.log("connect", outgoingCall);

  yield fork(addCallEventListeners, outgoingCall);
  yield call(logNewCall, outgoingCall);
}

function* disconnectAll() {
  TwilioVoice().getDevice().device?.disconnectAll();
}
function* toggleMuted({call: voiceCall}) {
  if (!call) return;
  yield call(voiceCall.mute, !call.isMuted());
}
function* logCall({payload}) {
  yield call(logNewCall, payload?.args[0]);
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.VOICE_DEVICE_REGISTER, register),
    takeLatest(actions.VOICE_CALL_CONNECT, connect),
    takeEvery(actions.VOICE_CALL_DISCONNECT_ALL, disconnectAll),
    takeEvery(actions.VOICE_CALL_TOGGLE_MUTED, toggleMuted),
    takeEvery(actions.VOICE_DEVICE_INCOMING, logCall),
    takeLatest(actions.VOICE_CALL_ACCEPTED, logCall),
  ]);
}
