const actions = {
  LOAD_TIMELINE: "LOAD_TIMELINE",
  LOAD_TIMELINE_SUCCESS: "LOAD_TIMELINE_SUCCESS",
  LOAD_TIMELINE_NEXT_PAGE: "LOAD_TIMELINE_NEXT_PAGE",
  LOAD_TIMELINE_NEXT_PAGE_SUCCESS: "LOAD_TIMELINE_NEXT_PAGE_SUCCESS",

  /**
   * @summary Load timeline
   * @returns {(function(*, *): void)|*}
   */
  loadTimeline: () => {
    return (dispatch, getState) => {
      console.log('loadTimeline');
      const params = getState().Timeline.params || {};
      dispatch({
        type: actions.LOAD_TIMELINE,
        params,
      });
    };
  },
  pageTimeline: () => {
    console.log('pageTimeline')
    return (dispatch) => {
      dispatch({
        type: actions.LOAD_TIMELINE_NEXT_PAGE,
      });
    };
  },
};

export default actions;
