import React, {useContext} from "react";
import {Redirect} from "wouter";
import {AppContext} from "@web/hooks/context";

const RequireVerification = ({children}) => {
  const [,user, claims] = useContext(AppContext)
  // console.log('RequireVerification', user?.uid, 'isVerified', !!claims?.data?.verified)
  if (!user) return <Redirect to="/sign-up" />
  if (user.isAnonymous) return <Redirect to="/sign-up" />
  // console.log('RequireVerification', !!claims.data?.verified)
  return (claims.data?.verified) ?
    children :
    <Redirect to="/verify" />;
}

export default RequireVerification