import React, {Fragment, useContext} from "react";
import {Empty, List} from "antd";
import {AppContext} from "@web/hooks/context";
import ContactsListItem from "./ContactsListItem";
import Loading from "./Loading";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import GroupTitle from "./GroupTitle";
import PropTypes from "prop-types";

ContactsList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
}

function ContactsList(props) {
  const [, user] =
    useContext(AppContext);

  if (!user?.uid) {
    return null;
  }

  return <Fragment>
      <Pager
      loader={<Loading/>}
      length={props.list?.length || 0}
      hasMore={
        props.list?.length &&
        (props.list.length % 30) > 0
      }
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector/>
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="root-wrapper-scroll"
    >
      <List
        header={
        <GroupTitle
          title="Activity"
          style={{paddingLeft: '15px'}}
        />}
        className="list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <ContactsListItem
            key={path}
            path={path}
            date={date}
          />}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
          </Empty>
        }}
      />
  </Pager>
</Fragment>
}

export default ContactsList;
