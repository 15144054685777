import React from "react";
import {api} from "@web/lib/api";
import {generateID} from "@web/lib/firestore.db";
import AiStreamText from "./AiStreamText";
import {Button, Divider, Space, Typography} from "antd";
import MarkdownTimeline from "./MarkdownTimeline";

const aiEndpoint = {
  dev: "http://127.0.0.1:5001/admin-85663/us-central1/chat-ai",
  prod: "https://chat-ai-7zujc5azra-uc.a.run.app",
  cloudRun: "http://localhost:8080/ai",
};
const aiBeta = {
  dev: "http://127.0.0.1:5001/admin-85663/us-central1/chat-workflow",
  prod: "https://chat-stream-7zujc5azra-uc.a.run.app",
  cloudRun: "http://localhost:8080/aiBeta",
};
function AiStream() {
  const [streamId, setStreamId] = React.useState("");
  const [content, setContent] = React.useState("");
  const send = () => {
    setStreamId(generateID());
  }
  const stream = () => {
    api.STREAM(aiBeta.dev, {
      prompt: "What are the highlights of World War 2?",
    }, (content) => setContent(content))
    .then((res) => {
      console.log("finish", res);
    });
  }
  React.useEffect(() => {
    if (!streamId) return;
    api.POST(aiEndpoint.prod, {
      streamId,
      prompt: "What are the highlights of World War 2?",
    })
      .then(() => null);
  }, [streamId]);

  return <div>
    {streamId && <AiStreamText streamId={streamId}/>}
    {/*{content && <Typography.Paragraph className="ai-text" style={{fontSize: '20px'}}>*/}
    {/*  {content}*/}
    {/*</Typography.Paragraph>}*/}
    <MarkdownTimeline markdown={content} active />
    <Divider />
    <Space>
      <Button onClick={send}>Send</Button><Button onClick={stream}>Stream</Button>
    </Space>
  </div>
}

export default AiStream;