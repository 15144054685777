import actions from "./actions";

/**
 * @typedef {object} State.Activity
 * @property {string} selectedUserId
 * @property {web.client.ActivityPayload|null} activity
 * @property {Error|null} error
 */

/** @type {State.Activity} */
const initState = {
  selectedUserId: {},
  activity: null,
  error: null,
};

/**
 * @summary Reducer for activity
 * @function reducer
 * @param {app.State.Activity} state
 * @param {object} action
 * @param {string} action.type
 * @param {web.client.ActivityPayload} [action.activity]
 * @param {string} [action.userId]
 * @param {string} [action.activityFilter]
 * @param {FirestoreQueryItems} [action.payload]
 * @param {Error} [action.error]
 * @return {State.Activity}
 */
export default function reducer(state = initState, {type, activity, activityFilter, payload, pageParams, error, userId, serviceId}) {
  switch (type) {
    case actions.SELECT_CONTACT: {
      if (!serviceId) return state;
      if (!userId) return state;
      return {
        ...state,
        selectedUserId: {
          ...state.selectedUserId,
          [serviceId]: userId === state.selectedUserId[serviceId] ? "" : userId
        },
        activityFilter: "",
      };
    }
    case actions.LOAD_TOP_CONTACTS_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity,
        error: null,
      };
    case actions.LOAD_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        ...activity,
        error: null,
      };
    case actions.LOAD_TOP_CONTACTS_ACTIVITY_ERROR:
      return {
        ...state,
        error,
      };
    case actions.SET_ACTIVITY_FILTER:
      return {
        ...state,
        activityFilter,
      };
    case actions.LOAD_CONTACTS_SUCCESS:
      console.log("LOAD_CONTACTS_SUCCESS:", {payload, pageParams});
      return {
        ...state,
        contacts: {
          ...payload,
          pageParams,
        },
      };
    default:
      return state;
  }
}
