import React, {useContext} from "react";
import {Button, Carousel, Menu} from "antd";
import {auth, ga} from "@web/lib/firebase";
import {WindowContext} from '@web/hooks/context'
import backgroundActions from "@web/redux/background/actions";
import SplashCard from './SplashCard'
import {AccountIcon, DownArrowOutlined, LogoutIcon, RightArrowOutlined} from "@web/assets/icons";
// import {refreshConfig} from '@web/lib/remote.config'
// import useRemoteConfig from "@web/hooks/useRemoteConfig";
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "./Loading";
import {useDispatch} from "react-redux";
import {Link, useLocation} from "wouter";
import DashboardRow from "./DashboardRow";
import useLocalStorage from "@web/hooks/useLocalStorage";

export default function Splash(props) {
  const {initialSlideIndex = 0} = props;
  const dispatch = useDispatch();
  const slider = React.useRef({});
  const [user, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const [isMobile] = useContext(WindowContext);
  const [seeGenesis, setSeeGenesis] = useLocalStorage('seeGenesis')
  const [location, setLocation] = useLocation();
  // const [config, loadingConfig] = useRemoteConfig(app.root.configKey);
  const [disableNavigation, setDisableNavigation] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(initialSlideIndex);
  const startAnimation = React.useCallback(() => dispatch(backgroundActions.startAnimation()), [dispatch]);
  const pauseAnimation = React.useCallback(() => dispatch(backgroundActions.pauseAnimation()), [dispatch]);

  React.useEffect(() => {
    if (location === '/genesis') setSeeGenesis(1)
  }, [location]);

  const navigateNext = React.useCallback((e) => {
    slider.current?.next()
    ga.click(e)
  }, [slider, slider.current, currentIndex])

  const goToIndex = React.useCallback((index, noAnimation = true) => {
    slider.current.goTo(index);
  }, [currentIndex])

  if (loadingUser) return <Loading />

  const slides = [
    <SplashCard.Home pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={0} />,
    // <SplashCard.Apps pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={1} />,
    // <SplashCard.Assistant pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={2} />,
    // <SplashCard.Insights pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={3} />,
    <SplashCard.Signup pauseAnimation={pauseAnimation} startAnimation={startAnimation} goToIndex={goToIndex} index={1} disableNavigation={setDisableNavigation}/>,
    <SplashCard.Invite pauseAnimation={pauseAnimation} startAnimation={startAnimation} goToIndex={goToIndex} index={2} next={navigateNext} disableNavigation={setDisableNavigation} />,
    <SplashCard.Subscribe pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={3} />,
  ];

  return <section style={{position: 'relative'}}>
      <Carousel
        ref={(ref) => slider.current = ref}
        initialSlide={initialSlideIndex}
        className="splash-carousel splash-carousel-dots"
        adaptiveHeight
        prevArrow={null}
        nextArrow={!isMobile && !disableNavigation && <Button icon={<RightArrowOutlined />} />}
        arrows={!isMobile && !disableNavigation}
        swipe={!disableNavigation}
        dots={!disableNavigation}
        accessibility={!isMobile && !disableNavigation}
        beforeChange={() => console.log('beforeChange')}
        afterChange={(index) => setCurrentIndex(index)}
        onSwipe={() => null}
        lazyLoad="ondemand"
        infinite={false}
        draggable={false}
        waitForAnimate={true}
      >
        {slides}
      </Carousel>
    <div style={{position: "fixed", top: "20px", right: "20px", width: '100%'}}>
      <DashboardRow>
        <div className="space space-center-y space-end">
          {<Menu mode="horizontal" theme="dark" className="splash-main-menu">
            <Menu.SubMenu
              key="SubMenu"
              title={<span>{user?.displayName || user?.email?.split("@")?.[0]}</span>}
              icon={<DownArrowOutlined size={22}/>}
            >
              {/*<Menu.Item key="videos" className="space space-center-y" icon={<CaptureIcon size={18} />}>*/}
              {/*  Videos*/}
              {/*</Menu.Item>*/}
              <Menu.Divider/>
              {user && !user.isAnonymous ?
                <Menu.Item key="logout" className="space space-center-y" icon={<LogoutIcon size={16}/>}>
                  <Link href="/logout">Logout</Link>
                </Menu.Item> :
                <Menu.Item key="login" className="space space-center-y" icon={<AccountIcon size={16}/>}>
                <Link href="/login">Login</Link>
              </Menu.Item>}
              {/*<Menu.ItemGroup title="Item Group">*/}
              {/*  <Menu.Item key="four" icon={<AppstoreOutlined />}>*/}
              {/*    Navigation Four*/}
              {/*  </Menu.Item>*/}
              {/*  <Menu.Item key="five" icon={<AppstoreOutlined />}>*/}
              {/*    Navigation Five*/}
              {/*  </Menu.Item>*/}
              {/*</Menu.ItemGroup>*/}
            </Menu.SubMenu>
          </Menu>}
        </div>
      </DashboardRow>
    </div>
  </section>
}
