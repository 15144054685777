import React from "react";
import PropTypes from "prop-types";
import OauthConnectButton from "./OauthConnectButton";
import {List} from "antd";

AppIntegrationListItem.propTypes = {
  integrationId: PropTypes.string.isRequired,
  integration: PropTypes.object.isRequired,
  iconStyle: PropTypes.object,
};

function AppIntegrationListItem({integrationId, integration, iconStyle}) {
  if (!integration) return null;
  const {name, description, website, provider} = integration;
  return <List.Item key={integrationId}>
    <List.Item.Meta title={<a href={website?.url || "#"} target="_blank"><h6>{name}</h6></a>}/>
    <div>
      <OauthConnectButton
        integrationId={integrationId}
        integration={integration}
      />
    </div>
  </List.Item>;
}

export default AppIntegrationListItem;
