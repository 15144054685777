import {useCollection} from 'react-firebase-hooks/firestore'
import {toDataPairs} from '@web/lib/firestore.db'
import {FirestoreError, Query} from "firebase/firestore";

/**
 * useQueryFirestore
 * @function useQueryFirestore
 * @param {Query} query
 * @returns {[DataItem[],boolean,FirestoreError]}
 */
const useQueryFirestore = (query) => {
  const [querySnapshot, isLoading, error] = useCollection(query)
  error && console.log('useQueryFirestore', error.code, error.message, query);
  return [toDataPairs(querySnapshot), isLoading, error]
}

export default useQueryFirestore
