import React from "react";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {CheckIcon} from "@web/assets/icons";
import Loading from "./Loading";

OauthCompletionStatus.propTypes = {
  userId: PropTypes.string.isRequired,
  integrationId: PropTypes.string.isRequired,
};

function OauthCompletionStatus({userId, integrationId}) {
  const [provider = {}, loading] = useDocumentFirestore(["user", userId, "providers", integrationId]);
  return <div>{
    provider.oauthCredentials?.accessToken ? <CheckIcon size={30} style={{color: 'green'}} /> : <Loading />
  }</div>
}

export default OauthCompletionStatus;