import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "./UserAvatar";
import useSelectedContact from "@web/hooks/useSelectedContact";
import useDomainIcon from "@web/hooks/useDomainIcon";
import {useSelector} from "react-redux";

MemberAvatar.propTypes = {
  userId: PropTypes.string.isRequired,
  memberUserId: PropTypes.string.isRequired,
  size: PropTypes.any,
  selected: PropTypes.bool,
  avatarOnly: PropTypes.bool,
  onClick: PropTypes.func,
};

function MemberAvatar({size, avatarOnly, selected = false, ...props}) {
  const [selectedUserId, unSelect] =
    useSelectedContact();

  // console.log('MemberAvatar:', props.userId, props.memberUserId);

  const path =
    `user/${props.userId}/contacts/${props.memberUserId}`;

  const member =
    useSelector((state) =>
      state.Content.data?.[path] ||
      state.Content.members?.[props.memberUserId]);

  const DomainIcon =
    useDomainIcon(
      member?.domain,
      member?.indicators,
    );

  if (!member) {
    return null;
  }

  const [fName, lName = ""] =
    (member?.name || member?.displayName)
    .split(" ");

  const displayName =
    `${fName} ${lName ? lName?.[0]+".": ""}`;

  // console.log('MemberAvatar:', member);

  return <div className={`contact-avatar-wrapper ${avatarOnly ? "avatar-only" : "as-chip"}`}>
    <UserAvatar
      selected={selected}
      onClick={props.onClick}
      clickTip={(selectedUserId && (selectedUserId === props.memberUserId)) ?
        <span className="ai-text">Click to remove focus</span> :
        <span className="ai-text">Click to focus</span>}
      displayName={(member?.name || member?.displayName)}
      photoURL={member?.photoURL}
      email={member?.email}
      size={size}
      userId={props.memberUserId}
      path={path}
      icon={DomainIcon}
    />
    <div className="contact-meta">
      <span className="contact-name muted">
        {displayName}
      </span>
      {/*{!!member?.email &&*/}
      {/*  <span className="contact-email muted">*/}
      {/*    {member?.email?.split("@")?.[0]?.replaceAll("-", " ")}*/}
      {/*  </span>}*/}
    </div>
  </div>
}

export default MemberAvatar;
