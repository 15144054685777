import React from 'react';
import PropTypes from 'prop-types';

HashPager.propTypes = {
  style: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onClick: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  prefix: PropTypes.node,
};

function HashPager(props) {
  const [activeKey, setActiveKey] = React.useState(props.initialValue?.key ?? props.initialValue);

  React.useEffect(() => {
    if (activeKey) {
      document.getElementById(activeKey)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [activeKey]);

  const onClick = (key) => {
    setActiveKey(key);
    props.onClick &&
    props.onClick(key);
  }

  return <section className="timeline-pager-wrapper" style={props.style ?? null}>
    <div className={`space ${props.direction === 'vertical' ? 'space-col' : 'space-center-y'} timeline-pager`}>
      {props.prefix}
      {props.list.map((hash) => {
        return <div key={hash.key ?? hash} className="fat-chip primary">
          <span className="muted font-sm uppercase" onClick={() => onClick(hash.key ?? hash)}>
            {hash.label ?? hash}
          </span>
        </div>
      })}
    </div>
  </section>
}

export default HashPager;
