import actions from "./actions";
import AlgoliaIndex from "@web/lib/algolia.db";
import reduce from "lodash/reduce.js";
import sortBy from "lodash/sortBy.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  ...(reduce(AlgoliaIndex.NAMES, ($acc, indexName) => {
    $acc[indexName] = {data: {}, list: [], query: "", error: null};
    return $acc;
  }, {})),
  todos: {data: {}, list: [], query: "", error: null},
  app: null,
  members: null,
};

export default function reducer(state = initState, {type, payload}) {
  switch (type) {
    case actions.SEARCH_UPDATE_APP_FILTERS:
      console.log("SEARCH_UPDATE_APP_FILTERS", {payload});
      return {
        ...state,
        app: {...state.app, ...payload},
      };
    case actions.SEARCH_CLEAR_APP_FILTERS:
      return {
        ...state,
        app: null,
          members: null,
      };
    // case actions.QUICK_SEARCH_SUCCESS:
    //   return {
    //     ...state,
    //     ...Object.entries(payload).reduce(($acc, [serviceId, {list = [], data = {}}]) => {
    //       $acc[serviceId] = $acc[serviceId] || {};
    //       $acc[serviceId].data = {...state[serviceId].data, ...data};
    //       $acc.quick[serviceId] = list;
    //       return $acc;
    //     }, {quick: {}}),
    //   };
    case actions.SEARCH_APP_SUCCESS: {
      const members = {};
      Object.values(payload).forEach((group) => {
          Object.entries(group?.facets?._tags || {}).forEach(([tag, count]) => {
              const [, memberUserId] = tag.split("member:");
              if (memberUserId) members[memberUserId] = (members[memberUserId] || 0) + count;
          });
      });
      console.log('SEARCH_APP_SUCCESS', payload, members);
      return {
        ...state,
        ...payload,
          members: sortBy(Object.entries(members), 1),
      };
    }
    case actions.SEARCH_SIMILARITY_SUCCESS:
      return {
        ...state,
        similarity: payload,
      };
    default: {
      return state;
    }
  }
}
