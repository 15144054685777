import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
};

/**
 * @summary Reducer for ai stream
 * @param {object} state
 * @param {object} data
 * @param {string} data.type
 * @param {{chatId:string,chat:models.Chat.Data,path:string}} data.stream
 * @return {*}
 */
export default function reducer(
  state =
    initState, {
    type,
    agentId,
    stream,
    streamDone,
    draftPath,
  }) {
  switch (type) {
    case actions.AI_WRITE_STREAM:
      return {
        ...state,
        [agentId]: [{
          ...stream.chat,
          answers: [
            ...stream
            .chat
            .answers
            .map((answer) =>
              ({...answer})),
            ].filter(Boolean),
          },
          stream.path,
          streamDone,
        ],
      };
    case actions.AI_CLEAR_STREAM:
      return {};
    case actions.SET_ACTIVE_EMAIL_DRAFT:
      return {
        ...state,
        draft: {
          draftPath,
          collapse: true,
        },
      };
    case actions.CLEAR_ACTIVE_EMAIL_DRAFT: {
      // console.log("ai:reducer:CLEAR_ACTIVE_EMAIL_DRAFT");
      return {
        ...state,
        draft: null,
      };
    }
    default:
      return state;
  }
}
