import React, {useContext} from "react";
import {Button, Carousel, Menu} from "antd";
import {auth, ga} from "@web/lib/firebase";
import {WindowContext} from '@web/hooks/context'
import backgroundActions from "@web/redux/background/actions";
import {AccountIcon, DownArrowOutlined, LogoutIcon, RightArrowOutlined} from "@web/assets/icons";
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "../Loading";
import {useDispatch} from "react-redux";
import {Link, useLocation} from "wouter";
import DashboardRow from "../DashboardRow";
import useLocalStorage from "@web/hooks/useLocalStorage";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {
  Welcome,
  Team,
  Origin,
  Connect,
  LinkedAccounts,
  Invites,
  Websites,
  Voice,
  Training,
  Features,
  Terms,
  Summary, Tools,
} from "./Onboarding";
import {buildQuery, setPath, updatePath} from "@web/lib/firestore.db";
import now from "lodash/now";
import useQueryFirestore from "@web/hooks/useQueryFirestore";

export default function OnboardingRouter(props) {
  const {initialSlideIndex = 0} = props;
  const dispatch = useDispatch();
  const [user, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const [completedDataItems, loadingOnboarding, errorLoadingOnboarding] = useQueryFirestore(buildQuery(["user", user.uid, "onboarding", "beta", "boards"], {}));
  const slider = React.useRef({});
  const [isMobile] = useContext(WindowContext);
  // const [seeGenesis, setSeeGenesis] = useLocalStorage('seeGenesis')
  // const [location, setLocation] = useLocation();
  // const [config, loadingConfig] = useRemoteConfig(app.root.configKey);
  const [disableNavigation, setDisableNavigation] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(initialSlideIndex);
  const startAnimation = React.useCallback(() => dispatch(backgroundActions.startAnimation()), [dispatch]);
  const pauseAnimation = React.useCallback(() => dispatch(backgroundActions.pauseAnimation()), [dispatch]);

  const boardIsCompleted = (boardKey) => {
    return !!completedDataItems.find(([completedItemKey]) => completedItemKey === boardKey);
  }

  const markComplete = React.useCallback(function() {
    setPath(["user", user.uid, "onboarding", "beta", "boards", this.boardKey],
      {completed: true, date: now()},
      true)
      .then(() => console.log("marked board complete", this.boardKey));
  }, [currentIndex]);

  const navigateNext = React.useCallback(function() {
    slider.current?.next()
  }, [currentIndex]);

  const markCompleteAndNavigateNext = React.useCallback(function() {
    console.log(this);
    markComplete.bind(this)();
    navigateNext();
  }, [currentIndex]);

  const goToIndex = React.useCallback(function(index, noAnimation = true) {
    slider.current.goTo(index);
  }, [currentIndex]);

  if (loadingUser) return <Loading />
  if (loadingOnboarding) return <Loading />

  /**
   * @typedef {object} user.onboarding.BoardProps
   * @property {function} setDisableNavigation
   * @property {function} goToIndex
   * @property {function} pauseAnimation
   * @property {function} startAnimation
   * @property {function} navigateNext
   * @property {function} markComplete
   * @property {function} markCompleteAndNavigateNext
   */

  /** @type {user.onboarding.BoardProps} */
  const handlers = {
    setDisableNavigation,
    goToIndex,
    pauseAnimation,
    startAnimation,
    navigateNext,
    markComplete,
    markCompleteAndNavigateNext,
  }

  const boards = {
    welcome: Welcome,
    origin: Origin,
    connect: Connect,
    tools: Tools,
    linkedAccounts: LinkedAccounts,
    // websites: Websites,
    // voice: Voice,
    training: Training,
    // features: Features,
    // terms: Terms,
    // invites: Invites,
    summary: Summary,
    team: Team,
  }

  return <section style={{position: 'relative'}}>
      <Carousel
        ref={(ref) => slider.current = ref}
        initialSlide={initialSlideIndex}
        className="splash-carousel splash-carousel-dots"
        adaptiveHeight
        prevArrow={null}
        nextArrow={!isMobile && !disableNavigation && <Button icon={<RightArrowOutlined />} />}
        arrows={false}
        swipe={!disableNavigation}
        dots={!disableNavigation}
        accessibility={!isMobile && !disableNavigation}
        beforeChange={() => console.log('beforeChange')}
        afterChange={(index) => setCurrentIndex(index)}
        onSwipe={() => null}
        lazyLoad="ondemand"
        infinite={false}
        draggable={false}
        waitForAnimate={true}
      >
        {Object.entries(boards).map(([boardKey, Component], index) => {
          if (boardIsCompleted(boardKey)) return null;
          const props = Object.entries(handlers).reduce(($acc, [name, handler]) => {
            $acc[name] = handler.bind({boardKey, index});
            return $acc;
          }, {});
          return <Component key={boardKey} {...props} />;
        })}
        {/*<Welcome key="welcome" {...boardProps} />*/}
        {/*<Origin {...boardProps}/>*/}
        {/*<Connect {...boardProps} />*/}
        {/*<Tools {...boardProps} />*/}
        {/*<LinkedAccounts {...boardProps} />*/}
        {/*<Websites {...boardProps} />*/}
        {/*<Voice {...boardProps} />*/}
        {/*<Training {...boardProps} />*/}
        {/*/!*<Features{...boardProps} />*!/*/}
        {/*/!*<Terms {...boardProps} />*!/*/}
        {/*/!*<Invites {...boardProps} />*!/*/}
        {/*<Summary {...boardProps} />*/}
        {/*<Team {...boardProps} />*/}
      </Carousel>
    <div style={{position: "fixed", top: "20px", right: "20px", width: '100%'}}>
      <DashboardRow>
        <div className="space space-center-y space-end">
          {<Menu mode="horizontal" theme="dark" className="splash-main-menu">
            <Menu.SubMenu
              key="SubMenu"
              title={<span>{user?.displayName || user?.email?.split("@")?.[0]}</span>}
              icon={<DownArrowOutlined size={22}/>}
            >
              {/*<Menu.Item key="videos" className="space space-center-y" icon={<CaptureIcon size={18} />}>*/}
              {/*  Videos*/}
              {/*</Menu.Item>*/}
              <Menu.Divider/>
              {user && !user.isAnonymous ?
                <Menu.Item key="logout" className="space space-center-y" icon={<LogoutIcon size={16}/>}>
                  <Link href="/logout">Logout</Link>
                </Menu.Item> :
                <Menu.Item key="login" className="space space-center-y" icon={<AccountIcon size={16}/>}>
                <Link href="/login">Login</Link>
              </Menu.Item>}
              {/*<Menu.ItemGroup title="Item Group">*/}
              {/*  <Menu.Item key="four" icon={<AppstoreOutlined />}>*/}
              {/*    Navigation Four*/}
              {/*  </Menu.Item>*/}
              {/*  <Menu.Item key="five" icon={<AppstoreOutlined />}>*/}
              {/*    Navigation Five*/}
              {/*  </Menu.Item>*/}
              {/*</Menu.ItemGroup>*/}
            </Menu.SubMenu>
          </Menu>}
        </div>
      </DashboardRow>
    </div>
  </section>
}
