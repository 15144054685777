import React from "react";
import PropTypes from "prop-types";
import BatchOperationProgress from "@ai-antd/components/BatchOperationProgress";
import {Link, useLocation} from "wouter";

BatchOperationManager.propTypes = {
  children: PropTypes.node,
  showCount: PropTypes.bool,
};

function BatchOperationManager({children, showCount = false}) {
  const [path, setPath] = React.useState("");
  const [batchOperationError, setBatchOperationError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [location] = useLocation();
  /**
   * @function onStart
   * @param {{path: string, success: boolean, error: string}} batchOperationResponse
   * @returns {void}
   */
  const onStart = (batchOperationResponse) => {
    if (!batchOperationResponse) return;
    if (batchOperationResponse instanceof Error) setBatchOperationError(batchOperationResponse.message);
    if (batchOperationResponse.success !== true) setBatchOperationError(batchOperationResponse.error || batchOperationResponse.message || "Something went wrong");
    setPath(batchOperationResponse?.path);
  };
  const onDismiss = () => {
    setPath("");
    setBatchOperationError("");
  };
  const onSuccess = () => {
    setSuccess(true);
    // setPath("");
    // setBatchOperationError("");
  };
  if (success && location !== "/today") return <Link href="/today">Continue</Link>;
  return !!path ?
    <BatchOperationProgress path={path} requestError={batchOperationError} onDismiss={onDismiss} onSuccess={onSuccess} showCount={showCount}/> :
    React.cloneElement(children, {onStart});
}

export default BatchOperationManager;