import React from 'react';
import PropTypes from 'prop-types';
import {Card, Space, Typography} from "antd";
import {CalendarIcon} from "@web/assets/icons";
import MarkdownTimeline from "./MarkdownTimeline";
import SlideCardWrapper from "./SlideCardWrapper";
import dayjs from "dayjs";
import {TimelineContext} from "@web/hooks/context";
import {useSelector} from "react-redux";
//
// const getDisplayFields = ({activity, data}) => {
//   if (activity) {
//     const eventIds = activity?.eventIds || [];
//     const event = activity.events[eventIds[eventIds.length - 1]];
//     return {title: event?.activitySummary};
//   }
//   return {title: data.summary};
// }

TimelineCalendarsCard.propTypes = {
  path: PropTypes.string.isRequired,
  // content: PropTypes.object,
};

function TimelineCalendarsCard(props) {
  // const {itemPath, date} = props.calendars[0];
  // const context = React.useContext(TimelineContext);
  // console.log('TimelineCalendarsCard', {itemPath, date, calendars: context.content[itemPath]});
  /** @type {models.Calendar.Data} */
  const calendarEvent =
    useSelector((state) =>
      state.Content.data?.[props.path]);

  if (!calendarEvent) {
    return null;
  }

  return <SlideCardWrapper key={props.path} size="xxl">
    <Card
      bordered={false}
      title={<div className="space space-center-y">
        <CalendarIcon/>&nbsp;
        <label className="font-xl card-header-title-text">
          {dayjs(calendarEvent.startTs)
          .format('hh:mm A')}
        </label>
        <label className="font-xl" style={{fontWeight: '600'}}>{calendarEvent?.summary}</label>
      </div>}
      className="card-rounded card-simple extra-round extra-padding card-body-scroll card-h-600"
    >
      <div className="space space-col space-between" style={{height: '80%'}}>
        <MarkdownTimeline
          markdown={calendarEvent.description}
          components={{
            p: (props) => {
              return <Typography.Text className="ai-text" style={{
                fontSize: '22px',
                lineHeight: '1.4em',
                marginBottom: '15px'
              }}>{props.children}</Typography.Text>
            }
          }}
        />
        <br/>
        <Space align="start">
          {calendarEvent
          .memberUserIds
          ?.map((memberUserId) =>
            <span
              key={memberUserId}
              className="fat-chip primary">
              {calendarEvent.members?.[memberUserId].displayName}
            </span>)}
          {!!calendarEvent["GOOGLE-CONFERENCE"] &&
            <span key="join" className="fat-chip primary">
              <a href={calendarEvent["GOOGLE-CONFERENCE"]} target="_blank">Join</a>
            </span>}
          {/*<span key="join" className="fat-chip primary">{calendarEvent.status}</span>*/}
        </Space>
      </div>
    </Card>
    </SlideCardWrapper>
}

export default TimelineCalendarsCard;
