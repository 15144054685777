import React from "react";
import PropTypes from "prop-types";
import {Tooltip} from "antd";

IconTip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  trigger: PropTypes.arrayOf(PropTypes.string),
};

function IconTip(props) {
  return <Tooltip
    placement={props.placement || "bottomLeft"}
    title={props.title}
    trigger={props.trigger || ["hover"]}
    mouseEnterDelay={0.35}
    mouseLeaveDelay={0.5}
    className={"user-avatar-tooltip"}
  >{props.children}</Tooltip>
}

export default IconTip;
