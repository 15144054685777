import {all, takeLatest, call, put, fork} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import AlgoliaIndex from "@web/lib/algolia.db";
import {SearchOptions} from "algoliasearch";
import map from "lodash/map.js";

/**
 * @function stringifyFilters
 * @param {object} filters
 * @return {string}
 */
export const stringifyFilters = (filters = null) => {
  return map(filters, (value, key) => {
    return ` ${key}:${value}`;
  }).join(" AND");
}

/**
 * @summary Run a search
 * @function runSearch
 * @param {string} query
 * @param {SearchOptions} searchOptions
 * @return {Generator<*, void, *>}
 */
function* runSearch(query, searchOptions) {
  const index = new AlgoliaIndex("timeline");
  const payload = yield call(index.search, query, searchOptions);
  yield put({
    type: actions.LOAD_TIMELINE_SUCCESS,
    payload,
  });
}

/**
 * @summary Load Events
 * @generator
 * @return {Generator<*, void, *>}
 */
function* loadTimeline({params}) {
  const userId = auth.currentUser.uid;
  /** @type {SearchOptions} */
  const searchOptions = {
    hitsPerPage: params.hitsPerPage,
    filters: stringifyFilters(params.filters),
    facetFilters: params.facetFilters,
    numericFilters: params.numericFilters,
    tagFilters: [userId, ...params.tagFilters],
    facets: params.facets,
    page: params.page,
    attributesToRetrieve: params.attributesToRetrieve, // ['_tags'],
    responseFields: params.responseFields,
  };
  yield fork(runSearch, params.query, searchOptions);
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_TIMELINE, loadTimeline),
  ]);
}
