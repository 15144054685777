import React from "react";
import {AudioIcon, AudioIconFilled} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "antd";
import voiceActions from "@web/redux/background/actions";

function VoiceMuteButton() {
  const dispatch = useDispatch();
  const call = useSelector(state => state.Voice.call);
  const isMuted = call?.isMuted();
  const toggleMute = () => dispatch(voiceActions.toggleMute());
  const onClickMute = () => {
    toggleMute();
  }
  if (!call) return null;
  return <Button className="icon-btn icon-right" disabled={!call} ghost onClick={onClickMute}>{
    isMuted ?
      <AudioIcon size={20} className={(call) ? 'green-icon' : ''} /> :
      <AudioIconFilled size={20} className={(call) ? 'green-icon' : ''} />}
  </Button>
}

export default VoiceMuteButton;