const actions = {
  // LOAD_CONTACTS: "LOAD_CONTACTS",
  LOAD_CONTACTS_SUCCESS: "LOAD_CONTACTS_SUCCESS",
  PAGE_CONTACTS: "PAGE_CONTACTS",
  PAGE_CONTACTS_SUCCESS: "PAGE_CONTACTS_SUCCESS",
  LOAD_TOP_CONTACTS_ACTIVITY: "LOAD_TOP_CONTACTS_ACTIVITY",
  LOAD_TOP_CONTACTS_ACTIVITY_SUCCESS: "LOAD_TOP_CONTACTS_ACTIVITY_SUCCESS",
  LOAD_TOP_CONTACTS_ACTIVITY_ERROR: "LOAD_TOP_CONTACTS_ACTIVITY_ERROR",
  LOAD_GROUP_ACTIVITY_SUCCESS: "LOAD_GROUP_ACTIVITY_SUCCESS",
  SET_ACTIVITY_FILTER: "SET_ACTIVITY_FILTER",
  SELECT_CONTACT: "SELECT_CONTACT",
  loadContacts: () => {
    return (dispatch) => {
      console.log("loadContacts");
      dispatch({
        type: actions.LOAD_CONTACTS,
        params: {},
      });
    };
  },
  // loadTopContactsActivity: (params) => {
  //   return (dispatch) => {
  //     dispatch({
  //       type: actions.LOAD_TOP_CONTACTS_ACTIVITY,
  //       params,
  //     });
  //   };
  // },
  selectContact: (userId, serviceId) => {
    return (dispatch) => {
      dispatch({
        type: actions.SELECT_CONTACT,
        userId,
        serviceId,
      });
    };
  },
  setActivityFilter: (activityFilter) => {
    // console.log("setActivityFilter", activityFilter);
    return (dispatch) => {
      dispatch({
        type: actions.SET_ACTIVITY_FILTER,
        activityFilter,
      });
    };
  },
  // checkStatus: (contactId) => {
      // console.log('checkStatus', contactId);
      // return (dispatch) => {
      //     dispatch({
      //         type: actions.CHECK_CONTACT_STATUS,
      //         params:  {contactId},
      //     });
      // };
  // },
  // selectTopContact: (contactId) => {
  //   return (dispatch, getState) => {
  //     dispatch({
  //       type: actions.SELECT_TOP_CONTACT,
  //       payload: {contactId},
  //     });
  //
  //     dispatch({
  //       type: searchActions.SEARCH_SET_APP_FILTERS,
  //       payload: {tags: [`member:${contactId}`]},
  //     });
  //
  //     /** @type {string} */
  //     const query = getState().Search.query;
  //     dispatch(searchActions.searchMulti(query))
  //   };
  // },
  page: () => {
    return (dispatch, getState) => {
      const params = getState().Activity?.pageParams;
      dispatch({
        type: actions.PAGE_CONTACTS,
        params,
      });
    };
  },
};

export default actions;
