import React from "react";
import PropTypes from "prop-types";
import {Alert, Typography} from "antd";
import AuthLayoutWrapper from "@ai-antd/components/AuthLayoutWrapper";
import useQuerystring from "@web/hooks/useQuerystring";
import {AppContext} from "@web/hooks/context";
import {addDocumentSub, setDocumentSub} from "@web/lib/firestore.db";
import OauthCompletionStatus from "@ai-antd/components/OauthCompletionStatus";
import {notifyAndTrack} from "@ai-antd/components/notification";

const fullWidthStyle = {width: '100%'}

TrelloAuthCallback.propTypes = {
  integrationId: PropTypes.string.isRequired,
};

function TrelloAuthCallback({integrationId}) {
  const [, user] = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const token = location.hash.replace('#token=', '');
  console.log('OauthCallback', {token, integrationId});
  if (!user) return null;
  if (!integrationId) return null;
  if (!token) {
    return notifyAndTrack.warn('Connect failed', 'Unable to complete your request at this time', {token, integrationId});
  }
  React.useEffect(() => {
    addDocumentSub(["user", user.uid, 'providers', integrationId, 'on-authorize'], {token})
      .then(() => setLoading(true));
  }, [token, integrationId, user.uid]);
  return <AuthLayoutWrapper>
    <Typography.Title level={5} style={{margin: '20px 40px 0'}}>Connecting...</Typography.Title>
    <div className="space-col center middle" style={fullWidthStyle}>
      {loading && <OauthCompletionStatus integrationId={integrationId} userId={user.uid} />}
    </div>
  </AuthLayoutWrapper>
}

export default TrelloAuthCallback;