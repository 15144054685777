import {all, call, put, takeEvery} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {Query} from "firebase/firestore";
import {getDocuments, buildQuery} from "@web/lib/firestore.db";
import {firestore as channel, toFirestoreQueryItems} from "../channels";

const queryLimit = 60;

/**
 * @summary Build and return a query
 * @param {object} options
 * @param {number} [options.limit]
 * @param {number|String|FirebaseFirestore.DocumentSnapshot} [options.startAfter]
 * @param {string} options.collectionName
 * @param {string} [options.memberUserId]
 * @return {Query<DocumentData>}
 */
const getDocumentsQuery = ({limit: l = queryLimit, memberUserId, startAfter, collectionName}) => {
  if (!collectionName) {
    throw new Error("collectionName is required");
  }
  console.log("getDocumentsQuery", {l, memberUserId, startAfter, collectionName});
  // return query(
  //   getCollection(["user", auth.currentUser.uid, collectionName]),
  //   where("mimeType", "in", [
  //     "application/pdf",
  //     "image/png",
  //     "application/vnd.google-apps.spreadsheet",
  //     "application/vnd.ms-excel",
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   ]),
  //   where("size", ">", 50000),
  //   memberUserId ?
  //     where("memberUserIds", "array-contains", memberUserId) :
  //     null,
  //   orderBy("size", "desc"),
  //   orderBy("mimeType", "desc"),
  //   limit(l),
  // );
  // const q = collection(getFirestore(), ["user", auth.currentUser.uid, collectionName].join())
  //   .where("mimeType", "in", ["application/pdf", "image/jpeg", "image/png"])
  //   .where("size", ">", 50000)
  //   .orderBy("mimeType")
  //   .orderBy("size", "desc")
  //   .get();
  // getDocs()
  const fields = {
    "in": {
      mimeType: [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/webp",
        "application/vnd.google-apps.spreadsheet",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "audio/x-m4a",
        "text/csv",
        "application/json",
      ]},
    ">": {size: 50000},
  };
  memberUserId && (fields["array-contains"] = {memberUserIds: memberUserId});
  return buildQuery(
    ["user", auth.currentUser.uid, collectionName],
    fields,
    {
      order: [["date", "desc"]],
      limit: l,
    },
    {startAfter},
  );
};

/**
 * @summary sync email
 * @generator
 * @return {Generator<*, void, *>}
 */
function* syncDocuments() {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getDocumentsQuery({limit: 1}),
    success: actions.SYNC_DOCUMENTS_SUCCESS,
  }];
  yield call(channel, listenTo);
}

/**
 * Load files
 * @param {Object} action
 * @param {Object} action.params
 * @return {Generator<*, void, *>}
 */
function* loadFiles({params = {}, memberUserId}) {
  if (!auth.currentUser?.uid) {
    return;
  }
  const filesQuerySnapshot = yield call(getDocuments, getDocumentsQuery({...params, memberUserId, collectionName: "files"}));
  const files = toFirestoreQueryItems(filesQuerySnapshot);
  console.log("files", files);
  const payload = {files};
  memberUserId && (payload.members = {[memberUserId]: files.items});
  yield put({
    type: actions.LOAD_DOCUMENTS_SUCCESS,
    payload,
    pageParams: {
      files: {
        ...params,
        startAfter: filesQuerySnapshot.docs?.[queryLimit - 1]?.date,
      },
    },
  });
}

function* pageDocuments() {

}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DOCUMENTS, loadFiles),
    takeEvery(actions.PAGE_DOCUMENTS, pageDocuments),
  ]);
}
