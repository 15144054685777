import React, {useContext} from "react";
import {Link} from "wouter";
import {AppContext} from "@web/hooks/context";

function LogoutButton({text = "Logout"}) {
  const [, user] = useContext(AppContext);
  if (!user) return null;
  return <Link href="/logout">{text}</Link>;
}

export default LogoutButton;
