import {all, takeEvery, call, put} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {api} from "@web/lib/api";
// import {colls, buildQuery, getDocuments} from "@web/lib/firestore.db";
// import {toFirestoreQueryItems, firestore as channel} from "../channels";

// const queryLimit = 60;

//
// /**
//  * @summary sync email
//  * @generator
//  * @return {Generator<*, void, *>}
//  */
// function* syncMail() {
//   if (!auth.currentUser) return;
//   const listenTo = [{
//     ref: getContactsQuery({limit: 1}),
//     success: actions.SYNC_CONTACTS_SUCCESS,
//   }];
//   yield call(channel, listenTo);
// }

// function loadUserContacts() {
//   return api.call("user-getContacts", {})
// }
function getUsers(userIds) {
  return api.call("user-getUsers", {userIds})
}
//
// /**
//  * Load users
//  * @return {Generator<*, void, *>}
//  */
// function* loadContacts() {
//   console.log("loadContacts saga")
//   if (!auth.currentUser?.uid) return;
//   const response = yield call(loadUserContacts);
//   console.log("loadContacts response", response);
//   yield put({
//     type: actions.LOAD_CONTACTS_SUCCESS,
//     payload: response.data?.activity,
//   });
// }
/**
 * Load users
 * @param {Object} action
 * @param {Object} action.userIds
 * @return {Generator<*, void, *>}
 */
function* loadUsers({userIds = []}) {
  if (!auth.currentUser?.uid) return;
  const users = yield call(getUsers, userIds);
  yield put({
    type: actions.LOAD_USERS_SUCCESS,
    payload: users,
  });
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.LOAD_CONTACTS, loadContacts),
    takeEvery(actions.LOAD_USERS, loadUsers),
  ]);
}
