import {auth, database} from './firebase'
import {push, ref} from 'firebase/database'
import {safeValueForDB} from './firestore.db'
import now from 'lodash/now.js'

/**
 * Track a click and other user router
 * @param {String|MouseEvent<HTMLElement>} e
 * @param {Object|null} data
 * @param {String} [href]
 */
export function track(e, data, href) {
  /**
   * When the click is to an external url, we want to track it,
   * but navigation away from the page prevents the event from being persisted,
   * so we need to prevent the default behavior and then navigate to the url below
   */
  if (href) {
    e?.preventDefault()
    e?.stopPropagation()
  }
  const d = new Date();
  const m = d.getMonth() + 1;
  const y = d.getFullYear();
  const day = d.getDate();
  console.log('hot-click');
  push(ref(database, `timeline/${y}/${m}/${day}`), {
    h: window?.location?.host || null,
    path: window?.location?.pathname || null,
    ot: e?.target?.outerText || null,
    d: safeValueForDB(data),
    ua: auth?.currentUser?.isAnonymous || null,
    uid: auth?.currentUser?.uid || '?',
    unm: auth?.currentUser?.displayName || null,
    e: e?.type || e || '?',
    l: e?.languages || auth?.languageCode && [auth?.languageCode] || null,
    c: auth?.config?.sdkClientVersion || null,
    ts: now(),
  })
    // .then(() => console.log('trackClick', {ev: e, h: window.location.host, path: window.location.pathname, t: e?.target?.outerText, data, uid: auth?.currentUser?.uid, unm: auth?.currentUser?.displayName, e: 'click'}))
    .then(() => href && window.location.assign(href))
}
