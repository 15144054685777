import React from "react";
// import PropTypes from "prop-types";
// import Background from "@ai-antd/components/Background";
import {AppContext, WindowContext} from "@web/hooks/context";
// import ScribeSearch from "./ScribeSearch";
import {Card, Col, Row, Typography} from "antd";
import Logo from "@ai-antd/components/Logo";
// import AppIntegrationsList from "@ai-antd/components/AppIntegrationsList";
// import AppIntegrationsData from "@web/ui/containers/AppIntegrationsData";
import DashboardRow from "@ai-antd/components/DashboardRow";
import useQuerystring from "@web/hooks/useQuerystring";
import AuthSuccess from "@ai-antd/components/AuthSuccess";
import AiStream from "@ai-antd/components/AiStream";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import Loading from "@ai-antd/components/Loading";
import {Redirect} from "wouter";
// import SearchTag from "@ai-antd/components/SearchParam";
// import useQuerystring from "@web/hooks/useQuerystring";
// import OauthConnectButton from "@ai-antd/components/OauthConnectButton";

// const iconStyle = {color: '#FFF'};

Home.propTypes = {};

function Home() {
  const [, user] = React.useContext(AppContext);
  const [isMobile, width, height] = React.useContext(WindowContext);
  const queryString = useQuerystring();
  const [userOnboarding, loadingUserOnboarding, errorLoadingUserOnboarding] = useDocumentFirestore(["user", user.uid, "onboarding", "beta"]);
  if (!user) return null;
  if (user.isAnonymous) return null;

  if (loadingUserOnboarding) {
    return <Loading/>
  }

  if (!userOnboarding) {
    return <Redirect to="/onboarding" />
  }
  const onboardingIncomplete = Object.values(userOnboarding?.boards || {}).some((board) => board.completed !== true)
  if (onboardingIncomplete) {
    return <Redirect to="/onboarding" />
  }

  // if (!claims.data.verified) return null;
  // if (!claims.data.subscribed) return null;
  // const [username, domain] = user.email?.split('@');
  // const businessDomainName = domain?.split('.')[0];
  // const displayName = querystring.demo ? querystring.name : (user.displayName || username || businessDomainName)
  const displayName = user.displayName && user.displayName?.split(" ")?.[0] || user.email?.split("@")?.[0];
  return <section className="beta-home-page" style={{
    width,
    height: "100vh",
    padding: isMobile ? "10px 6px" : "10px",
    position: "relative",
    boxSizing: "border-box",
    backgroundColor: "transparent"
  }}>
    <div style={{display: "flex", flexDirection: "column", height, justifyContent: "center", alignItems: "center"}}>
      <Row style={{width: "100%"}} justify="start">
        <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={6}>
        </Col>
        <Col xs={24} sm={24} md={20} lg={16} xl={16} xxl={12}>
          <div style={{maxWidth: "700px", margin: "0 auto", paddingTop: "5px"}} className="space space-col">
            {queryString["auth-success"] ? <>
              <Typography.Title level={2}>{`Welcome, ${displayName}`}</Typography.Title>
              <Card className="card-simple card-rounded card-no-header" bordered={false}><AuthSuccess/></Card>
            </> : <>
              <Logo name={user.displayName || "Get started"} className="user-display-name" href="/today"/>
              <div style={{height: isMobile ? "6px" : "16px"}}/>
              {/*<Card className="card-simple card-rounded card-no-header" bordered={false}>*/}
              {/*  <AppIntegrationsData filter="all" tags={["email"]}>*/}
              {/*    <AppIntegrationsList/>*/}
              {/*  </AppIntegrationsData>*/}
              {/*</Card>*/}
              <AiStream />
            </>}
          </div>
        </Col>
      </Row>
      <DashboardRow/>
    </div>
  </section>;
}

export default Home;