const actions = {
  LOAD_CONTACTS: "LOAD_CONTACTS",
  LOAD_CONTACTS_SUCCESS: "LOAD_CONTACTS_SUCCESS",
  LOAD_USERS: "LOAD_USERS",
  LOAD_USERS_SUCCESS: "LOAD_USERS_SUCCESS",
  SYNC_USERS: "SYNC_USERS",
  SYNC_USERS_SUCCESS: "SYNC_USERS_SUCCESS",
  loadContacts: () => {
    return (dispatch) => {
      console.log("loadContacts")
      dispatch({
        type: actions.LOAD_CONTACTS,
      });
    };
  },
  loadUsers: ({userIds}) => {
    return (dispatch) => {
        console.log("loadUsers")
      dispatch({
        type: actions.LOAD_USERS,
        userIds,
      });
    };
  },
};

export default actions;
