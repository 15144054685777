import React, {useCallback} from "react";
import {List, Tag} from "antd";
import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
// import ExternalLink from "./ExternalLink";
// import useDomainIcon from "@web/hooks/useDomainIcon";
import ListItemActive from "./ListItemActive";
import MarkdownListItem from "./MarkdownListItem";
// import LineConnector from "./LineConnector";
// import isNumber from "lodash/isNumber";

function PaymentListItem(props) {
  const dispatch =
    useDispatch();

  const isMobile = true;

  const payment =
    useSelector((state) => {
      return state.Content?.data?.[props.path];
    });

  // console.log('PaymentListItem:payment:', payment);

  // const DomainIcon =
  //   useDomainIcon(
  //     contact?.domain,
  //     contact?.indicators,
  //   );

  const setActiveDetail =
    useCallback((serviceId, props, show) =>
        dispatch(
          appActions
          .setActiveDetail(serviceId, props, show)),
      [dispatch],
    );

  if (!payment) {
    return null
  }

  if (!payment.card) {
    return <MarkdownListItem
      key={props.path}
      path={props.path}
      date={props.date}
      dataKey="payment"
    />
  }

  const membersList =
    Object.entries(payment.members);

  const [label, ...labels] =
    payment.card?.labels || [];

  // console.log('PaymentListItem:subtitle:number:', payment.card?.subtitle?.replace(/[$,]/, ""));
  const amount = +payment.card?.subtitle?.replaceAll(/[$,]/g, "")?.trim();
  // console.log('PaymentListItem:subtitle:amount:isFinite:', isFinite(amount));

  const subtitle =
    isFinite(amount) ?
      `$${amount}` :
      payment.card.subtitle;

  return <List.Item
    key={props.path}
    className={`relative list-item-lite-divider contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[
      <RightArrowOutlined
        className="muted-icon hidden"
        size={20}
      />
    ]}
    onClick={() =>
      setActiveDetail(
        "payments", {
          path: props.path,
          payment,
        }, true)}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={null}
          userId={membersList[0][0].split("/").pop()}
          name={membersList[0][1].displayName}
          displayName={membersList[0][1].displayName}
          src={""}
        />}
      title={
        <div className="space space-center-y">
          {/*<h6 className="capitalize">*/}
          <span>
            {membersList[0][1].displayName}
          </span>
          {/*</h6>*/}
          <span key="ts" className="muted font-sm">
            <Day ts={payment.updatedTs} format="MMM D"/>
          </span>
        </div>}
      description={
        <div className="space space-col">
          <div className="space space-center-y">
            <span className="ai-text">{payment.card.title} {subtitle}</span>
            {/*<LineConnector />*/}
            {/*<h6 style={{lineHeight: '1.2', marginBottom: '5px'}} className="ai-text">*/}
            {/*  {payment.card.subtitle}*/}
            {/*</h6>*/}
          </div>
          <div>
            <Tag className="muted">{label}</Tag>
            {/*{labels*/}
            {/*.sort((a, b) => a.length - b.length)*/}
            {/*.slice(0, 1)*/}
            {/*.map((label) =>*/}
            {/*  <Tag key={label} className="muted">{label}</Tag>*/}
            {/*)}*/}
          </div>
        </div>}
    />
    <ListItemActive
      path={props.path}
      offset={'5px'}
    />
  </List.Item>
}

export default PaymentListItem;
