const actions = {
  VOICE_DEVICE_REGISTER: "VOICE_DEVICE_REGISTER",
  VOICE_DEVICE_REGISTERED: "VOICE_DEVICE_REGISTERED",
  VOICE_DEVICE_REGISTERING: "VOICE_DEVICE_REGISTERING",
  VOICE_DEVICE_UNREGISTERED: "VOICE_DEVICE_UNREGISTERED",
  VOICE_DEVICE_INCOMING: "VOICE_DEVICE_INCOMING",
  VOICE_DEVICE_ERROR: "VOICE_DEVICE_ERROR",
  VOICE_CALL_ACCEPTED: "VOICE_CALL_ACCEPTED",
  VOICE_CALL_CANCELLED: "VOICE_CALL_CANCELLED",
  VOICE_CALL_IGNORED: "VOICE_CALL_IGNORED",
  VOICE_CALL_TOGGLE_MUTED: "VOICE_CALL_TOGGLE_MUTED",
  VOICE_CALL_MUTED: "VOICE_CALL_MUTED",
  VOICE_CALL_REJECTED: "VOICE_CALL_REJECTED",
  VOICE_CALL_RINGING: "VOICE_CALL_RINGING",
  VOICE_CALL_CONNECTING: "VOICE_CALL_CONNECTING",
  VOICE_CALL_CONNECT: "VOICE_CALL_CONNECT",
  VOICE_CALL_CONNECTED: "VOICE_CALL_CONNECTED",
  VOICE_CALL_DISCONNECT_ALL: "VOICE_CALL_DISCONNECT_ALL",
  VOICE_CALL_DISCONNECTING: "VOICE_CALL_DISCONNECTING",
  VOICE_CALL_DISCONNECTED: "VOICE_CALL_DISCONNECTED",
  VOICE_CALL_RECONNECTING: "VOICE_CALL_RECONNECTING",
  VOICE_CALL_RECONNECTED: "VOICE_CALL_RECONNECTED",
  // VOICE_CALL_CONNECT_EVENT_CHANGE: "VOICE_CALL_CONNECT_EVENT_CHANGE",
  // VOICE_CALL_DEVICE_EVENT_CHANGE: "VOICE_CALL_DEVICE_EVENT_CHANGE",
  VOICE_CALL_TOGGLE_RECORD: "VOICE_CALL_TOGGLE_RECORD",
  VOICE_CALL_WARNING: "VOICE_CALL_WARNING",
  VOICE_CALL_WARNING_CLEARED: "VOICE_CALL_WARNING_CLEARED",
  VOICE_CALL_ERROR: "VOICE_CALL_ERROR",
  register: () => {
    console.log("voiceActions.register")
    return (dispatch) => {
      // dispatch({
      //   type: actions.VOICE_DEVICE_REGISTERING,
      // });
      dispatch({
        type: actions.VOICE_DEVICE_REGISTER,
      });
    };
  },
  connectCall: (params) => {
    // console.log("voiceActions.connectCall")
    return (dispatch, getState) => {
      const record = getState().Voice.record;
      dispatch({
        type: actions.VOICE_CALL_CONNECT,
        params: {
          ...params,
          options: {
            record,
          },
        },
      });
    };
  },
  disconnectAll: (params) => {
    return (dispatch) => {
      dispatch({
        type: actions.VOICE_CALL_DISCONNECT_ALL,
        params,
      });
    };
  },
  toggleMute: () => {
    return (dispatch, getState) => {
      const call = getState().Voice.call;
      dispatch({
        type: actions.VOICE_CALL_TOGGLE_MUTED,
        call,
      });
    };
  },
  toggleRecord: () => {
    return (dispatch) => {
      dispatch({
        type: actions.VOICE_CALL_TOGGLE_RECORD,
      });
    };
  }
};

export default actions;
