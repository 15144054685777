import React from "react";
import * as Icons from "@web/assets/icons";
import {Brainstorm} from "@web/assets/icons";

const defaultClasses = "app-thescribe-ai-icon ";
/**
 * @summary Render an icon from the @web/assets/icons library
 * @param name
 * @param props
 * @param className
 * @return {JSX.Element}
 * @constructor
 */
export default function Icon({name, className, ...props}) {
  if (!name) return <Brainstorm className={className}/>;
  const TheIcon = Icons[name];
  return TheIcon ? <TheIcon {...props} className={defaultClasses + " " + className}/> :
    <Brainstorm className={className}/>;
}
