import React, {useCallback, useContext} from "react";
// import {UploadResult} from "firebase/storage";
import {Alert, Button, Card, Col, Empty, List, Row, Typography} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {buildQuery} from "@web/lib/firestore.db";
import Loading from "./Loading";
import {RightArrowOutlined} from "@web/assets/icons";
// import CompletionsStatusIndicator from "./CompletionsStatusIndicator";
import startCase from "lodash/startCase";
import appActions from "@web/redux/app/actions";
import {useDispatch} from "react-redux";
import Day from "./Day";
import ListItemActive from "./ListItemActive";
import GroupTitle from "./GroupTitle";

Datasets.propTypes = {};

function Datasets() {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);
  const [,, height] = useContext(WindowContext);
  const [pairs, dataLoading, error] =
    useQueryFirestore(user.uid && buildQuery(
    ["user", user.uid, "datasets"],
    {},
      {order: [["updatedTs", "desc"]], limit: 20}
  ));

  const setActiveDetail = useCallback((serviceId, props, show) =>
    dispatch(appActions.setActiveDetail(serviceId, props, show)),
    [dispatch]);

  if (!user?.uid) {
    return null;
  }
  if (error) {
    return <Alert
      message={error.message}
      type="error"
      showIcon
      closable
    />;
  }
  if (dataLoading) {
    return <Loading/>;
  }

  return <React.Fragment>
    <Row style={{width: "100%", boxSizing: "border-box"}}>
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Card
        className="card-rounded card-simple card-transparent"
        bordered={false} style={{minHeight: `${height * 0.55}px`}}
      >
        <List
          header={<GroupTitle title="Datasets" />}
          className="list-item-no-divider list-empty-no-padding pagination-left list-item-relative"
          dataSource={pairs}
          bordered={false}
          renderItem={(dataItem) =>
            <List.Item
              key={dataItem[0]}
              onClick={() =>
                setActiveDetail(
                  "datasets",
                  {path: dataItem[2], data: dataItem[1]},
                  true,
                )}
              actions={[<RightArrowOutlined className="muted-icon" />]}
            >
              <ListItemActive path={dataItem[2]} />
              <List.Item.Meta
                title={
                  <div className="space space-center-y">
                    <h6 className="capitalize">
                      {startCase(dataItem[1].name)}
                    </h6>
                    <span className="font-sm muted">
                    <Day ts={dataItem[1].createdTs} format="MMM DD"/>
                  </span>
                  </div>}
                description={<span className="ai-text">{dataItem[1].description}</span>}>
              </List.Item.Meta>
              {/*<div className="space space-col">*/}
              {/*  <div*/}
              {/*    className="space space-between space-center-y"*/}
              {/*    onClick={() => {*/}
              {/*      setActiveDetail(*/}
              {/*        "datasets",*/}
              {/*        {path: dataItem[2], data: dataItem[1]},*/}
              {/*        true);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <span className="ai-text bolded">{startCase(dataItem[1].name)}</span>*/}
              {/*    <div>*/}
              {/*      <span className="font-sm uppercase muted">*/}
              {/*        <Day ts={dataItem[1].createdTs} format="MMM DD"/>*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <span className="muted">{dataItem[1].description}</span>*/}
              {/*</div>*/}
            </List.Item>
          }
          loading={dataLoading}
          pagination={{
            pageSize: 30,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          locale={{
            emptyText: <Empty
              description={null}
              image={null}
              imageStyle={{display: "none"}}
              style={{maxWidth: "600px"}}
            >
              <Card className="card-simple card-rounded card-no-header" bordered={false}>
                <Typography.Text className="muted">You have no datasets</Typography.Text>
              </Card>
            </Empty>
          }}
        />
      </Card>
    </Col>
    </Row>
  </React.Fragment>
}

export default Datasets;
