import React from 'react';
import PropTypes from 'prop-types';
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {buildQuery} from "@web/lib/firestore.db";

DatasetData.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  preview: PropTypes.bool,
  width: PropTypes.number,
  bordered: PropTypes.bool,
};

function DatasetData(props) {
  const [dataset] = useDocumentFirestore(props.path);
  const [sampleRecords] = useQueryFirestore(
    buildQuery([props.path, "sample-records"],
      {},
      {limit: 10}),
  );

  return React.createElement(
    props.component,
    {
      dataset,
      sampleRecords: sampleRecords.map(([key, record]) => {
        return {
          key,
          ...record,
        };
      }),
      path: props.path,
      preview: props.preview,
      width: props.width,
      bordered: props.bordered,
    });
}

export default DatasetData;
