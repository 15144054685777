import React, {useContext} from "react";
import {Button, List, Divider, Affix} from "antd";
import {AppContext, TimelineContext} from "@web/hooks/context";
import {XIcon} from "@web/assets/icons";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import Loading from "../Loading";
import DetailTimelineDay from "./DetailTimelineDay";
import HashPager from "../HashPager";
import MembersList from "../MembersList";

DetailTimeline.propTypes = {
  path: PropTypes.string,
  item: PropTypes.object,
  daysList: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  errorLoading: PropTypes.bool,
  onClose: PropTypes.func,
}

function DetailTimeline(props) {
  const [, user] =
    useContext(AppContext);

  const sortedDaysList =
    props.daysList ?
      props.daysList
      .sort()
      .map((dateKey) => ({
        label: dayjs(dateKey).format("MMMM DD"),
        key: dateKey,
      })) :
      [];

  if (!user?.uid) {
    return null
  }

  if (props.loading) {
    return <Loading/>;
  }

  return <div
    className="space space-col"
    style={{width: '100%', maxWidth: '100%', margin: '0 auto'}}
  >
    <TimelineContext.Provider value={{filter: []}}>
      <Affix
        offsetTop={0}
        target={() =>
          document
          .getElementById("dashboard-detail-wrapper")}
      >
        <HashPager
          direction="horizontal"
          list={sortedDaysList}
          style={{height: '60px', width: '100%'}}
          prefix={<Button
            ghost
            size="large"
            className="icon-btn icon-left"
            onClick={props.onClose}
          >
            <XIcon className="green-icon" />
          </Button>}
        />
      </Affix>
      <Divider className="small no-top" />
      <MembersList
        itemPath={props.path}
        minCount={1}
      />
      <List
        className="list-item-no-divider"
        dataSource={sortedDaysList}
        renderItem={({key: dateKey}, index) => {
          return <List.Item
            id={dateKey}
            key={dateKey + index}
          >
            <DetailTimelineDay
              path={props.path}
              dateKey={dateKey}
              dayIndex={index}
              collapsed={index < (sortedDaysList.length - 1)}
            />
          </List.Item>
        }}
      />
    </TimelineContext.Provider>
  </div>
}

export default DetailTimeline;
