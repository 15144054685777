import React from "react";
// import {useRemark} from "react-remark";
import remarkGfm from 'remark-gfm'
import PropTypes from "prop-types";
// import {Typography} from "antd";
// import OauthConnectButton from "./OauthConnectButton";
// import DraftEditor from "./DraftEditor";
import {Link} from "wouter";
import querystring from "query-string";
// import VideoLink from "./VideoLink";
import {track} from "@web/lib/analytics";
import remarkParse from "remark-parse";
import {Space, Timeline, Typography} from "antd";
import Todo from "./Todo";
import {LineOutlined} from "@ant-design/icons";
import ContactCard from "./ContactCard";
import ContactSelect from "./ContactSelect";
import VoiceCallButton from "./VoiceCallButton";
import OauthConnectButton from "./OauthConnectButton";
import DraftEditorManager from "./DraftEditorManager";
import UserContentPreview from "./UserContentPreview";
import ExternalLink from "./ExternalLink";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import Toggle from "./Toggle";
// import rehypeHighlight from 'rehype-highlight'
// import 'highlight.js/styles/atom-one-dark.css'

Markdown.propTypes = {
  markdown: PropTypes.string.isRequired,
  action: PropTypes.node,
  collapsible: PropTypes.object,
  collapsed: PropTypes.bool,
  components: PropTypes.object,
  style: PropTypes.object,
};

function Markdown(props) {
  const handleExternalLinkClick = (url, e) => {
    track(e, {chatId: props.chatId, url, ...props});
    e.stopPropagation();
  };
  const componentOverrides = {
    ul: (props) => {
      return <Timeline className="markdown-timeline-wrapper ul">{props.children.filter((child) => typeof child !== 'string')}</Timeline>
    },
    ol: (props) => {
      return <Space direction="vertical">
        <Timeline className="markdown-timeline-wrapper ol" mode="left">
          {props.children.filter((child) => typeof child !== 'string').map((child) =>
            React.cloneElement(child, {timeline: true})
          )}
        </Timeline>
      </Space>
    },
    li: (props) => {
      return props.timeline ?
        <Timeline.Item className={props.className} color={isActive ? 'blue' : 'gray'}>
          <Todo label="Step" type="step">{props.children}</Todo>
        </Timeline.Item> :
        <Timeline.Item className={props.className} dot={<LineOutlined />} color={isActive ? 'blue' : 'gray'}>
          <Todo label="Info" type="info">{props.children}</Todo>
        </Timeline.Item>
    },
    a: (props) => {
      switch (true) {
        case props.href.startsWith("mailto"):
          return <a className="ai-text underline" href={props.href}>{props.children}</a>
        case props.href.startsWith("http"):
          return <a className="ai-text underline" href={props.href} onClick={(e) => handleExternalLinkClick(props.href, e)} target="_blank">{props.children}</a>
        case props.href.startsWith("/"):
          return <Link href={props.href}>{props.children}</Link>
        case props.href.startsWith("#"): {
          const [componentName, queryParams] = props.href.split("?");
          const componentProps = querystring.parse(queryParams, {
            parseBooleans: true,
            parseNumbers: true,
          });

          switch(componentName) {
            case "#contact-card":
              return <ContactCard action={props.children} {...componentProps} />
            case "#contact-select":
              return <ContactSelect action={props.children} {...componentProps} />
            case "#voice-call":
              return <VoiceCallButton inline {...componentProps}>{props.children}</VoiceCallButton>
            case "#oauth":
              return <OauthConnectButton {...componentProps}>{props.children}</OauthConnectButton>
            case "#email-editor":
              return <div style={{display: "flex", width: "100%", maxWidth: "990px"}} className="card-content-wrapper">
                <DraftEditorManager
                  // draftPath={path}
                  // index={answerIndex}
                  // actionLabel={props.children}
                  // action={componentProps.action}
                  {...componentProps}
                  // style={{marginTop: '10px'}}
                />
              </div>
            case "#source-link":
              return <UserContentPreview {...componentProps} href={props.href}>{props.children}</UserContentPreview>
            default:
              if (component === props.href) { // plain content path, no query params
                return <UserContentPreview path={props.href.split('#').pop()} href={props.href}>
                  {props.children}
                </UserContentPreview>
              }
              return <a href={props.href}>{props.children}</a>
          }
        }
        default:
          return <ExternalLink className="ai-text underline" href={props.href}>{props.children}</ExternalLink>
      }
    },
    pre: (props) => {
      return <pre className="markdown-pre">{props.children}</pre>
    },
    code: (props) => {
      return <Typography.Text className="font-lg muted">{props.children}</Typography.Text>
    },
    ...(props.components || null),
  };
  const markdownContent = <ReactMarkdown
    children={props.markdown}
    remarkPlugins={[
      remarkGfm,
      remarkBreaks,
      remarkParse
    ]}
    // components={componentOverrides}
  />
  const isActive = props.active
  return <div className="muted relative flex-col middle markdown-timeline-content font-lg" style={props.style || {minHeight: 50}}>
    {props.extra ?
      <div style={{height: 20}}>
        <div style={{width: 40, position: "absolute", top: -10, right: 0}}>{props.extra}</div>
      </div> :
      null
    }
    {!!props.collapsible ?
      <Toggle defaultExpanded={!props.collapsible?.rows} rows={props.collapsible?.rows} trigger={<Link href="#">Expand</Link>}>
        {markdownContent}
      </Toggle> :
      markdownContent
    }
    {!!props.action && !!props.action?.length && <div className="space space-center-y">{props.action}</div>}
  </div>;
}

export default Markdown;
