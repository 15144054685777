import {all, takeEvery, call, put} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import AlgoliaIndex from "@web/lib/algolia.db";
import {buildQuery, colls, getDocuments} from "@web/lib/firestore.db";
import {toFirestoreQueryItems} from "../channels";

/**
 * @type {{router: AlgoliaIndex}}
 */
const indexes = {
  // router: new AlgoliaIndex(AlgoliaIndex.NAMES.SUMMARY),
  timeline: new AlgoliaIndex(AlgoliaIndex.NAMES.TIMELINE),
};

// /**
//  * @summary Get payload
//  * @param {SearchQueryResults | Error} searchQueryResults
//  * @returns {SearchQueryResults}
//  */
// const getPayload = (searchQueryResults) => {
//   const error = searchQueryResults instanceof Error ? searchQueryResults : null;
//   const payload = searchQueryResults instanceof Error ? {} : searchQueryResults;
//   return {error, ...payload}
// }

/**
 * @summary Search timeline index
 * @param {string} query
 * @param {SearchOptions | null} [searchOptions]
 * @return {Promise<SearchQueryResults | Error>}
 */
function getFeed(query, searchOptions = null) {
  return indexes.timeline.search(query, {
    facets: ['*'],
    hitsPerPage: 20,
    ...searchOptions,
  });
}

/**
 * @summary Get object by id
 * @param {string} objectId
 * @returns {Promise<SearchQueryResults|Error>}
 */
const getObject = (objectId) =>
  indexes.timeline.getObjectById(objectId);

// /**
//  * @summary Load timeline data
//  * @generator
//  * @return {Generator<*, void, *>}
//  */
// function* loadFeed() {
//   const userId = auth.currentUser.uid;
//   if (!userId) return;
//   const searchQueryResults = yield call(getFeed, "", {tagFilters:[userId]});
//   yield put({
//     type: actions.LOAD_FEED_SUCCESS,
//     payload: searchQueryResults,
//   });
// }
const pageSize = 40;
/**
 * @summary Build and return a query
 * @param {Number} [limit]
 * @param {Number|String|FirebaseFirestore.DocumentSnapshot} [startAfter]
 * @return {Query<DocumentData>}
 */
const getThreadsQuery = ({limit = pageSize, startAfter}) => {
  return buildQuery(
    ["user", auth.currentUser.uid, "activity"],
    {}, {order: [['date', 'desc']], limit},
    {startAfter},
  );
};
function* loadActivity({params = {}, type}) {
  const userId = auth.currentUser.uid;
  if (!userId) return;
  // if (!auth.currentUser?.uid) return;
  // if (params.startAfter === null) {
  //   console.log("loadMail", "no more threads");
  //   return;
  // }
  const querySnapshot = yield call(getDocuments, getThreadsQuery(params));
  const activity = toFirestoreQueryItems(querySnapshot);
  // console.log("loadActivity", {activity});
  yield put({
    type: actions.LOAD_ACTIVITY_SUCCESS,
    payload: {activity},
    pageParams: {
      ...params,
      startAfter: querySnapshot.docs?.[pageSize - 1] || null,
    },
  });
  // delay(5000);
  // yield call(syncMail, params);
  // const searchQueryResults = yield call(getFeed, "", {tagFilters:[userId]});
  // yield put({
  //   type: actions.LOAD_FEED_SUCCESS,
  //   payload: searchQueryResults,
  // });
}

/**
 * @summary Search timeline data
 * @generator
 * @return {Generator<*, void, *>}
 */
function* loadItemEvents({path}) {
  const userId = auth.currentUser.uid;
  if (!userId) return;
  const querySnapshot = yield call(getDocuments, buildQuery([path, "members"], {}));
  const memberQueryItems = toFirestoreQueryItems(querySnapshot);
  const tagFilters = memberQueryItems.items.map(({id: userId}) => `member:${userId}`);

  if (!tagFilters.length) return;

  /** @type {SearchQueryResults} */
  const searchQueryResults = yield call(getFeed, "", {
    tagFilters: [userId, ...tagFilters],
  });

  yield put({
    type: actions.LOAD_ITEM_EVENTS_SUCCESS,
    payload: {
      ...searchQueryResults, path, members: memberQueryItems, tagFilters},
  });
}

/**
 * @summary Page feed data
 * @generator
 * @return {Generator<*, void, *>}
 */
function* pageFeed({page}) {
  const userId = auth.currentUser.uid;
  if (!userId) return;
  const searchQueryResults = yield call(getFeed, "", {page, tagFilters: [userId]});
  yield put({
    type: actions.PAGE_FEED_SUCCESS,
    payload: searchQueryResults,
  });
}

function* loadEvent({eventId}) {
  const userId = auth.currentUser.uid;
  if (!userId) return;
  const searchQueryResults = yield call(getObject, eventId);
  yield put({
    type: actions.LOAD_EVENT_SUCCESS,
    payload: searchQueryResults,
    // eventId,
  });
}
function* loadUrgent() {
  const userId = auth.currentUser.uid;
  if (!userId) return;

  const searchOptions = {
    filters: "event.isUrgent:true",
    hitsPerPage: 5,
    tagFilters: [userId],
  };
  const searchQueryResults = yield call(getFeed, "", searchOptions);

  yield put({
    type: actions.LOAD_URGENT_SUCCESS,
    payload: searchQueryResults,
  });
}

export default function* rootSaga() {
  yield all([
    // takeEvery(actions.LOAD_ACTIVITY, loadActivity),
    // takeEvery(actions.LOAD_FEED, loadFeed),
    takeEvery(actions.PAGE_FEED, pageFeed),
    takeEvery(actions.LOAD_EVENT, loadEvent),
    takeEvery(actions.LOAD_URGENT, loadUrgent),
    takeEvery(actions.LOAD_ITEM_EVENTS, loadItemEvents),
  ]);
}
