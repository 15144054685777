import React, {useContext} from "react";
import {AppContext} from "@web/hooks/context";
import Splash from "@ai-antd/components/Splash";
import Home from "./Home";
import RequireVerification from "@web/ui/containers/RequireVerification";

export default function HomeWrapper() {
  const [, user] = useContext(AppContext);
  return (user && !user.isAnonymous) ?
    <RequireVerification><Home/></RequireVerification> :
    <Splash/>;
}
