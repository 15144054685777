/** @namespace clients.algolia */
import search, {SearchIndex, SearchClient, SearchOptions, SearchResponse, DeleteResponse, ChunkedBatchResponse, SaveObjectResponse, PartialUpdateObjectsOptions, PartialUpdateObjectResponse} from "algoliasearch";
const client = search(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);

// /** @typedef {import("algoliasearch")} clients.algolia.AlgoliaSearch */

/**
 * @typedef {Object} SearchQueryResults
 * @property {Object} data
 * @property {SearchQueryItem[]} list
 * @property {number} speed
 * @property {string} query
 * @property {string} indexName
 * @property {number} [nbHits]
 * @property {Object} [facets]
 * @property {number} [nextPage]
 */

/**
 * @typedef {Object} SearchQueryItem
 * @property {string} key
 * @property {string} serviceId
 * @property {string} path
 * @property {string} indexName
 * @property {number} score
 * @property {string} threadId
 * @property {number} date
 */

const services = {
  'summary': 'feed',
  'timeline': 'feed', // todo Change to timeline value
  'messages': 'threads',
  'contacts': 'contacts',
  'attachments': 'documents',
  'trello': 'trello',
}
const computeScore = () => {
  return 1;
}

const keyField = {
  'summary': 'eventId',
  'timeline': 'eventId',
  'messages': 'messageId',
  'contacts': 'contactId',
  'attachments': 'mediaId',
  'trello': 'trelloId',
  'attachments_by_date': 'mediaId',
}

/**
 * @summary Convert a list of records to a map  keyed by objectID
 * @function toSearchQueryResults
 * @param {SearchResponse} searchResponse
 * @param {boolean} [extractContacts]
 * @returns {SearchQueryResults}
 */
const toSearchQueryResults = (searchResponse, extractContacts = false) => {
  console.log("toSearchQueryResults", searchResponse);
  const {hits, page, results, query, processingTimeMS: processingMS, serverTimeMS: serverMS, facets, index: indexName, nbHits} = searchResponse;
  const records = hits || results;
  const searchQueryResults = records.reduce(($acc, record) => {
    if (!record?.objectID) return $acc;
    const {objectID, ...hit} = record;
    $acc.data[objectID] = hit;
    $acc.list.push({
      key: objectID,
      [keyField[indexName]]: objectID,
      serviceId: services[indexName],
      path: hit.path,
      indexName,
      score: computeScore(hit),
      threadId: hit.threadId,
      date: hit.date,
    });
    $acc.speed = Math.max($acc.speed, processingMS + serverMS);
    return $acc;
  }, {data: {}, list: [], speed: 0, query, indexName, nbHits, nextPage: (page || 0) + 1});
  if (facets) searchQueryResults.facets = facets;
  return searchQueryResults;
};

/**
 * @class clients.algolia.AlgoliaIndex
 * @property {SearchIndex} index
 */
export default class AlgoliaIndex {
  /**
   * @summary Constructor
   * @param {string} indexName
   */
  constructor(indexName) {
    this.index = client.initIndex(indexName);
    this.search = this.search.bind(this);
  }

  /**
   * @summary Handle search response error
   * @param {Error} error
   * @return {{error: Error}}
   */
  #toSearchResponseError(error) {
    console.log("An error occurred searching index", error);
    return {error};
  }

  /**
   * @summary Execute a search of this index with query
   * @param {String} query
   * @param {SearchOptions} searchOptions
   * @return {Promise<SearchResponse>}
   */
  #indexSearch = async (query, searchOptions = null) => {
    // if (this.index.indexName === "timeline") console.log("searchOptions", searchOptions);
    return this.index.search(query, searchOptions)
      .catch(this.#toSearchResponseError);
  }

  /**
   * @summary Search index
   * @param {String} query
   * @param {SearchOptions} searchOptions
   * @returns {Promise<SearchQueryResults | Error>}
   */
  async search(query, searchOptions) {
    return this.#indexSearch(query, searchOptions)
      .then((searchResponse) =>
        toSearchQueryResults({
          ...searchResponse,
          index: this.index.indexName,
        }))
      .catch(this.#toSearchResponseError);
  }
  // test() {
  //   this.search("test", {
  //     hitsPerPage: 1,
  //     page: 0,
  //     restrictSearchableAttributes: [
  //       'attribute'
  //     ],
  //     attributesToRetrieve: [],
  //     attributesToHighlight: [],
  //     attributesToSnippet: [],
  //     relevancyStrictness: 90,
  //     highlightPreTag: "",
  //     highlightPostTag: "",
  //     snippetEllipsisText: "",
  //     restrictHighlightAndSnippetArrays: false,
  //     responseFields: "*",
  //     facets: [],
  //     filters: "",
  //     facetFilters: [],
  //     maxValuesPerFacet: 100,
  //     facetingAfterDistinct: false,
  //     sortFacetValuesBy: "count",
  //     aroundLatLng: "",
  //     aroundLatLngViaIP: false,
  //     aroundRadius: "",
  //     aroundPrecision: 1,
  //     minimumAroundRadius: "",
  //     insideBoundingBox: [],
  //     queryType: "prefixLast",
  //     insidePolygon: [],
  //     ignorePlurals: false,
  //     removeStopWords: false,
  //     advancedSyntax: false,
  //     optionalWords: [],
  //     removeWordsIfNoResults: "none",
  //     disableExactOnAttributes: [],
  //     exactOnSingleWordQuery: "attribute",
  //     alternativesAsExact: ["ignorePlurals", "singleWordSynonym"],
  //     numericFilters: [],
  //     tagFilters: [],
  //   })
  // }

  /**
   * @summary Get index records by objectID
   * @param {string[]} objectIds
   * @returns {Promise<SearchQueryResults[] | Error>}
   */
  async getObjectsByIds(objectIds) {
    return this.index.getObjects(objectIds)
      .then((response) => toSearchQueryResults(response))
      .catch(this.#toSearchResponseError);
  }

  /**
   * @summary Get object by ID
   * @param {string} objectId
   * @returns {Promise<SearchQueryResults | Error>}
   */
  async getObjectById(objectId) {
    return this.index.getObject(objectId)
      .then((response) => toSearchQueryResults({results: [response]}))
      .catch(this.#toSearchResponseError);
  }

  /**
   * @summary Add/update a index document
   * @param {String} objectID
   * @param {Object} updates
   * @param {PartialUpdateObjectsOptions} [options]
   * @return {Promise<PartialUpdateObjectResponse | Error>}
   */
  async updateDocument(objectID, updates, options) {
    if (!objectID) {
      throw new Error("Missing objectID");
    }
    return this.index.partialUpdateObject({...updates, objectID}, {createIfNotExists: true})
      .then((partialUpdateObjectResponse) => partialUpdateObjectResponse)
      .catch((error) => {
        console.error("Error updating document", error);
        return error;
      });
  }

  /**
   * @summary Add/update a index document
   * @param {String} objectID
   * @param {Object} data
   * @return {Promise<SaveObjectResponse | Error>}
   */
  async saveDocument(objectID, data) {
    if (!objectID) {
      throw new Error("Missing objectID");
    }
    await this.index.saveObject({...data, objectID})
      .then((savedObjectResponse) => savedObjectResponse)
      .catch((error) => {
        console.error("Error saving document", error);
        return error;
      });
  }

  /**
   * @summary Update multiple files
   * @param {[String, Object][]} dataList
   * @return {Promise<Readonly<ChunkedBatchResponse> | Error>}
   */
  async saveDocuments(dataList) {
    if (!dataList.every((docItem) => !!docItem[0])) throw new Error("A document is missing objectID");
    const documents = dataList.map(([objectID, data]) => ({...data, objectID}));
    return this.index.saveObjects(documents)
      .then((chunkedBatchResponse) => chunkedBatchResponse)
      .catch((err) => {
        console.error("Error saving files", err);
        return err;
      });
  }

  /**
   * @summary Remove object from index
   * @param {string} objectID
   * @return {Promise<DeleteResponse | Error>}
   */
  async remove(objectID) {
    return this.index.deleteObject(objectID)
      .then((deleteResponse) => deleteResponse)
      .catch((err) => {
        console.error("Error deleting files", err);
        return err;
      });
  }

  static NAMES = {
    MESSAGES: "messages",
    FILES: "files",
    // ATTACHMENTS: "attachments",
    CONTACTS: "contacts",
    SUMMARY: "summary",
    TIMELINE: "timeline",
    TRELLO: "trello",
  };

  /**
   * @summary Search app indexes
   * @function searchApp
   * @param {string} query
   * @param {[string, object, string][]} searchRequests
   * @returns {Promise<{<string, SearchQueryResults>[]>}
   */
  static async searchApp(query, searchRequests) {
    if (!searchRequests.length) return [];
    return client.multipleQueries(searchRequests.map(([_, searchOptions = null, indexName]) => {
      return {
        indexName,
        query,
        params: {
          hitsPerPage: 5,
          page: 0,
          typoTolerance: false,
          // attributesToHighlight: [],
          // attributesToSnippet: [],
          // highlightPreTag: "",
          // highlightPostTag: "",
          restrictHighlightAndSnippetArrays: true,
          // removeWordsIfNoResults: "none",
          // snippetEllipsisText: "...",
          // attributesToRetrieve: [],
          // responseFields: "*",
          facets: ['_tags'],
          responseFields: [
            'hits', 'hitsPerPage', 'nbPages', 'page', 'query', 'processingTimeMS', 'serverTimeMS', 'facets', 'index', 'nbHits'
          ],
          relevancyStrictness: 90,
          ...searchOptions,
        },
      }
      }), {strategy: "none"})
      .then(({results = []}) => {
        return results.reduce(($acc, searchResponse, currentIndex) => {
          const [serviceId] = searchRequests[currentIndex];
          $acc[serviceId] = toSearchQueryResults(searchResponse, true);
          return $acc;
        }, {});
      });
  }
}
