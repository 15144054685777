import {Progress} from 'antd'
import React, {useState} from 'react'
let timer

export const ProgressBar = ({visible, loop = true, steps = 10, float = true, color}) => {
  const [percent, setPercent] = useState(0)
  if (!visible) {
    clearInterval(timer)
    return null
  }
  if (loop) {
    clearInterval(timer)
    timer = setInterval(() => {
      setPercent(last => {
        return ((last + (100 / steps)) % 100)
      })
    }, 200)
  }
  const className = float ?
    'floating-progress-bar' :
    'static-progress-bar';

  return <Progress
    percent={percent}
    steps={steps}
    size="small"
    status="normal"
    showInfo={false}
    className={className}
    strokeColor={color}
    strokeWidth={20}
    // style={{width: 200}}
  />
}
