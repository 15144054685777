import {ref, getStorage, uploadBytes, getDownloadURL, uploadString, connectStorageEmulator, UploadResult, StorageReference, uploadBytesResumable, UploadTask, TaskEvent} from 'firebase/storage'
import {firebaseApp} from './firebase'
// import ChatUploader from "@ai-antd/components/ChatUploader";

/** @typedef {StorageReference} StorageReference */

const storage = getStorage(firebaseApp)
// if (process.env.NODE_ENV !== 'production') {
//   connectStorageEmulator(storage, "localhost", 9199)
// }
export const getDownloadUrl = (storageURI) => {
  if (!storageURI) {
    return Promise.resolve(null);
  }
  return getDownloadURL(ref(storage, storageURI))
    .catch((error) => console.log('getDownloadUrl', error));
}
export const TaskState = {
  CANCELED: 'canceled',
  ERROR: 'error',
  PAUSED: 'paused',
  RUNNING: 'running',
  SUCCESS: 'success',
  CHANGED: 'state_changed',
}
/**
 * Upload a file return its reference
 * @async
 * @param {File} file
 * @param {String} path
 * @param {object} [metadata]
 * @return {Promise<UploadResult>}
 */
export const uploadToStorage = async (file, path, metadata = null) => {
  return uploadBytes(ref(storage, path), file, metadata);
}
/**
 * @function uploadToStorageResumable
 * @summary Upload a file resumable with task progress
 * @param {File} file
 * @param {string} path
 * @param {object} metadata
 * @returns {UploadTask}
 */
export const uploadToStorageResumable = (file, path, metadata = null) => {
  return uploadBytesResumable(ref(storage, path), file, metadata);
}
/**
 * Upload a file and return its download url
 * @param {File} file
 * @param {String} path
 * @param {Object} [metadata]
 * @return {Promise<string>}
 */
export const uploadFile = async (file, path, metadata) => {
  const uploadResult = await uploadToStorage(file, path, metadata);
  return await getDownloadURL(uploadResult.ref);
  // .then((downloadUrl) => [downloadUrl, uploadResult.metadata]);
}

/**
 * Upload a base64 string
 * Ex. dataURL image.
 * @param {string} base64
 * @param {string} path
 * @param {object} metadata
 * @returns {Promise<string>} - download url
 */
export const uploadBase64 = async (base64, path, metadata) => {
  const uploadResult = await uploadString(ref(storage, path), base64, metadata)
  return await getDownloadURL(uploadResult.ref)
}
