import React, {useContext} from "react";
import {Alert, Card, Col, Empty, List, Row} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
import GroupTitle from "./GroupTitle";
import MarkdownListItem from "./MarkdownListItem";
import Loading from "./Loading";
import PropTypes from "prop-types";

DeliveriesList.propTypes = {
  list: PropTypes.array,
  error: PropTypes.object,
  pageNext: PropTypes.func,
};

function DeliveriesList(props) {
  const [, user] = useContext(AppContext);
  const [,, height] = useContext(WindowContext);

  if (!user?.uid) {
    return null;
  }
  if (props.loading) {
    return <Loading/>;
  }

  if (props.error) {
    return <Alert
      message={props.error.message}
      type="error"
      showIcon
      closable
    />;
  }

  return <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={23} xxl={23}>
        <Card
          className="card-rounded card-simple card-transparent"
          bordered={false}
          style={{margin: '0 0 20px 0', minHeight: `${height * 0.55}px`}}
        >
          <List
            className="list-empty-no-padding"
            header={<h6>Deliveries</h6>}
            dataSource={props.list}
            renderItem={([path, date]) =>
              <MarkdownListItem
                key={path}
                path={path}
                date={date}
                dataKey="deliveries"
              />}
            pagination={{
              pageSize: 30,
              showSizeChanger: false,
            }}
            locale={{
              emptyText: <Empty
                description={null}
                image={null}
                imageStyle={{display: "none"}}
                style={{maxWidth: "600px"}}
              >
                <Card className="card-simple card-rounded card-no-header" bordered={false}>
                  No deliveries to show
                </Card>
              </Empty>
            }}
          />
        </Card>
      </Col>
      <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}/>
    </Row>
}

export default DeliveriesList;
