import {addPath} from "@web/lib/firestore.db";
import now from "lodash/now.js";

/**
 * @function useAddFirestore
 * @param {String[]|string} collectionPath
 * @returns {[function(*=): Promise<void>]}
 */
function useAddFirestore(collectionPath) {
    const addFn = (data = null) => addPath(collectionPath, {...data, createdTs: now()});
    return [addFn];
}

export default useAddFirestore;
