// import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {thunk} from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const bindMiddleware = (middleware) => {
  // if (process.env.NODE_ENV !== "production") {
  //   const { composeWithDevTools } = require("redux-devtools-extension");
  //   return composeWithDevTools(applyMiddleware(...middleware));
  // }
  // if (process.env.NODE_ENV !== "production") {
  //   return import("redux-devtools-extension")
  //   .then(({composeWithDevTools}) =>
  //     composeWithDevTools(...middleware));
  // }
  return middleware;
};

// const store = createStore(rootReducer, bindMiddleware(middlewares));
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(bindMiddleware(middlewares)),
});

sagaMiddleware.run(rootSaga);
export { store };
