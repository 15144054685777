import React, {useContext} from "react";
import {Alert, Card, Col, Empty, List, Row} from "antd";
import {WindowContext} from "@web/hooks/context";
import Loading from "./Loading";
import PropTypes from "prop-types";
import MarkdownListItem from "./MarkdownListItem";
import PaymentListItem from "./PaymentListItem";

PaymentsList.propTypes = {
  list: PropTypes.array,
  error: PropTypes.object,
  pageNext: PropTypes.func,
};

function PaymentsList(props) {
  const [,, height] = useContext(WindowContext);

  if (props.error) {
    return <Alert
      message={props.error.message}
      type="error"
      showIcon
      closable
    />;
  }

  if (props.dataLoading) {
    return <Loading/>;
  }
  return <Row>
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      {/*<Card*/}
      {/*  className="card-rounded card-simple card-transparent"*/}
      {/*  bordered={false}*/}
      {/*  style={{margin: '0 0 20px 0', minHeight: `${height * 0.55}px`}}*/}
      {/*>*/}
        <List
          style={{marginTop: '20px'}}
          header={<h6 style={{paddingLeft: '12px'}}>Payments</h6>}
          className="list-empty-no-padding list-item-lite-divider"
          dataSource={props.list}
          // renderItem={([path, date]) =>
          //   <MarkdownListItem
          //     key={path}
          //     path={path}
          //     date={date}
          //     dataKey="payment"
          //   />
          // }
          renderItem={([path, date]) =>
            <PaymentListItem key={path} path={path} date={date} />
          }
          loading={props.dataLoading}
          pagination={{
            pageSize: 30,
            showSizeChanger: false,
          }}
          locale={{
            emptyText: <Empty
              description={null}
              image={null}
              imageStyle={{display: "none"}}
              style={{maxWidth: "600px"}}
            >
              <Card className="card-simple card-rounded card-no-header" bordered={false}>
                No payments to show
              </Card>
            </Empty>
          }}
        />
      {/*</Card>*/}
    </Col>
    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}/>
  </Row>
}

export default PaymentsList;
