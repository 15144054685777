import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  opacity: 0.4,
  muted: true,
  status: 'start',
};
export default function reducer(state = initState, {type, opacity, payload}) {
  switch (type) {
    case actions.SET_OPACITY:
      return {
        ...state,
        opacity,
      };
    case actions.START_ANIMATION:
      return {
        ...state,
       status: 'start',
      };
    case actions.STOP_ANIMATION:
      return {
        ...state,
        status: 'stop',
      };
      case actions.PAUSE_ANIMATION:
        return {
          ...state,
          status: 'pause',
        }
    case actions.TOGGLE_MUTE:
      return {
        ...state,
        mute: !state.mute,
      }
      case actions.START_CONTENT_VIDEO:
        return {
            ...state,
            status: 'content',
          opacity: 1,
            resetToStatus: state.contentVideo ? state.resetToStatus : state.status,
            contentVideo: {...payload, status: 'start'},
        }
    case actions.RESET_VIDEO_STATUS:
        return {
            ...state,
          opacity: 0.4,
          status: state.resetToStatus || 'start',
          resetToStatus: "",
        }
    case actions.END_CONTENT_VIDEO:
      return {
        ...state,
        contentVideo: null,
      }
    default:
      return state;
  }
}
