import React from "react";
import {Typography, Button, Divider, List} from "antd";
import PropTypes from "prop-types";
import Icon from "./Icon";
// import {DownArrowOutlined, ShareIcon} from "@web/assets/icons";
import useDomainIcon from "@web/hooks/useDomainIcon";
// import IconTip from "./IconTip";
// import ContactDetailsListItemMeta from "./ContactDetailsListItemMeta";
// import contentActions from "@web/redux/content/actions";
// import {useDispatch} from "react-redux";
// import uniq from "lodash/uniq";
import ContactDetailsListItem from "./ContactDetailsListItem";

ContactDetails.propTypes = {
  path: PropTypes.string,
  activityList: PropTypes.arrayOf(PropTypes.array),
  contact: PropTypes.object,
  onClose: PropTypes.func,
};

function ContactDetails(props) {
  console.log("ContactDetails:props:", props);
  // const dispatch = useDispatch();
  const contactIcon =
    useDomainIcon(
      props.contact?.domain,
      props.contact?.indicators,
    );

  if (!props.contact) {
    return null
  }

  console.log("ContactDetails:activityList:", props.activityList);

  const displayName =
    props.contact.name
    .replaceAll('"', '') ||
    props.contact.email;

  return <section
    style={{position: "relative", maxWidth: '1200px'}}
  >
    <div style={{width: '100%'}}>
      <div className="space space-between space-center-y">
        <div className="space space-center-y">
          <Button
            ghost
            className="icon-btn icon-left"
            onClick={props.onClose}
          >
            <Icon name="XIcon" />
          </Button>
          <Typography.Title level={4}>
            {displayName}
          </Typography.Title>
        </div>
        {/*<StatusActions*/}
        {/*  itemId={props.path.split("/").pop()}*/}
        {/*  actions={[]}*/}
        {/*  initialStatus={props.contact.status}*/}
        {/*  path={props.path}*/}
        {/*  extra={*/}
        {/*  <ReactionButton*/}
        {/*    ghost*/}
        {/*    size="small"*/}
        {/*    afterIcon={*/}
        {/*    <LikeIcon*/}
        {/*      size={20}*/}
        {/*      className="green-icon"*/}
        {/*    />}*/}
        {/*    beforeIcon={*/}
        {/*      <LikeIcon size={20} />*/}
        {/*    }*/}
        {/*    reaction="like"*/}
        {/*    itemPath={props.path}*/}
        {/*    value={props.contact.like}*/}
        {/*  />}*/}
        {/*/>*/}
      </div>
    </div>
    <div>
      <Divider />
      <List
        loading={!props.activityList}
        dataSource={props.activityList || []}
        renderItem={([activityPath]) =>
          <ContactDetailsListItem
            key={activityPath}
            path={props.path}
            activityPath={activityPath}
            displayName={displayName}
            name={props.contact.name}
            email={props.contact.email}
            contactIcon={contactIcon}
          />
        }
        locale={{emptyText: "No relevant activity"}}
      />
    </div>
  </section>
}

export default ContactDetails;
