import React, {useEffect} from 'react'
import {Redirect} from 'wouter'
import {auth} from '@web/lib/firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
import Loading from '@ai-antd/components/Loading'

export default function Logout() {
  const [user, loading] = useAuthState(auth)
  console.log('Logout 1', user, loading);
  useEffect(() => {
    console.log('signing out', user);
    !!user && auth.signOut()
  }, [])
  console.log('Logout render', user);
  if (loading) return <Loading />
  return !user ? <Redirect to="/" /> : <Loading />
}
