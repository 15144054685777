import React from 'react';
import {Button, Card, Empty, List, Input} from "antd";
import PropTypes from "prop-types";
// import {updatePath} from "@web/lib/firestore.db";
// import now from "lodash/now";
// import FilterDropdown from "./FilterDropdown";
// import isArray from "lodash/isArray";
// import flatten from "lodash/flatten";
// import TodoCard from "./TodoCard";
// import TodosListItem from "./TodosListItem";
// import GroupTitle from "./GroupTitle";
import {api} from "@web/lib/api";
import ContactCard from "./ContactCard";

ContactSelect.propTypes = {
  action: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  userId: PropTypes.string,
};

function ContactSelect(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [list, setList] = React.useState([]);

  /** @param {string} q */
  const onSearch = (q) => {
    if (!q) return setList([]);
    setIsLoading(true);
    api.call("user-search", {q, serviceId: "contacts"})
      .then((response) => setList(response.data))
      .then(() => setIsLoading(false));
  }
  // const onSelect = (selectedUserId) => {
  //   props.onSelect && props.onSelect(selectedUserId);
  //   props["action-path"] &&
  //   updatePath(props["action-path"], {
  //     response: {
  //       userId: selectedUserId,
  //       ts: now(),
  //     },
  //   });
  // }
  const dataSource = list.length ? list : props.list;
  return <List
    header={<label>Multiple contacts found:</label>}
    className="scribe-widget add-integrations"
    grid={{gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2}}
    dataSource={[...dataSource].sort((a, b) => (a.activityTs - b.activityTs))}
    renderItem={(item) => <ContactCard {...item} />}
    pagination={dataSource.length && {pageSize: 2, showSizeChanger: false}}
    locale={{emptyText: <Empty
        className="add-integrations-empty-wrapper"
        description={null}
        image={null}
        imageStyle={{display: 'none'}}
        style={{maxWidth: '700px'}}
      >
        <Card className="card-rounded card-simple" bordered={false}>
          <h4>Oops, something went wrong</h4>
        </Card>
      </Empty>}
    }/>
}

export default ContactSelect;
