import React, {useCallback} from "react";
import {Segmented} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {MenuIcon, StarIcon} from "@web/assets/icons";
import IconTip from "./IconTip";

function CleanModeButton() {
  const dispatch = useDispatch();
  const showOnly = useSelector((state) => !!state.App?.modeClean ? "starred" : "all");
  const toggleModeClean = useCallback(() => dispatch({type: "TOGGLE_MODE_CLEAN"}), [dispatch, showOnly]);
  console.log("CleanModeButton", showOnly)
  return <Segmented
    onChange={toggleModeClean}
    value={showOnly}
    size={"middle"}
    options={[
      {
        value: "starred",
        label: <IconTip key="relevant" title="Hide Promos" placement="top" trigger={["hover"]}>
          <StarIcon style={{position: 'relative', bottom: '-3px'}} size={18} />
        </IconTip>,
      },
      {
        value: "all",
        label: <IconTip key="all" title="Show All" placement="top" trigger={["hover"]}>
          <MenuIcon style={{position: 'relative', bottom: '-3px'}}  />
        </IconTip>,
      },
    ]}
  />
}

export default CleanModeButton;
