import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

ListItemActive.propTypes = {
  path: PropTypes.string,
  offset: PropTypes.string,
};

function ListItemActive(props) {
  const isActive =
    useSelector((state) => {
      return state.App[state.App.activeDetailServiceId]?.props?.path === props.path;
    });

  return <div
    className={`list-item-selected ${isActive ? 'selected' : 'not-selected'}`}
    style={props.offset ? {right: props.offset} : null}
  ></div>;
}

export default ListItemActive;
