import React from "react";
import PropTypes from "prop-types";

Todo.propTypes = {
  type: PropTypes.string,
};

function Todo({type, children}) {
  return <div
    className={
    type === 'info' ?
      'todo-item todo-item-info font-lg' :
      'todo-item todo-item-step font-lg'
  }>
    {children}
  </div>;
}

export default Todo;
