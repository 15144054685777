const actions = {
  LOAD_TASKS: "LOAD_TASKS",
  LOAD_TASKS_SUCCESS: "LOAD_TASKS_SUCCESS",
  LOAD_TODOS: "LOAD_TODOS",
  LOAD_TODOS_SUCCESS: "LOAD_TODOS_SUCCESS",
  SYNC_TASKS_SUCCESS: "SYNC_TASKS_SUCCESS",
  DISMISS_TODO: "DISMISS_TODO",
  DISMISS_TODO_SUCCESS: "DISMISS_TODO_SUCCESS",
  SET_TODOS_FILTERS: "SET_TODOS_FILTERS",
  LOAD_TASKS_ERROR : "LOAD_TASKS_ERROR",

  loadTasks: () => {
    return (dispatch, getState) => {
        console.log('loadTasks')
      const fields = getState().Tasks?.filters;
      dispatch({
        type: actions.LOAD_TASKS,
        fields: fields || {},
      });
    };
  },
  loadTodos: (status) => {
      console.log('loadTodos')
    return (dispatch) => {
      dispatch({
        type: actions.LOAD_TODOS,
        status,
      });
    };
  },
  dismissTodo: ({todoPath}) => {
      console.log('dismissTodo')
    return (dispatch) => {
      dispatch({
        type: actions.DISMISS_TODO,
        todoPath,
      });
    };
  },
  filterTodos: ({status}) => {
      console.log('filterTodos')
    return (dispatch) => {
      dispatch(actions.loadTodos(status));
    };
  }
};

export default actions;
