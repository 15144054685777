import React from "react";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
// import {getDocument} from "@web/lib/firestore.db";
import {Typography} from "antd";

function AiStreamText({streamId}) {
  // const [content, setContent] = React.useState("");
  const [data] = useDocumentFirestore(["stream", streamId])
  return <Typography.Paragraph className="ai-text" style={{fontSize: '20px'}}>
    {data?.content}
  </Typography.Paragraph>
}

export default AiStreamText;