import React from "react";
import {RecordIcon} from "@web/assets/icons";
import {Button, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import voiceActions from "@web/redux/voice/actions";

const getTipTitle = (call) => {
  switch (true) {
    case !call:
      return "AI Record";
    case call?.customParameters?.record:
      return "Recording";
    default:
      return "Not Recording";
  }
}
function VoiceRecordButton() {
  const dispatch = useDispatch();
  const record = useSelector(state => state.Voice.record);
  const call = useSelector(state => state.Voice.call);
  const registered = useSelector(state => state.Voice.registered);
  const toggleRecord = () => dispatch(voiceActions.toggleRecord());
  const onClickRecord = () => {
    toggleRecord();
  }
  return <Tooltip title={getTipTitle(call)} trigger={['hover']} placement="bottom">
    <Button disabled={!registered || (call && !call.customParameters?.record)} ghost size="small" className="icon-btn icon-right" onClick={onClickRecord}>
      <RecordIcon className={record ? "green-icon" : ""} />
    </Button>
  </Tooltip>
}

export default VoiceRecordButton;