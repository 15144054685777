import React, {useEffect} from "react";
import {
  browserLocalPersistence,
  isSignInWithEmailLink,
  parseActionCodeURL,
  setPersistence,
  signInWithEmailLink,
  updateProfile
} from "firebase/auth";
import {auth, ga} from "@web/lib/firebase";
import {Alert, Button, Divider, Input, Space, Typography} from "antd";
import useLocalStorage from "@web/hooks/useLocalStorage";
import {useLocation} from "wouter";
import {notifyAndTrack} from "@ai-antd/components/notification";
import {MailOutlined} from "@ant-design/icons";
import useQuerystring from "@web/hooks/useQuerystring";
import {colls, setDocument} from "@web/lib/firestore.db";
import now from "lodash/now.js";
import AuthLayoutWrapper from "@ai-antd/components/AuthLayoutWrapper";
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "@ai-antd/components/Loading";

const getFirebaseErrorMessage = (code) => {
  switch (code) {
    case "auth/email-already-in-use":
      return "Email is Already In Use";
    case "auth/expired-action-code":
      return "The link has expired.  Make sure you used the latest link sent to your email.";
    case "auth/invalid-action-code":
      return "The link is not valid. Make sure you used the latest link sent to your email.";
    case "auth/weak-password":
      return "Weak Password";
    case "auth/invalid-email":
      return "Invalid Email";
    case "auth/user-not-found":
      return "User Not Found";
    case "auth/wrong-password":
      return "Wrong Password";
    default:
      return "Something went wrong. Try again later.";
  }
};
const setAuthPersistence = () => setPersistence(auth, browserLocalPersistence);
const syncAnonContent = (user, anonUserId) => {
  setDocument(colls["user-profile"], user.uid, {anonUserId}, true).then();
};
const fullWidthStyle = {width: "100%"};

function VerifyEmailLink() {
  const [user] = useAuthOven(auth);
  const isSigningInWithEmailLink = isSignInWithEmailLink(auth, window.location.href);
  const [requestEmail, setRequestEmail] = React.useState(false);
  const qs = useQuerystring();
  const [emailLS, setEmailLS] = useLocalStorage("signup-email");
  const [email, setEmail] = React.useState("");
  const [anonUserId, setAnonUID] = useLocalStorage("anon-user-id");
  const [error, setError] = React.useState(null);
  const [retry, setRetry] = React.useState(0);
  const [authComplete, setAuthComplete] = React.useState(false);
  const [, setLocation] = useLocation();

  const setRedirect = () => {
    setEmailLS("");
    setAnonUID("");
    const {redirectTo = "/"} = qs;
    setLocation(redirectTo);
  };

  React.useEffect(() => {
    // console.log("VerifyEmailLink useEffect 1", {
    //   user,
    //   authComplete,
    //   isSigningInWithEmailLink,
    //   requestEmail,
    //   error,
    //   qs,
    //   email,
    //   emailLS
    // });
    const verifiedEmail = email || emailLS;

    if (!isSigningInWithEmailLink) {
      setError({code: ""});
      return;
    }
    if (!verifiedEmail) {
      setRequestEmail(true);
      return;
    }
    const actionCodeUrl = parseActionCodeURL(window.location.href);
    // console.log("actionCodeUrl", actionCodeUrl);
    if (actionCodeUrl.tenantId) {
      auth.tenantId = actionCodeUrl.tenantId;
    }
    if (user && !user.isAnonymous) {
      return setAuthComplete(true);
    }
    setAuthPersistence()
      .then(() => signInWithEmailLink(auth, verifiedEmail, window.location.href))
      .then((result = {}) => {
        const {user, additionalUserInfo} = result;
        if (!user) return notifyAndTrack.error("Please try again.");
        if (additionalUserInfo?.isNewUser) {
          updateProfile(user, {displayName: email.split("@")[0]})
            .then(() => anonUserId && syncAnonContent(user, anonUserId))
            .then(() => ga.setUserId(user.uid))
            .then(() => setAuthComplete(true))
            .catch(error => {
              console.log("Error verifying link", error);
              notifyAndTrack.error("Unable to complete verification");
              setError(error);
            });
        } else setAuthComplete(true);
      })
      .catch(error => {
        console.log("Error verifying link", error.code);
        setError(error);
      });
  }, [retry]);
  const errorMessage = error && getFirebaseErrorMessage(error.code);

  useEffect(() => {
    if (authComplete && user) setRedirect();
  }, [authComplete, user]);

  if (!error && !requestEmail) return <Loading/>;

  return <AuthLayoutWrapper hideLinks>
    <div className="flex-col middle center" style={fullWidthStyle}>
      <Typography.Title level={4}>Finish Verification</Typography.Title>
      <div className="flex-col middle center">
        <Divider/>
        {!error && requestEmail &&
          <Space style={fullWidthStyle}>
            <Input
              size="large"
              prefix={<MailOutlined/>}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email"
            />
            <Button size="large" type="primary" onClick={() => setRetry(now())}>{qs.cta || "Continue"}</Button>
          </Space>
        }
        {!!error &&
          <Space direction="vertical">
            <Alert type="warning" description={<span>{errorMessage}</span>} showIcon/>
            <Typography.Link href="/">Home</Typography.Link>
          </Space>
        }
      </div>
    </div>
  </AuthLayoutWrapper>;
}

export default VerifyEmailLink;
