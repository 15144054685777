import React from "react";
import {Button, Empty, List} from "antd";
import {AppContext} from "@web/hooks/context";
import {AddDocumentIcon,} from "@web/assets/icons";
import {buildQuery} from "@web/lib/firestore.db";
import {useCollectionData} from "react-firebase-hooks/firestore";
import Loading from "./Loading";
import ChatUploader from "./ChatUploader";
import {DeleteOutlined} from "@ant-design/icons";
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import {notifyAndTrack} from "./notification";
import Icon from "./Icon";

function UploadsManager(props) {
  const [_, user] = React.useContext(AppContext);
  const [saveUploads, savingUploads, errorSavingUploads] = useFunctionsCallable("user-upload");
  const [userUploads, loadingUserUploads = false, errorLoadingUserUploads = false] = useCollectionData(
    user?.uid ? buildQuery(["user", user?.uid, "files"],
      {">": {uploadTs: 0}},
      {limit: 10, orderBy: ["receivedTs", "desc"]}) : null
  );
  if (!userUploads && loadingUserUploads) {
    return <Loading inline/>;
  }
  const onMediaUpload = (gsUrls) => {
    console.log("onMediaUpload", gsUrls);
    return saveUploads({gsUrls})
      .then((res) => {
        const {data} = res || {};
        console.log("onMediaUpload", res);
        const {result, error, success, message = error?.message, description} = data;
        !error ?
          !!message && notifyAndTrack.success(message, description, result) :
          notifyAndTrack.error(message, description, error);
      })
      .catch((err) => {
        console.log("onMediaUpload", err);
        notifyAndTrack.error("Oops", err.message, err);
      });
  };
  const renderItem = (item = {}) => {
    return <List.Item extra={<DeleteOutlined/>}>
      {item.url}
    </List.Item>;
  };

  return <section>
    <section style={{paddingBottom: "18px"}}>
      <List
        header={<div className="space space-center-y space-between">
          <div className="space space-center-y gap-8">
            <AddDocumentIcon/>
            <span>Upload</span>
          </div>
          <ChatUploader onSuccess={onMediaUpload}>
            <Button className="px-5" ghost shape="round">
              <Icon name="PlusIcon"/>
            </Button>
          </ChatUploader>
        </div>}
        datasource={userUploads}
        bordered={false}
        renderItem={renderItem}
        locale={{emptyText: <Empty image={null} description={<span>No uploads</span>}/>}}
      />
    </section>
  </section>;
}

export default UploadsManager;
