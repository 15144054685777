import React from "react";
import PropTypes from "prop-types";
import {PhoneCallIcon, XIcon} from "@web/assets/icons";
import voiceActions from "@web/redux/voice/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, notification, Tag, Tooltip} from "antd";
import VoiceMuteButton from "./VoiceMuteButton";
import VoiceRecordButton from "./VoiceRecordButton";

VoiceCallButton.propTypes = {
  to: PropTypes.string.isRequired,
  name: PropTypes.string,
  callUserId: PropTypes.string,
};

function VoiceCallButton(props) {
  const dispatch = useDispatch();
  const call = useSelector(state => state.Voice.call);
  const registered = useSelector(state => state.Voice.registered);
  const statusTs = useSelector(state => state.Voice.statusTs);
  const connect = () => dispatch(voiceActions.connectCall({To: props.to}));
  const disconnect = () => dispatch(voiceActions.disconnectCall());

  const onClickConnect = () => {
    if (!registered) {
      return notification.warning({
        message: 'Voice is not ready',
        description: 'Please try again shortly',
      });
    }
    call?.direction === 'INCOMING' ?
      call.accept() :
      connect();
  }
  const onClickEnd = () => {
    console.log('onClickEnd', call.status());
    switch (true) {
      case call?.status() === 'pending':
      case call?.status() === 'ringing':
      case call?.status() === 'connecting':
      case call?.status() === 'open':
        console.log('onClickEnd', call.direction);
        return call.direction === 'INCOMING' ?
          call.reject() :
          call.disconnect();
      case call?.status() === 'connected':
        return call.disconnect();
      default:
        return call.disconnect();
    }
  }
  const disableCallButton = () => {
    switch (true) {
      case !registered:
      case call?.status() === 'connecting':
      case call?.status() === 'connected':
      case call?.status() === 'disconnecting':
      case call?.status() === 'open':
        return true;
      default:
        return false;
    }
  }
  const disableEndButton = () => {
    switch (true) {
      case !registered:
      case call?.status() === 'pending':
      case call?.status() === 'disconnected':
      case call?.status() === 'disconnecting':
        return true;
      default:
        return false;
    }
  }
  const connectButton = () => <Tooltip title="Start" trigger={['hover']} placement="bottom">
    <Button ghost disabled={disableCallButton()} onClick={onClickConnect} size="small">
      <PhoneCallIcon className={(call) ? 'green-icon' : ''} style={{position: 'relative', top: '2px'}} />
    </Button>
  </Tooltip>;
  const endButton = () => <Tooltip title="End" trigger={['hover']} placement="bottom">
    <Button danger ghost disabled={disableEndButton()} onClick={onClickEnd} size="small">
      <XIcon />
    </Button>
  </Tooltip>

  const getButton = () => {
    console.log("getButton", call?.status());
    switch(call?.status()) {
      case 'pending': return connectButton();
      case 'connecting': return endButton();
      case 'connected': return endButton();
      case 'disconnecting': return endButton();
      case 'ringing': return endButton();
      case 'disconnected': return connectButton();
      case 'open': return endButton();
      default: return connectButton();
    }
  }

  return <div className="space space-center-y">
    <VoiceMuteButton />
    {getButton()}
    <Tooltip title={props.to} trigger={['hover']} placement="bottom" key={props.to}>
      <h6 className={!registered ? 'muted' : '' }>{props.name}</h6>
    </Tooltip>
    <VoiceRecordButton />
  </div>
}

export default VoiceCallButton;