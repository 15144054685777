const actions = {
  LOAD_CALENDAR: "LOAD_CALENDAR",
  LOAD_CALENDAR_SUCCESS: "LOAD_CALENDAR_SUCCESS",
  SYNC_CALENDAR_SUCCESS: "SYNC_CALENDAR_SUCCESS",
  PAGE_CALENDAR: "PAGE_CALENDAR",
  PAGE_CALENDAR_SUCCESS: "PAGE_CALENDAR_SUCCESS",
  loadCalendar: (memberUserId) => {
    return (dispatch) => {
      console.log("loadCalendar", {memberUserId});
      dispatch({
        type: actions.LOAD_CALENDAR,
        memberUserId,
        params: {},
      });
    };
  },
  pageNext: (memberUserId) => {
    return (dispatch, getState) => {
      const params = getState().Calendar?.pageParams;
      console.log("pageNext", {memberUserId, params});
      dispatch({
        type: actions.PAGE_CALENDAR,
        memberUserId,
        params,
      });
    };
  },
  // searchMail: () => {
  //   return (dispatch, getState) => {
  //     console.log("searchMail");
  //     const params = getState().Search?.pageParams;
  //     dispatch({
  //       type: actions.SEARCH_MAIL,
  //       params: {},
  //     });
  //   };
  // },
};

export default actions;
