import React from 'react';
import PropTypes from 'prop-types';
import {Button, Comment, Tooltip, Typography} from "antd";
import {AttachmentIcon} from "@web/assets/icons";
import {AppContext} from "@web/hooks/context";
import TimeAgo from "@web/ui/components/TimeAgo";
import UserAvatar from "./UserAvatar";
import MarkdownTimeline from "./MarkdownTimeline";
import {useSelector} from "react-redux";

TimelineEmail.propTypes = {
  path: PropTypes.string.isRequired,
  date: PropTypes.number,
  hideSubject: PropTypes.bool,
  showAttachmentCount: PropTypes.bool,
};

function TimelineEmail(props) {
  const [, user] =
    React.useContext(AppContext);

  /** @type {models.EmailMessage.Data} */
  const email =
    useSelector((state) => {
      return state.Content.data?.[props.path];
    });

  if (!email) {
    return null
  }

  return <div className="space space-col">
    {!props.hideSubject &&
      <label className="font-xl muted">
        {email.subject}
      </label>
    }
    <Comment
      avatar={
      <UserAvatar
        displayName={email.from?.name || email.from?.email}
        size={"large"}
      />}
      author={
      <label
        className="ant-comment-content-author-name"
      >{email.from?.email === user.email ?
        user.displayName :
        email.from?.name ||
        email.from?.email
        ?.split("@")?.[0]}
      </label>}
      datetime={
        <Tooltip title="2016-11-22 11:22:33">
          <TimeAgo
            date={email.date}
            live={true}
          />
        </Tooltip>
      }
      // avatar={<Avatar size="large" src="https://joeschmoe.io/api/v1/random" alt="Han Solo">O</Avatar>}
      content={
        <MarkdownTimeline
          markdown={email.markdown || email.text || email.snippet}
          components={{
            p: (props) =>
              <Typography.Paragraph
                ellipsis={{rows: 8, expandable: true}}
                className="render-line-breaks mb-0">
                {props.children}
            </Typography.Paragraph>
          }}
        />
      }
      actions={
        props.showAttachmentCount &&
        Object.keys(email.attachments || {}).length ?
          [<Button shape="round" className="icon-btn">
            <AttachmentIcon className="muted-icon"/>
            <label className="font-xl muted">
              {Object.keys(email.attachments).length}
            </label>
          </Button>,
          ] :
          null
      }
    />
    </div>
}

export default TimelineEmail;
