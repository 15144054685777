import React from 'react';
import PropTypes from 'prop-types';

SlideCardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
  partial: PropTypes.bool,
};

function SlideCardWrapper(props) {
  return <div className={`slider-card-wrapper ${props.size || 'md'} ${props.partial ? 'partial' : ''}`}>
    <section>{props.children}</section>
  </div>;
}

export default SlideCardWrapper;
