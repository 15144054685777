import React from "react";
import PropTypes from "prop-types";
import {PhoneCallIcon} from "@web/assets/icons";
import {Button} from "antd";

CallButton.propTypes = {
  size: PropTypes.string,
  iconClassName: PropTypes.string,
};

function CallButton({size = 'small', iconClassName}) {
  const ref = React.useRef({});
  const [status, setStatus] = React.useState('idle');

  React.useEffect(() => {
    // if (!ref.current.voice) {
    //   const voice = Voice();
    //   ref.current.voice = voice;
    //   voice.on('incoming', (call) => {
    //     console.log("Inbound call");
    //     ref.current.call = call;
    //     setStatus('incoming');
    //   });
    //   return () => {
    //     if (ref.current.call) ref.current.call.disconnect();
    //   }
    // }
  }, []);
  const onClick = (e) => {

  }
  return <Button ghost size={size} onClick={onClick}>
    <PhoneCallIcon className={iconClassName} />
  </Button>
}

export default CallButton;