import React from 'react';
import PropTypes from 'prop-types';
import {getMemberByEmail} from "@web/redux/activity/selectors";
import {useSelector} from "react-redux";

ContactDataByEmail.propTypes = {
  email: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function ContactDataByEmail(props) {
  // console.log('props', props.email);
  const contact = useSelector((state) => getMemberByEmail(state, props.email));
  console.log('ContactDataByEmail', contact, props.email);
  // if (contact && !contact.indicators?.isPerson === true) {
  //   contact.displayName = contact.domain;
  // }
  return React.cloneElement(React.Children.only(props.children), {...contact || null})
}

export default ContactDataByEmail;
