import React from "react";
import PropTypes from "prop-types";
import {Avatar, List} from "antd";
import Markdown from "./Markdown";
import TimeAgo from "@web/ui/components/TimeAgo";

ListItemAvatar.propTypes = {
  icon: PropTypes.node,
  displayName: PropTypes.string,
  photoURL: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

function ListItemAvatar({displayName, title, photoURL, description, date, text = "", icon = null, onClick, size = 30}) {
  console.log('ListItemAvatar', {displayName, title, photoURL, description, date, text, icon, onClick, size})
  return <List.Item.Meta
    avatar={photoURL ? <Avatar src={photoURL} size={size} /> : null}
    title={<div onClick={onClick} className="space space-col">
      <div className="space space-center-y" style={{height: `${size}px`}}>
        {icon}
        <h6 className="font-xl muted capitalize">{displayName}</h6>
        <span className="muted font-xl">&nbsp;{text}{!!date && <TimeAgo date={date}/>}</span>
      </div>
      {title && <h6>{title}</h6>}
    </div>}
    description={description && <Markdown markdown={description} className="muted" />}
  />
}

export default ListItemAvatar;
