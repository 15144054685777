import actions from "./actions";
import uniqBy from "lodash/uniqBy.js";
import sortBy from "lodash/sortBy.js";
import reverse from "lodash/reverse.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  all: [],
  files: [],
  members: null,
  documents: [],
};

/**
 * @summary Reducer for activity
 * @param {object} state
 * @param {string} type
 * @param {{files: FirestoreQueryItems, members: FirestoreDocumentItem[]}} payload
 * @param {string} [source=gmail]
 * @return {*}
 */
export default function reducer(state = initState, {type, payload, members = {}, pageParams}) {
  switch (type) {
    case actions.SYNC_DOCUMENTS_SUCCESS:
      return state;
    case actions.LOAD_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        ...payload.members||null,
        data: {...state.data, ...payload.files?.data},
        all: reverse(sortBy(uniqBy([...state.all, ...payload?.files?.items], "id"), "receivedTs")),
        files: reverse(sortBy(uniqBy([...state.files, ...payload?.files?.items], "id"), "receivedTs")),
        pageParams,
      };
    }
    default:
      return state;
  }
}
