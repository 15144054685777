import {LeftArrowOutlined} from "@web/assets/icons";
import React from "react";
import PropTypes from "prop-types";
import {Button} from "antd";
// import AgentSelector from "./AgentSelector";
import DashboardRow from "./DashboardRow";

HoverStrip.propTypes = {
  onClick: PropTypes.func,
  center: PropTypes.node,
}
export default function HoverStrip(props) {
  return <div className="detail-drawer-content-header hover-visibility-wrapper" style={{
    padding: '10px 20px 10px 10px',
    margin: '4px 0 6px 0',
    borderRadius: '50px',
    position: 'relative',
    cursor: 'pointer'
  }}>
    <DashboardRow
      left={<Button ghost size="large" onClick={props.onClick}>
        <LeftArrowOutlined className="green-icon"/>
        {/*<span className="font-lg keyboard-shortcut green-color" style={{position: 'relative', top: '-2px'}}>Back</span>*/}
      </Button>}
    >
      <div style={{position: 'relative', left: '-10px'}}>{props.center || null}</div>
      {/*<div className="space space-center-y show-on-hover" onClick={props.onClick}>*/}
      {/*  <span className="font-lg keyboard-shortcut green-color" style={{position: 'relative', top: '-2px'}}>Back</span>*/}
      {/*</div>*/}
    </DashboardRow>
  </div>;
}
