import React from 'react'
import {Divider, Typography} from "antd";
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '@web/lib/firebase'
import AuthEmail from './AuthEmail'
import Loading from '@ai-antd/components/Loading'
import GoogleAuthButton from '@ai-antd/components/GoogleAuthButton'
import AuthLayoutWrapper from '@ai-antd/components/AuthLayoutWrapper'
import MicrosoftAuthButton from '@ai-antd/components/MicrosoftAuthButton'
import {Redirect} from "wouter";
import PropTypes from "prop-types";

const fullWidthStyle = {width: '100%'}

Login.propTypes = {
  title: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export function Login({title = "A new way to work.", subtitle = "Login", providers = [], redirectTo = '/'}) {
  const [user, loadingUser] = useAuthState(auth)

  if (loadingUser) return <Loading />
  if (user && !user.isAnonymous) {
    return <Redirect to={redirectTo} />
  }
  // if (isLoggedIn) {
  //   if (redirectCallback) return redirectCallback(user)
  //   return <Redirect to={redirectTo}/>;
  // }

  return <AuthLayoutWrapper hideLinks>
    <Typography.Title level={2} style={{margin: '20px 40px 0'}}>{title}</Typography.Title>
    <div className="space-col center middle" style={fullWidthStyle}>
      <AuthEmail title={subtitle} redirectTo={redirectTo} hideMoreOptions />
      <br />
      <br />
      {providers.indexOf("google.com") >= 0 && <GoogleAuthButton block>Login with Google</GoogleAuthButton>}
      {providers.length > 1 && <Divider className="small-top small-bottom"/>}
      {providers.indexOf("microsoft.com") >= 0 && <MicrosoftAuthButton scopes={['openid']} block/>}
    </div>
  </AuthLayoutWrapper>
}

export default Login
