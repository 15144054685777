import React from "react";
import DetailData from "@web/ui/containers/DetailData";
import DetailTimeline from "./timeline/DetailTimeline";

ContentDetailWrapper.propTypes = {

};

function ContentDetailWrapper(props) {
  return <DetailData
    {...props}
    component={DetailTimeline}
  />
}

export default ContentDetailWrapper;
