import actions from "./actions";

const initState = {
  callMessage: '',
  registering: false,
  registered: false,
  call: null,
  record: false,
  callError: null,
  deviceError: null,
  twilioError: null,
  warning: [],
  // muted: false,
};

/**
 * @summary Reducer for activity
 * @param {object} state
 * @param {string} type
 * @param {object} payload
 * @return {*}
 */
export default function reducer(state = initState, {type, payload}) {
    switch (type) {
        case actions.VOICE_DEVICE_REGISTERING:
            console.log("VOICE_DEVICE_REGISTERING");
            return {
              ...state,
              registering: true,
              callError: null,
              deviceError: null,
              statusTs: Date.now(),
            }
        case actions.VOICE_DEVICE_REGISTERED:
            console.log("VOICE_DEVICE_REGISTERED");
            return {
                ...state,
                registered: true,
                registering: false,
              callError: null,
              deviceError: null,
              statusTs: Date.now(),
            }
      case actions.VOICE_DEVICE_UNREGISTERED:
        console.log("VOICE_DEVICE_UNREGISTERED");
        return {
          ...state,
          registered: false,
          registering: false,
          statusTs: Date.now(),
        }
        case actions.VOICE_DEVICE_INCOMING:
            console.log("VOICE_DEVICE_INCOMING", payload);
            const [call] = payload?.args;
            return {
                ...state,
                call,
              callError: null,
              deviceError: null,
              statusTs: Date.now(),
            }
        case actions.VOICE_CALL_ACCEPTED: {
            console.log("VOICE_CALL_ACCEPTED", payload);
            return {
                ...state,
              callError: null,
              deviceError: null,
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_CANCELLED: {
            console.log("VOICE_CALL_CANCELLED", payload);
            state.call = null;
            return {
                ...state,
              call: null,
              callMessage: 'Cancelled',
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_IGNORED: {
            console.log("VOICE_CALL_IGNORED", payload);
            state.call = null;
            return {
                ...state,
              call: null,
              callMessage: 'Ignored',
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_MUTED: {
            console.log("VOICE_CALL_MUTED", payload);
            return {
              ...state,
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_REJECTED: {
            console.log("VOICE_CALL_REJECTED", payload);
            return {
                ...state,
              callMessage: '',
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_RINGING: {
            console.log("VOICE_CALL_RINGING", payload);
            const [hasEarlyMedia] = payload?.args;
            return {
                ...state,
              callMessage: 'Ringing...',
              media: hasEarlyMedia,
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_CONNECTING: {
            console.log("VOICE_CALL_CONNECTING", payload);
            return {
                ...state,
                connecting: true,
              callMessage: 'Connecting...',
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_CONNECTED: {
            console.log("VOICE_CALL_CONNECTED", payload);
            const [call] = payload?.args;
            return {
                ...state,
                connecting: false,
                call,
              callMessage: 'Connected',
              callError: null,
              deviceError: null,
              statusTs: Date.now(),
            }
        }
      case actions.VOICE_CALL_DISCONNECTING: {
        console.log("VOICE_CALL_DISCONNECTING", payload);
        return {
          ...state,
          disconnecting: true,
          callMessage: 'Disconnecting...',
          statusTs: Date.now(),
        }
      }
      // case actions.VOICE_CALL_DISCONNECT: {
      //   console.log("VOICE_CALL_DISCONNECT", payload);
      //   state.call = null;
      //   return {
      //     ...state,
      //     disconnecting: false,
      //     callMessage: '',
      //     statusTs: Date.now(),
      //   }
      // }
        case actions.VOICE_CALL_DISCONNECTED: {
            console.log("VOICE_CALL_DISCONNECTED", payload);
            return {
                ...state,
                disconnecting: false,
                call: null,
              callMessage: '',
              statusTs: Date.now(),
            }
        }
        case actions.VOICE_CALL_RECONNECTING: {
          const [twilioError] = payload?.args;
          return {
            ...state,
            twilioError,
            statusTs: Date.now(),
          }
        }
      case actions.VOICE_CALL_RECONNECTED: {
        return {
          ...state,
          twilioError: null,
          statusTs: Date.now(),
        }
      }
        case actions.VOICE_CALL_WARNING:
            console.log("VOICE_CALL_WARNING", payload)
            return {
                ...state,
                warning: [...state.warning, payload?.args],
              statusTs: Date.now(),
            }
      case actions.VOICE_CALL_WARNING_CLEARED:
        console.log("VOICE_CALL_WARNING_CLEARED", payload)
        return {
          ...state,
          warning: state.warning.filter(([warningName]) =>
            warningName !== payload?.args[0]),
          statusTs: Date.now(),
        }
        case actions.VOICE_DEVICE_ERROR:
            console.log("VOICE_DEVICE_ERROR", payload)
          const [deviceError] = payload?.args;
            return {
                ...state,
              deviceError,
              statusTs: Date.now(),
            }
        case actions.VOICE_CALL_TOGGLE_RECORD:
            console.log("VOICE_CALL_RECORD", payload)
            return {
                ...state,
              record: !state.record,
              statusTs: Date.now(),
            }
        // case actions.VOICE_CALL_CONNECT_EVENT_CHANGE:
        //     console.log("VOICE_CALL_CONNECT_EVENT_CHANGE", {payload, type})
        //     return {
        //         ...state,
        //         connectEvent: payload,
        //     }
        // case actions.VOICE_CALL_DEVICE_EVENT_CHANGE:
        //     console.log("VOICE_CALL_DEVICE_EVENT_CHANGE", {payload, type})
        //     return {
        //         ...state,
        //         deviceEvent: payload,
        //     }
        default:
            return state;
    }
}
