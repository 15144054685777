import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIcon,
  AmazonAwsIcon,
  AmazonIcon, BusinessIcon,
  ContactsOutlinedIcon,
  GMailIcon,
  IntuitIcon,
  LinkedInIcon,
  OutlookIcon
} from "@web/assets/icons";

const getIcon = (domain, indicators) => {
  if (!domain) {
    return null;
  }
  switch (true) {
    case domain === "amazon.com":
    case domain === "business.amazon.com":
      return <AmazonIcon className="muted-icon" />;
    case domain === "aws.amazon.com":
      return <AmazonAwsIcon className="muted-icon" />;
    case domain.includes("linkedin.com"):
      return <LinkedInIcon className="muted-icon" />;
    case domain.endsWith("gmail.com"):
      return <GMailIcon className="muted-icon" />;
    case domain.endsWith("outlook.com"):
      return <OutlookIcon className="muted-icon" />;
    case domain.endsWith("intuit.com"):
      return <IntuitIcon className="muted-icon" />;
    case !indicators?.isPerson:
      return <ContactsOutlinedIcon className="muted-icon" />;
    case indicators?.isSystem:
      return <ActivityIcon className="muted-icon" />;
    case indicators?.isGenericEmailProvider:
      return <BusinessIcon className="muted-icon" />;
    default:
      return null;
  }
}
useDomainIcon.propTypes = {
  domain: PropTypes.string,
  indicators: PropTypes.object,
};

/**
 * Get the icon for a contact based on their domain and indicators
 * @param {string} domain - The domain of the contact ex. "gmail.com" or "aws.amazon.com"
 * @param {object} indicators
 * @returns {*}
 */
function useDomainIcon(domain, indicators) {
  return getIcon(domain, indicators);
}

export default useDomainIcon;
