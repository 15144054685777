import React from "react";
import PropTypes from "prop-types";
import {Button, Card, Divider, Input, Select} from "antd";
// import {generateID, setPath, updatePath} from "@web/lib/firestore.db";
import {DeleteIcon, EditIcon, GoogleIcon, ReplyIcon, SaveOutlineIcon} from "@web/assets/icons";
// import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
// import {AppContext, ChatContext} from "@web/hooks/context";
// import now from "lodash/now";
// import ChatInputTextarea from "./ChatInputTextarea";
// import ChatInputManager from "@web/ui/containers/ChatInputManager";
// import Toggle from "./Toggle";
import Icon from "./Icon";
// import useChatStream from "@web/hooks/useChatStream";
// import {api} from "@web/lib/api";
import ButtonWithScope from "./ButtonWithScope";
import SendEmailButton from "./SendEmailButton";
// import {EditOutlined} from "@ant-design/icons";
// import MarkdownTimeline from "./MarkdownTimeline";
// import useChatStreamListener from "@web/hooks/useChatStreamListener";

DraftEditor.propTypes = {
  // contextPaths: PropTypes.arrayOf(PropTypes.string),
  // draftPath: PropTypes.string,
  // label: PropTypes.string,
  // sendAction: PropTypes.string,
  // actionLabel: PropTypes.string,
  // threadPath: PropTypes.string,
  // parentEmailMessagePath: PropTypes.string,
  // toEmails: PropTypes.arrayOf(PropTypes.object),
  // ccEmails: PropTypes.arrayOf(PropTypes.object),
  // subject: PropTypes.string,
  // replyingToEmailMessage: PropTypes.object,
  // attachments: PropTypes.array,
  // important: PropTypes.bool,
  // style: PropTypes.object,
  parentEmailMessage: PropTypes.object,
  sending: PropTypes.bool,
  finished: PropTypes.bool,
  onAddEmail: PropTypes.func,
  onClickSave: PropTypes.func,
  onClickSend: PropTypes.func,
  onClickCollapse: PropTypes.func,
  onClickDelete: PropTypes.func,
  onTextChange: PropTypes.func,
  getDistroLists: PropTypes.func,
  text: PropTypes.string,
  collapsed: PropTypes.bool,
};

function DraftEditor(props) {
  // const [_, user] =
  //   React.useContext(AppContext);
  // const [draftPath, setDraftPath] =
  //   React
  //   .useState(props.draftPath);
  // const [
  //   initialDraft,
  //   loadingDraft,
  //   loadingDraftError,
  //   draftSnapshot,
  // ] = useDocumentFirestore(draftPath);
  // const [
  //   subject,
  //   setSubject,
  // ] = React
  // .useState(props.subject);
  // const [toEmails, setToEmails] =
  //   React
  //   .useState(
  //     props.toEmails
  //     .map(({email: value, name: label = value}) =>
  //       ({value, label})));
  // const [distro, setDistro] =
  //   React
  //   .useState(
  //     props.toEmails
  //     .map(({email: value, name: label = value}) =>
  //       ({value, label})));
  // const [ccEmails, setCcEmails] =
  //   React
  //   .useState(props.ccEmails);
  // const [attachments, setAttachments] =
  //   React
  //   .useState(props.attachments);
  // const [
  //   answers,
  //   generateDraft,
  //   streamError,
  //   streamDone,
  //   chatPath,
  // ] = useChatStream("ai-email-assistant");
  // const [chat] =
  //   useChatStreamListener("ai-email-body-writer");
  // const [chat] =
  //   useChatStreamListener("ai-email-assistant");
  // const [message, setMessage] =
  //   React
  //   .useState(initialDraft?.message || "");
  // const [sendError, setSendError] =
  //   React
  //   .useState(null);
  // const [processing, setProcessing] =
  //   React
  //   .useState(false);
  // const [assistantAnswerText, setAssistantAnswerText] =
  //   React
  //   .useState(null);
  // React
  // .useEffect(() => {
  //   if (!chat) {
  //     return;
  //   }
  //   switch (true) {
  //     case !chat?.answers?.length:
  //       return;
  //     case chat?.answers[chat?.answers?.length - 1].agentId === "ai-email-body-writer":
  //       return setMessage(chat?.answers[chat?.answers.length - 1].text);
  //     // case chat?.answers[chat?.answers.length - 1].agentId === "ai-email-assistant":
  //     //   return setAssistantAnswerText(chat?.answers[chat?.answers.length - 1].text);
  //   }
  // }, [chat]);
  // const formatSubject = (subject) => {
  //   switch (action) {
  //     case "reply":
  //       return `Re: ${subject}`;
  //     case "forward":
  //       return `Fwd: ${subject}`;
  //     default:
  //       return subject;
  //   }
  // }
  // const cancelAction = () => {
  //   setFinished(true);
  //   setProcessing(false);
  //
  //   props.onCancel &&
  //   props
  //   .onCancel(draftPath);
  //
  //   return draftPath &&
  //     setPath(
  //       [draftPath, "cancel"]
  //       .join("/"),
  //       {date: now()},
  //     );
  // };
  //
  // const closeEditor = () => {
  //   props.onClickCollapse();
  // };
  // const onClickGenerate = (text) => {
  //   generateDraft(
  //     `Draft an email ${action}${props.threadPath ? ' to the provided thread.' : '.'}${text ? ` Instructions: ${text}` : ''}`,
  //     props.threadPath?[props.threadPath] : [],
  //   );
  // };
  const [isPending, startTransition] = React
  .useTransition();

  const [editing, setEditing] = React
  .useState(false);

  const onTextChange = (e) => {
    startTransition(() =>
      props.onTextChange(e.target.value));
  }

  const onSelectChange = (value) => {
    console.log(`onSelectChange ${value}`);
  }

  const onSelect =
    (value, item) => {
      props.onAddEmail(item);
    }

  const onSearch = (value) => {
    console.log(`onSearch ${value}`);
  }

  const onFocus = () =>
    null;

  const onClickEdit = () => {
    setEditing(!editing);
  }

  const onInputKeyDown = (e) => {
    console.log("onInputKeyDown", e.key, e.code)
    switch (true) {
      case e.key === "Tab":
      case e.key === "Enter":
      case (e.key === " " || e.code === "Space"):
      case e.key === ",":
        e.preventDefault();
        e.stopPropagation();
        e.target.value &&
        props.onAddEmail(e.target.value);
        e.target.value = "";
        break;
    }
  }

  const [toList, ccList] =
    props
    .getDistroLists()
    .map((distroList) => {
      return distroList
      .map(({email: value, name: label = value}) =>
        ({value, label}));
    });

  // console.log("onSearch", toEmails);
  // if (props.finished) {
  //   return <Card
  //     className="card-simple card-rounded card-header-title-text"
  //   >
  //     <Card.Meta
  //       // title="Email Sent"
  //       description={
  //         <div
  //           className="space space-center-y space-between"
  //         >
  //           <span>
  //             Your email has been sent successfully
  //           </span>
  //           <Button
  //             size="small"
  //             ghost
  //             className="icon-btn icon-right"
  //             onClick={props.onClickCollapse}
  //           >
  //             <Icon name={"XIcon"} />
  //           </Button>
  //         </div>}
  //     />
  //     <Divider
  //       className="hidden small-top no-bottom"
  //     />
  //     <Toggle
  //       trigger={
  //       <Button
  //         ghost
  //         className="icon-btn icon-left"
  //       >
  //         <DownArrowOutlined
  //           className="green-icon"
  //         />
  //         <label
  //           className="green-color"
  //         >
  //           Message text
  //         </label>
  //       </Button>}
  //     >
  //       <MarkdownTimeline
  //         markdown={props.text}
  //       />
  //     </Toggle>
  //   </Card>
  // }

  return <Card
    bordered={false}
    extra={
    <div className="space space-center-y">
      {/*<Button*/}
      {/*  size="small"*/}
      {/*  ghost*/}
      {/*  className="icon-btn icon-right"*/}
      {/*  onClick={props.save}*/}
      {/*>*/}
      {/*  <EditOutlined size={28}/>*/}
      {/*</Button>*/}
      <Button
        // size="small"
        ghost
        className="icon-btn icon-right"
        // onClick={onClickEdit}
      >
        <span className="muted">Cc</span>
        {/*<EditIcon className="green-icon"/>*/}
      </Button>
      {/*<Button*/}
      {/*  size="small"*/}
      {/*  ghost*/}
      {/*  className="icon-btn icon-right"*/}
      {/*  onClick={props.onClickDelete}*/}
      {/*>*/}
      {/*  <Icon name={"DeleteIcon"}/>*/}
      {/*</Button>*/}
    </div>
    }
    // title={<Input
    //   bordered={false}
    //   size="large"
    //   value={initialDraft?.subject}
    //   addonBefore="Subject:"
    // />}
    title={
      <div className="space space-center-y">
        <Button ghost>
          <ReplyIcon />
        </Button>
        <Select
          mode="multiple"
          value={toList}
          size={"large"}
          placeholder="Please select"
          // defaultValue={distro[0]?.value}
          onChange={onSelectChange}
          onSelect={onSelect}
          onSearch={onSearch}
          onInputKeyDown={onInputKeyDown}
          style={{width: '100%'}}
          options={
            props.draft?.toEmails?.length &&
            props.draft.toEmails
            .map(({email: value, name: label = value}) =>
              ({value, label}))}
          // fieldNames={{label: "name", value: "email"}}
        />
      </div>
    }
    className="card-rounded card-simple extra-round email-editor-card"
    bodyStyle={{padding: "0"}}
    // extra={<Button size="small" ghost className="icon-btn" onClick={cancelEmail}><XIcon size={18} className="muted-icon" /></Button>}
    // style={style}
  >
    {/*<Input*/}
    {/*  bordered={false}*/}
    {/*  size="large"*/}
    {/*  value={draft?.subject}*/}
    {/*  addonBefore="Subject"*/}
    {/*  className="email-editor-textarea mb-6 ai-text"*/}
    {/*/>*/}
    <Input.TextArea
      defaultValue={props.text}
      disabled={props.sending}
      className={`email-editor-textarea brainstorm-editor${editing ? " editing" : ""}`}
      value={props.text}
      autoSize={{minRows: 3, maxRows: 14}}
      size="large"
      onChange={onTextChange}
      onFocus={onFocus}
      onPressEnter={onFocus}
    />
    <div className="space space-center-y space-between mt-8">
      <div className="space space-center-y">
        <Button
          ghost
          className="icon-btn icon-right"
          onClick={props.onClickDelete}
        >
          <Icon name={"DeleteIcon"}/>
        </Button>
        <Button
          ghost
          className="icon-btn icon-right"
          onClick={onClickEdit}
        >
          <EditIcon className="green-icon"/>
        </Button>
      </div>
      <SendEmailButton
        ghost
        shape="round"
        disabled={!props.text || props.finished}
        onClick={props.onClickSend}
        size="large"
        className="icon-btn green-color"
      >
        <span className="green-color">
          Send
        </span>
      </SendEmailButton>
    </div>
  </Card>;
}

export default DraftEditor;
