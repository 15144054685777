import actions from "./actions";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";

/**
 * @typedef {object} State.Calendar
 * @property {object} data
 * @property {{list:string[],items:{id:string,key:string,path:string}[],sync:{list:string[],items:{id:string,key:string,path:string},count:number}}} events
 * @property {Error|null} error
 */

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  events: {
    all: [],
    items: [],
    sync: {
      list: [],
      items: [],
      count: 0,
    },
  },
};

/**
 * @summary Reducer for calendar
 * @param {object} state
 * @param {string} type
 * @param {FirestoreQueryItems} events
 * @param {object} pageParams
 * @param {object} payload
 * @return {*}
 */
export default function reducer(state = initState, {type, payload, events, pageParams, startAfter}) {
  switch (type) {
    case actions.SYNC_CALENDAR_SUCCESS:
      console.log("SYNC_CALENDAR_SUCCESS", events);
      if ((state.events?.list || []).includes(payload?.list?.[0])) {
        return state;
      }
      const syncList = uniq([...state.events.sync?.list || [], ...payload?.list]);
      return {
        ...state,
        data: {
          ...state.data,
          ...(payload.data || null),
        },
        events: {
          ...state.events,
          sync: {
            list: syncList,
            items: uniqBy([...state.events?.sync?.items || [], ...payload.items], 'key'),
            count: syncList.length,
          },
        },
        pageParams,
      };
    case actions.LOAD_CALENDAR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...(events?.data || null),
        },
        events: {
          all: uniq([...events?.list]),
          items: uniqBy([...events?.items], 'key'),
        },
        pageParams,
        startAfter,
      };
    default:
      return state;
  }
}
