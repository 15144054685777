import {firestore} from "@web/lib/firebase";
import {doc, DocumentData, DocumentSnapshot, FirestoreError} from "firebase/firestore";
import {useDocumentData} from "react-firebase-hooks/firestore";
import compact from "lodash/compact.js";

/**
 * @summary useDocumentFirestore
 * @param {string|string[]|undefined} documentPath
 * @return {[(DocumentData | undefined), boolean, (FirestoreError | undefined), (DocumentSnapshot<DocumentData> | undefined)]}
 */
export default function useDocumentFirestore(documentPath) {
  if (!documentPath) {
    return useDocumentData(null);
  }

  const path = Array
  .isArray(documentPath) ?
    documentPath.join("/") :
    documentPath;

  const validPath =
    !!path &&
    (path.indexOf("//") === -1) &&
    (compact(path.split("/")).length % 2 === 0);

  if (!validPath) {
    console.warn("Invalid path", path, compact(path.split("/")).length % 2);
  }
  // console.log('useDocumentFirestore', path);
  return useDocumentData((validPath && doc(firestore, path)) || null);
}
