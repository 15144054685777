import React, {Fragment, useEffect} from "react";
import PropTypes from "prop-types";
import VideoPlayer from "react-background-video-player";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "antd";
import {XIcon} from "@web/assets/icons";
import backgroundActions from "@web/redux/background/actions";
import isBoolean from "lodash/isBoolean";

const defaultVideoUrl = ["https://player.vimeo.com/external/435674703.sd.mp4?s=01ad1ba21dc72c1d34728e1b77983805b34daad7&profile_id=165&oauth2_token_id=57447761", "./video/splash.m4v"];
Background.propTypes = {

};

function Background(props) {
  const dispatch = useDispatch();
  const video = React.useRef(null);
  const opacity = useSelector(state => state.Background.opacity);
  const status = useSelector(state => state.Background.status);
  const contentVideo = useSelector(state => state.Background.contentVideo);
  const endContentVideo = () => dispatch(backgroundActions.endContentVideo());
  const [contentVideoUrl, setContentVideoUrl] = React.useState("");

  useEffect(() => {
    switch (status) {
      case 'start':
        video.current.play();
        break;
      case 'stop':
        video.current.pause();
        break;
      case 'pause':
        video.current.pause();
        break;
      case 'content':
        video.current.pause();
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (!contentVideo?.url) return setContentVideoUrl("");
    setContentVideoUrl(contentVideo.url);
    if (contentVideo.status === 'start') video.current.play();
  }, [contentVideo]);

  const onEndContentVideo = () => {
    endContentVideo();
  }

  return <Fragment>
    <VideoPlayer
      ref={videoRef => video.current = videoRef}
      className={`background-video with-gradient with-gradient-color${(status === 'start' || status === 'content') ? ' animate' : ''} ${status === 'content' ? ' content-video-active' : ''}`}
      style={{opacity, backgroundColor: 'hsla(196, 84%, 15%, 100%)'}}
      src={contentVideoUrl || defaultVideoUrl[1]}
      // onPlay={(e) => console.log('onPlay', e)}
      // onPause={(e) => console.log('onPause', e)}
      autoPlay={isBoolean(contentVideo?.autoPlay) ? contentVideo.autoPlay : true}
      muted={isBoolean(contentVideo?.muted) ? contentVideo.muted : true}
      loop={isBoolean(contentVideo?.loop) ? contentVideo.loop : true}
    />
    <section className={status === 'content' ? 'background-video-children content-video-active-children-hide' : 'background-video-children content-video-active-children-show'}>
      {props.children}
    </section>
    {!contentVideo?.noControls && <section className="content-video-controls-wrapper" style={{width: '1px', height: '1px'}}>
      <Button.Group
          className={`content-video-controls${status === 'content' ? ' content-video-active-show' : ' content-video-active-hide'}`}>
        <Button className="icon-btn" ghost onClick={onEndContentVideo}><XIcon/></Button>
      </Button.Group>
    </section>}
  </Fragment>
}

export default Background;
