import React from 'react';
import {generateID} from '@web/lib/firestore.db'
import {uploadToStorage} from '@web/lib/cloud.storage'
import {PlusOutlined} from '@ant-design/icons'
import {Button} from 'antd'

/**
 * @summary Upload a file to firebase storage
 * @param {File} file
 * @return {StorageReference}
 */
const handleFileUpload = (file) => {
  const {type} = file
  const ext = type.split('/')[1]
  const filename =  `${generateID()}.${ext}`
  return uploadToStorage(file, filename, {})
    .then((uploadResult) => uploadResult.ref)
}

function ChatUploader(props) {
  const uploader = React.useRef()
  /**
   * @summary Upload files to firebase storage and return a list of urls
   * @param {ChangeEvent<T>} e
   * @return {Promise<string[]>}
   */
  const onChange = (e) => {
    /** @type {FileList} */
    const fileList = e.target.files
    return Promise.all([...fileList].map(handleFileUpload))
      .then((refList) => refList.map((ref) => ref.toString()))
      .then((gsUrlList) => props.onSuccess && props.onSuccess(gsUrlList))
  }
  const onClickUpload = (e) => {
    e.preventDefault()
    e.stopPropagation()
    uploader.current.click()
  }
  const button = props.children ?
    React.cloneElement(React.Children.only(props.children), {onClick: onClickUpload}) :
    <Button
      icon={<PlusOutlined />}
      className="px-5"
      ghost shape="round"
      onClick={onClickUpload}
    />
  return <>
    {button}
    <input
      ref={uploader}
      type="file"
      style={{visibility: 'hidden', position: 'absolute', height: 0, width: 0, left: -1000}}
      onChange={onChange}
    />
  </>
}

export default ChatUploader;
