import React from 'react';
import PropTypes from 'prop-types';
import {XIcon} from "@web/assets/icons";
import {Card, Modal, Typography} from "antd";
import {api} from "@web/lib/api";
import {notifyAndTrack} from "./notification";
import {WindowContext} from "@web/hooks/context";

AddScopeModal.propTypes = {
  providerId: PropTypes.string.isRequired,
  scopes: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onContinue: PropTypes.func,
  icon: PropTypes.node.isRequired,
  closable: PropTypes.bool,
};

function AddScopeModal(props) {
  const [_, width, height] = React.useContext(WindowContext);
  const [open, setOpen] = React.useState(props.open);

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  if (!props.scopes.length) {
    return null;
  }

  const getAuthLinkUrl = (providerId) => {
    switch (providerId) {
      case "google-com":
        return "google-authLink";
      case "microsoft-com":
        return "microsoft-authLink";
      default:
        throw new Error(`Unknown providerId: ${providerId}`);
    }
  }

  const onContinue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onContinue &&
    props.onContinue();
    return api
    .call(
      getAuthLinkUrl(props.providerId), {
        scopes: props.scopes,
      })
    .then(({data, error}) => {
      console.log("AddScopeModal:data", {data, error});
      if (error) {
        return notifyAndTrack.error(error, "Unable to complete your request");
      }
      window.open(data.authUrl, "_blank", `popup=true,width=800,height=800,noreferrer,left=${(width / 2) - 400},top=${(height / 2) - 400}`);
    });
  }
  const onCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
    props.onCancel();
  }

  return <Modal
    closable={props.closable ?? true}
    open={open}
    title={props.title}
    onOk={onContinue}
    onCancel={onCancel}
    okText={<div className="space space-center-y">{props.icon} Sounds good</div>}
    cancelText={<div className="space space-center-y"><XIcon/> Cancel</div>}
    centered
    okButtonProps={{
      type: "ghost",
      className: "green-color",
      disabled: !props.scopes.length,
      shape: "round",
    }}
    cancelButtonProps={{
      shape: "round",
      style: {display: 'none'},
    }}
  >
    <Card className="card-simple card-rounded extra-round extra-padding card-no-header card-no-title card-transparent" bordered={false}>
      <Card.Meta
        title={<Typography.Text type="success">Permission to send email</Typography.Text>}
        description={<Typography.Text>To protect your privacy, we request your permission to send email on your behalf. You only have to do this once.</Typography.Text>}
      />
    </Card>
  </Modal>
}

export default AddScopeModal;
