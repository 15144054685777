import contentActions from "../content/actions";
const actions = {
  AI_WRITE_STREAM: "AI_WRITE_STREAM",
  AI_CLEAR_STREAM: "AI_CLEAR_STREAM",
  SET_ACTIVE_EMAIL_DRAFT: "SET_ACTIVE_EMAIL_DRAFT",
  CLEAR_ACTIVE_EMAIL_DRAFT: "CLEAR_ACTIVE_EMAIL_DRAFT",
  clearStream: (chatId) =>
    ({type: actions.AI_CLEAR_STREAM, chatId}),
  /**
   * @function setEmailDraft
   * @param {string} draftPath
   * @returns {{draftPath:string}}
   */
  setActiveEmailDraft: (draftPath) => {
    return {
      type: actions.SET_ACTIVE_EMAIL_DRAFT,
      draftPath,
    };
  },
  clearActiveEmailDraft: () => {
    return {type: actions.CLEAR_ACTIVE_EMAIL_DRAFT};
  },
};

export default actions;
