import React from "react";
import {buildQuery} from "@web/lib/firestore.db";
import {AppContext} from "@web/hooks/context";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import PropTypes from "prop-types";
import Loading from "@ai-antd/components/Loading";

AppIntegrationsData.propTypes = {
  filter: PropTypes.oneOf(["all", "connected"]).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  pagination: PropTypes.object,
};

function AppIntegrationsData({filter = "all", tags = [], children, ...listProps}) {
  const [,, claims] = React.useContext(AppContext);
  const [pairs = [], dataLoading, integrationsLoadError] = useQueryFirestore(
    buildQuery(
    "app-integrations",
    tags.length ? {
      "array-contains-any": {tags}
    } : {},
      {order: [["sort", "desc"]], limit: 10}
  ));

  if (dataLoading) {
    return <Loading inline/>;
  }

  const filtered = pairs.filter(([integrationId]) => {
    switch (filter) {
      case "all":
        return !!integrationId;
      // case "connected":
      //   return integrationId in Object.keys(claims?.data?.integrations || {});
      default:
        return false;
    }
  });

  return React.cloneElement(
    React.Children.only(children),
    {pairs: filtered, tags, ...listProps},
  );
}

export default AppIntegrationsData;
