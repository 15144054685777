import React from "react";
import PropTypes from "prop-types";
import {Alert, Button, Divider, Progress, Statistic} from "antd";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import Loading from "./Loading";
import {DeleteIcon, XIcon} from "@web/assets/icons";
import {CheckOutlined} from "@ant-design/icons";

BatchOperationProgress.propTypes = {
  path: PropTypes.string,
  starting: PropTypes.bool,
  requestError: PropTypes.string,
  onSuccess: PropTypes.func,
  onDismiss: PropTypes.func,
  countSuffix: PropTypes.string,
  showCount: PropTypes.bool,
};

function BatchOperationProgress({path, starting = false, requestError, onSuccess, onDismiss, countSuffix, showCount = false}) {
  const [batchOperation, loadingBatchOperation, errorLoadingBatchOperation] = useDocumentFirestore(path || null);
  const [complete, setComplete] = React.useState(false);
  const percentComplete = ((batchOperation?.complete || 0) / (batchOperation?.steps || 1)) * 100;

  React.useEffect(() => {
    if (percentComplete === 100 || batchOperation?.completedTs) {
      setComplete(true);
      onSuccess && onSuccess(batchOperation);
    }
  }, [percentComplete, batchOperation?.completedTs]);

  if (loadingBatchOperation) {
    return <Loading size="medium" inline/>;
  }

  if (requestError) return <Alert type="warning" message="This action cannot be performed" banner showIcon={false} closable onClose={onDismiss}/>;
  if (!batchOperation) return <Alert type="error" message="Something went wrong" banner/>;

  const status = () => {
    switch (true) {
      case requestError:
        return "warning";
      case errorLoadingBatchOperation:
        return "exception";
      case complete:
        return "success";
      default:
        return "active";
    }
  };

  return <>
    {showCount && <>
      <Statistic
      title={batchOperation?.displayName}
      value={batchOperation?.count}
      prefix={<DeleteIcon className="muted-icon" size={28}/>}
      suffix={countSuffix}
      valueStyle={{display: "flex", alignItems: "center", lineHeight: "0.8em"}}
    />
      <Divider className="small-bottom"/>
    </>}
    <Progress
      style={{width: "100%"}}
      status={status}
      percent={percentComplete}
      strokeLinecap="round"
      type="line"
      format={(percent = 0, successPercent) => percent !== successPercent ? Math.floor(percent) + "%" :
        <CheckOutlined className="green-icon"/>}
      showInfo
    />
    {onDismiss && <Button ghost onClick={onDismiss}>
      <div className="space space-center-y"><XIcon size={18}/> Dismiss</div>
    </Button>}
  </>;
}

export default BatchOperationProgress;
