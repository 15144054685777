import React from "react";
import PropTypes from "prop-types";
import {Avatar, Badge, Tooltip} from "antd";
import UserAvatarCard from "./UserAvatarCard";
// "https://firebasestorage.googleapis.com/v0/b/admin-85663.appspot.com/o/upload%2Fandrey-zvyagintsev-x0c6vTO5ibA-unsplash.jpg?alt=media&token=f93c574f-cc6e-4c3d-af00-72feff10f8dc"
UserAvatar.propTypes = {
  userId: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  photoURL: PropTypes.string,
  icon: PropTypes.node,
  badge: PropTypes.number,
  size: PropTypes.any,
  content: PropTypes.string,
  status: PropTypes.string,
  card: PropTypes.node,
  className: PropTypes.string,
  selected: PropTypes.bool,
};

function UserAvatar({
                      userId,
                      name = "",
                      displayName = name,
                      email,
                      photoURL,
                      icon,
                      badge = 0,
                      size,
                      clickTip,
                      content = null,
                      status = null,
                      className = "",
  selected = false,
                      onClick,
extra                    }) {
  const title =
    content || displayName || email?.split("@")?.[0] ||
    "";

  return <Tooltip
    overlayStyle={{width: "360px"}}
    placement="bottomLeft"
    title={<UserAvatarCard
      email={email}
      userId={userId}
      title={title}
      onClick={onClick}
      extra={extra}
    />}
    trigger={["hover"]}
    className={"user-avatar-tooltip"}
  >
    <Badge count={badge > 0 ? "New" : 0} offset={[-12, 10]}>
      <div className="user-avatar-wrapper">
        <Avatar
          onClick={() => onClick && onClick(userId)}
          icon={icon}
          key={userId || email || photoURL || displayName}
          className={`user-avatar${selected ? " user-avatar-selected" : ""} ${onClick ? " cursor-pointer" : ""}`}
          src={photoURL}
          size={size || "large"}
        >{photoURL ?
          null :
          <span
          className="avatar-name capitalize">{(displayName || title).substring(0, 3).toLowerCase()}</span>}</Avatar>
      </div>
    </Badge>
  </Tooltip>;
}

export default UserAvatar;
