import React from 'react';
import PropTypes from 'prop-types';
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import {AppContext} from "@web/hooks/context";
import {GoogleIcon, OutlookIcon} from "@web/assets/icons";
import ButtonWithScope from "./ButtonWithScope";

SendEmailButton.propTypes = {
  shape: PropTypes.string,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

function SendEmailButton(props) {
  const [_, user] = React
  .useContext(AppContext);

  const [emailProviderId, setEmailProviderId] = React
  .useState(null);

  const [getEmailProviderId] =
    useFunctionsCallable("user-emailProviderId");

  React.useEffect(() => {
    if (!user?.uid) {
      return;
    }
    getEmailProviderId()
    .then((result) => {
      console.log("SendEmailButton:getEmailProviderId:result:", result);
      result.data.providerId &&
      setEmailProviderId(result.data.providerId);
    });
  }, [user]);

  const getProviderIcon = (providerId) => {
    if (providerId === "google-com") {
      return <GoogleIcon
        size={16}
        className="green-icon"
      />;
    }
    if (providerId === "microsoft-com") {
      return <OutlookIcon
        size={16}
        className="green-icon"
      />;
    }
    return null;
  }

  const getRequiredScopes = (providerId) => {
    if (providerId === "google-com") {
      return [
        "https://www.googleapis.com/auth/gmail.send",
      ];
    }
    if (providerId === "microsoft-com") {
      return [
        "mail.send",
      ];
    }
    return [];
  }

  if (!emailProviderId) {
    return null;
  }

  return <ButtonWithScope
    shape="round"
    disabled={props.disabled}
    ghost={props.ghost}
    size={props.size}
    className={props.className || "icon-btn green-color"}
    providerId={emailProviderId}
    onClick={props.onClick}
    providerIcon={getProviderIcon(emailProviderId)}
    requiredScopes={getRequiredScopes(emailProviderId)}
  >
    {props.children}
  </ButtonWithScope>
}

export default SendEmailButton;
