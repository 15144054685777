import React from "react";
import {Button, Dropdown} from "antd";
import {SubmenuIcon} from "@web/assets/icons";
import PropTypes from "prop-types";
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";
import {getFirebaseApp} from "@web/lib/firebase";

DevButton.propTypes = {
  path: PropTypes.string.isRequired,
  loader: PropTypes.string.isRequired,
  icon: PropTypes.node,
  trigger: PropTypes.arrayOf(PropTypes.string),
  ghost: PropTypes.bool,
}

function DevButton({path, loader, icon, size = "medium", trigger = ["contextMenu", "click"], ghost = true}) {
  // const [executeTask] = useFunctionsCallable(loader);
  const projectId = getFirebaseApp().options.projectId;
  const items = [
    // {
    //   label: 'Completions', key: 'completions', children: [
    //     { label: 'Todo Steps', key: 'todo-steps' }, // remember to pass the key prop
    //     { label: 'Summary', key: 'summary' },
    //     { label: 'Actionable', key: 'actionable' },
    //     { label: 'Events Details', key: 'router-details' },
    //     { label: 'Todos', key: 'router-todos' },
    //     { label: 'Labels', key: 'labels' },
    //     { label: 'User Actions', key: 'user-actions' },
    //   ]},
    // { type: 'separator' },
    {
      label: <a href={`https://console.firebase.google.com/project/${projectId}/firestore/data/${path}`} target="_blank">Data</a>,
      key: 'view-document',
    },
  ];
  const onClick = (e) => {
    if (e.domEvent?.target.href) return true;
    e.domEvent?.preventDefault();
    e.domEvent?.stopPropagation();
    const {keyPath} = e;
    const [taskKey, group] = keyPath;
    switch (group) {
      case "completions": {
        executeTask({path, taskKey});
        break;
      }
      case "view": {
        break;
      }
    }
    return false;
  }
  return <Dropdown menu={{items, onClick}} trigger={trigger}>
    <Button ghost={ghost}>{icon || <SubmenuIcon className="muted-icon" />}</Button>
  </Dropdown>
}

export default DevButton;
