import React from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

DashboardRow.propTypes = {
  left: PropTypes.node,
  children: PropTypes.node,
};

function DashboardRow(props) {
  return <Row className="w-full">
    <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}>{props.left || null}</Col>
    <Col xs={24} sm={24} md={24} lg={24} xl={21} xxl={21} className="overscroll-contain">
      {props.children}
    </Col>
  </Row>
}

export default DashboardRow;
